import React from 'react'
import AdminNavbar from "../Navbars/AdminNavbar";
import Sidebar from "../Sidebar/Sidebar";
import { AxiosResponse } from 'axios';
import { UserApis } from '../../apis/userApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { convertUTCDateToLocalDate } from '../Reusable/functions';
import { NavLink } from 'react-router-dom';
//@ts-ignore
import Modal from 'react-awesome-modal';
import { SvgElement, icontypesEnum } from '../assets/svgElement';
import { SuperAdminApis } from '../../apis/superAdminApi';
import { STATUSTYPES } from '../Reusable/Status';
import { CURRENCYCODE } from '../Reusable/CurrencyCode';
import { COUNTRYCODE } from '../Reusable/CountryCode';
import AdminSidebar from '../Sidebar/AdminSidebar';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FaPen } from "react-icons/fa";
import { Oval } from 'react-loader-spinner';



function CreditCustomer() {
  let [showScreen, setShowScreen] = React.useState<any>(1);

  const navigate = useNavigate();

  const userLoginData = useSelector((state: any) => state.data.login.value);

  let [shipmentData, setShipmentData] = React.useState<any>({
    companyName: "", phoneNumber: "", email: '', address: '',
    authorizingOfficer: '', banker1: '', banker2: '',
    officerInCharge: '', procedureForPayment: '', pickupDays: '', accountCommencementDate: '',
    advancePaymentDate: '', reviewedBy: '', approvedBy: '', clientAccountNumber: '', dateAccountOpened: '',
  });


  const [shipmentInfo, setShipmentInfo] = React.useState<any>([]);

  let [loader, setLoader] = React.useState<boolean>(false);

  let [statusModal, setStatusModal] = React.useState<boolean>(false);
  let [approveModal, setApproveModal] = React.useState<boolean>(false);
  let [data, setData] = React.useState<any>([]);
  let [riderModal, setRiderModal] = React.useState<boolean>(false);

  let [shipmentId, setShipmentId] = React.useState<any>([]);
  let [shipmentStatus, setShipmentStatus] = React.useState<any>('');

  let [creditCustomers, setCreditCustomers] = React.useState<any>([]);
  let [vehicle, setVehicle] = React.useState<any>([]);


  function toggleStatus(data: any) {
    setData(data)
    setStatusModal(true)
  }


  function toggleApprove(data: any) {
    setData(data)
    setApproveModal(true)
  }

  const updateToReview = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true)
    SuperAdminApis.updateToReview(data?.id)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            setStatusModal(false)
            SuperAdminApis.getAllCreditCustomers('', '').then(
              (response: any) => {
                if (response?.data) {
                  setCreditCustomers(response?.data?.data?.data)
                  setLoader(false)
                }
              }
            )
          }
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        console.log(err);
      })

  }, [shipmentInfo, data, loader, creditCustomers]);


  const updateToApproved = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true)
    SuperAdminApis.updateToApproved(data?.id)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            setApproveModal(false)
            SuperAdminApis.getAllCreditCustomers('', '').then(
              (response: any) => {
                if (response?.data) {
                  setCreditCustomers(response?.data?.data?.data)
                  setLoader(false)
                }
              }
            )
          }
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        console.log(err);
      })

  }, [shipmentInfo, data, approveModal, loader, creditCustomers]);


  const handleShipmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShipmentData({ ...shipmentData, [e.target.name]: e.target.value });
  }


  React.useEffect(() => {
    const query: any = {
      search: '',
    };
    SuperAdminApis.getAllCreditCustomers('', '').then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setCreditCustomers(response?.data?.data)
        }
      }
    )
  }, []);


  const [searchText, setSearchText] = React.useState('');

  const paginator = React.useCallback(
    (value: any) => {
      //   setLoader(true);
      let value2 = '';
      if (value !== null) {
        value2 = value;
      } else {
        value2 = ''
      }

      console?.log(value2)

      SuperAdminApis.getAllCreditCustomers(value2, searchText).then(
        (response: any) => {
          if (response?.data) {
            setCreditCustomers(response?.data?.data);
          }
        }
      ).catch(function (error) {
        console.log(error.response.data);
      })

    }, [shipmentInfo, searchText]);

  function refreshPage() {
    window.location.reload();
  }

  const schedule = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);
    const formData = new FormData()
    formData.append('companyName', shipmentData?.companyName)
    formData.append('phoneNumber', shipmentData?.phoneNumber)
    formData.append('email', shipmentData?.email)
    formData.append('address', shipmentData?.address)
    formData.append('authorizingOfficer', shipmentData?.authorizingOfficer)
    formData.append('banker1', shipmentData?.banker1)
    formData.append('banker2', shipmentData?.banker2)
    formData.append('officerInCharge', shipmentData?.officerInCharge)
    formData.append('procedureForPayment', shipmentData?.procedureForPayment)
    formData.append('pickupDays', shipmentData?.pickupDays)
    formData.append('accountCommencementDate', shipmentData?.accountCommencementDate)
    formData.append('advancePaymentDate', shipmentData?.advancePaymentDate)
    formData.append('reviewedBy', shipmentData?.reviewedBy)
    formData.append('approvedBy', shipmentData?.approvedBy)
    formData.append('clientAccountNumber', shipmentData?.clientAccountNumber)
    formData.append('dateAccountOpened', shipmentData?.dateAccountOpened)
    SuperAdminApis.createCreditCustomer(formData)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            setShowScreen(1)
            setLoader(false)
            refreshPage()
          }
        } else {
          toast.error('something went wrong');
        }

      }).catch((err: any) => {
        console.log(err);
        toast.error('something went wrong');
      })

  }, [shipmentData, showScreen, loader]);

  return (
    <>
      <AdminSidebar title="Credit Customer" />
      <div className="relative md:ml-64 bg-[#FAFAFA] ">
        <AdminNavbar title="Credit Customer" />

        <div className={showScreen === 1 ? "block " : "hidden"}>
          <div className=' lg:px-10 pb-6 px-6 pt-5 bg-[#FAFAFA] min-h-screen'>

            <div className='flex justify-end mt-5'>
              <button
                onClick={() => setShowScreen(2)}
                type='button'
                className='bg-[#E04343] rounded-[32px] py-[6px] px-[25px] text-white font-medium'
              >
                + Create
              </button>
            </div>

            <div className={'flex justify-end mt-5'}>

              <div className='flex gap-3'>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 start-0 flex items-center pl-3 pointer-events-none">

                    <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                    </svg>
                  </div>
                  <input type="text" onChange={(e: any) => setSearchText(e.target.value)} className="bg-white text-gray-900 text-sm rounded-[20px] block w-full pl-10 p-1.5" placeholder="Search orders here..." required />
                </div>

                <button
                  onClick={() => paginator('')}
                  type='button'
                  className={'bg-[#E04343] rounded-[32px] px-[20px] border-red-100 text-sm text-white'}
                >
                  Search
                </button>
              </div>
            </div>
            <hr className='mt-3 bg-[#C4C4C44D]' />

            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 border-separate border-spacing-y-3">
                <thead className="text-[12px] text-[#808080] bg-[#FAFAFA] ">
                  <tr>

                    <th scope="col" className="px-2 py-2">
                      S/N
                    </th>
                    {/* <th scope="col" className="px-2 py-2">
                      Run&nbsp;ID
                    </th> */}
                    <th scope="col" className="px-2 py-2">
                      Company&nbsp;Name
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Phone&nbsp;Number
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Email&nbsp;Address
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Officer&nbsp;Name
                    </th>

                    <th scope="col" className="px-2 py-2">
                      Banker 1
                    </th>

                    <th scope="col" className="px-2 py-2">
                      Banker 2
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Officer&nbsp;In&nbsp;Charge
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Account&nbsp;Commencement&nbsp;Date
                    </th>

                    <th scope="col" className="px-2 py-2">
                      Advance&nbsp;Payment&nbsp;Date
                    </th>

                    <th scope="col" className="px-2 py-2">
                      Review By
                    </th>

                    <th scope="col" className="px-2 py-2">
                      Review Status
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Approved By
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Approval Status
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Action
                    </th>



                  </tr>
                </thead>


                <tbody className=''>

                  {
                    creditCustomers?.data?.map(
                      (datas: any, index: any) => (
                        <tr className="bg-white  ">

                          <td className="px-4 py-2">
                            {index + 1}
                          </td>

                          {/* <td className="px-6 py-4">
                            {datas?.run_id}
                          </td> */}
                          <td className="px-4 py-4">
                            {datas?.companyName}
                          </td>

                          <td className="px-4 py-4 ">
                            {datas?.phoneNumber}
                          </td>
                          <td className="px-4 py-4">
                            {datas?.email}
                          </td>

                          <td className="px-4 py-4">
                            {datas?.authorizingOfficer}
                          </td>
                          <td className="px-4 py-4">
                            {datas?.banker1}
                          </td>
                          <td className="px-4 py-4">
                            {datas?.banker2}
                          </td>
                          <td className="px-4 py-4">
                            {datas?.officerInCharge}
                          </td>
                          <td className="px-4 py-4">
                            {datas?.accountCommencementDate}
                          </td>
                          <td className="px-4 py-4">
                            {datas?.advancePaymentDate}
                          </td>
                          <td className="px-4 py-4">
                            {datas?.reviewedBy}
                          </td>



                          <td className="px-4 py-4">

                            {datas?.reviewStatus == 'Reviewed' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#C9F0D0', color: '#51CF66', borderRadius: '10px', padding: '2px 10px' }}>{datas?.reviewStatus}</b></p> : ''}
                            {datas?.reviewStatus == 'pending' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#FFDDBD', color: '#FF922B', borderRadius: '10px', padding: '2px 10px' }}>{datas?.reviewStatus}</b></p> : ''}

                          </td>

                          <td className="px-4 py-4">
                            {datas?.approvedBy}
                          </td>

                          <td className="px-4 py-4">
                            {datas?.approvalStatus == 'Approved' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#C9F0D0', color: '#51CF66', borderRadius: '10px', padding: '2px 10px' }}>{datas?.approvalStatus}</b></p> : ''}
                            {datas?.approvalStatus == 'pending' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#FFDDBD', color: '#FF922B', borderRadius: '10px', padding: '2px 10px' }}>{datas?.approvalStatus}</b></p> : ''}

                          </td>



                          <td className="px-4 py-4 cursor-pointer flex justify-between gap-5">
                            <NavLink to={`/admin/view-credit-customers/${datas?.id}`}>
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="24" height="24" rx="4" fill="#EBEBEB" />
                                <path d="M12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5Z" fill="#3F83F8" />
                                <path d="M15.2536 9.30891C14.2289 8.60625 13.1323 8.25 11.9944 8.25C10.9704 8.25 9.97219 8.55469 9.02742 9.15187C8.07469 9.75539 7.1407 10.8516 6.375 12C6.99422 13.0312 7.84125 14.0916 8.72344 14.6995C9.73547 15.3966 10.8359 15.75 11.9944 15.75C13.1428 15.75 14.2409 15.3968 15.2592 14.7002C16.1557 14.0859 17.0088 13.027 17.625 12C17.0067 10.9821 16.1508 9.92437 15.2536 9.30891ZM12 14.25C11.555 14.25 11.12 14.118 10.75 13.8708C10.38 13.6236 10.0916 13.2722 9.92127 12.861C9.75097 12.4499 9.70642 11.9975 9.79323 11.561C9.88005 11.1246 10.0943 10.7237 10.409 10.409C10.7237 10.0943 11.1246 9.88005 11.561 9.79323C11.9975 9.70642 12.4499 9.75097 12.861 9.92127C13.2722 10.0916 13.6236 10.38 13.8708 10.75C14.118 11.12 14.25 11.555 14.25 12C14.2493 12.5965 14.012 13.1684 13.5902 13.5902C13.1684 14.012 12.5965 14.2493 12 14.25Z" fill="#3F83F8" />
                              </svg>
                            </NavLink>

                            {userLoginData?.email == datas?.reviewedBy ?
                              <div onClick={() => toggleStatus(datas)} className="mt-1 cursor-pointer">
                                <FaPen />
                              </div>
                              :
                              ''
                            }


                            {userLoginData?.email == datas?.approvedBy ?
                              <div onClick={() => toggleApprove(datas)} className="mt-1 cursor-pointer">
                                <FaPen />
                              </div>
                              :
                              ''
                            }

                          </td>

                        </tr>
                      )
                    )

                  }

                </tbody>

              </table>
            </div>

            <div className='m-4 flex justify-end mt-20'>
              {
                creditCustomers?.links?.filter(((item: any, idx: any) => idx < 1000)).map(
                  (datas: any, index: any) => (
                    <button onClick={() => paginator(datas?.label == 'Next &raquo;' ? datas?.url?.charAt(datas?.url?.length - 1) : (datas?.label === '&laquo; Previous') ? datas?.url?.charAt(datas?.url?.length - 1) : datas?.label)} disabled={datas?.active} className={'mx-1 py-1 px-2 ' + ((datas?.active == false || datas?.url == null) ? 'bg-gray-300 text-black ' : 'bg-[#E04343] text-white')}>
                      {datas?.label == '&laquo; Previous' ? '< Previous' : (datas?.label === 'Next &raquo;') ? 'Next  >' : datas?.label}
                    </button>
                  )
                )
              }

            </div>

          </div>
        </div>
        <div>
          <form onSubmit={schedule}>
            <div className={showScreen === 2 ? "block " : "hidden"}>
              <div className=' lg:px-10 pb-6 px-6 bg-[#FAFAFA] min-h-screen'>
                {/* <!-- Sender Information --> */}
                <div className="px-6 lg:mt-5 bg-white pt-[24px] rounded-[10px]">

                  <div>

                    <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                      <div className="relative flex flex-col min-w-0 break-words w-full  ">
                        <div className="flex-auto   py-10 pt-0">
                          <div className="flex flex-wrap ">

                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Name of Company
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Company Name"
                                  name="companyName"
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>



                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Telephone Number
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Company Phone Number"
                                  name="phoneNumber"
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>



                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  E-Mail Address
                                </label>
                                <input
                                  type="email"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Company Email"
                                  name="email"
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>

                            <div className="w-full lg:w-8/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Registered Address
                                </label>
                                <textarea
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Company Address"
                                  name="address"
                                  onChange={(e: any) => handleShipmentChange(e)}
                                  required
                                />
                              </div>
                            </div>


                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Name of Authorizing Officer
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Officer Name"
                                  name="authorizingOfficer"
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>


                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Name Of Banker / Branch 1
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Banker / Branch 1"
                                  name="banker1"
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>

                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Name Of Banker / Branch 2
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="banker / Branch 2"
                                  name="banker2"
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>


                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full">
                                <label className="block mb-2 text-[12px] font-semibold text-[#19213D]">
                                  Name of Officer In Charge Of Mailroom Or Pick Up Point
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Officer Name"
                                  name="officerInCharge"
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>


                            <div className="w-full lg:w-8/12 lg:pr-4">
                              <div className="relative w-full">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  State Briefly The Procedure For Payment
                                </label>
                                <textarea

                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Payment Procedure"
                                  name="procedureForPayment"
                                  onChange={(e: any) => handleShipmentChange(e)}
                                  required
                                />
                              </div>
                            </div>


                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Pick up days
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="e.g Mon,Tue,Wed..."
                                  name="pickupDays"
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>


                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Account Commencement Date
                                </label>
                                <input
                                  type="date"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder=""
                                  name="accountCommencementDate"
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>

                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Advanced Payment date
                                </label>
                                <input
                                  type="date"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder=""
                                  name="advancePaymentDate"
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>


                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  To Be Reviewed By
                                </label>
                                <input
                                  type="email"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder=""
                                  name="reviewedBy"
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>


                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  To Be Approved By
                                </label>
                                <input
                                  type="email"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder=""
                                  name="approvedBy"
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>



                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>

                {/* <!-- Receiver  Information --> */}


                <div className='flex justify-center mt-6'>

                  <button
                    // onClick={() => setShowScreen(2)}
                    type='submit'
                    disabled={loader}
                    className='bg-[#E04343] rounded-[10px] py-[5px] px-[20px] text-white inline-flex'>
                    <div className="flex justify-center gap-3 ">
                      <span> Create Account</span>
                      <Oval
                        visible={loader}
                        height="20"
                        width="20"
                        color="#E04343"
                        secondaryColor="#FCBF94"
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    </div>
                  </button>
                </div>
              </div>
            </div>

            {/* <!-- Shipments  Information --> */}

          </form>
        </div>

      </div>

      <section>
        <Modal
          visible={statusModal}
          width="350"
          height="300"
          effect="fadeInUp"
          onClickAway={() => setStatusModal(false)}
        >
          <div className=" " style={{ height: '100%', overflow: 'auto' }}>

            <div className="container  bg-[#fff] rounded-lg p-2">

              <div className="px-3">

                {/* <span className="flex justify-around">
                    <h1 className=" text-xs text-red-600" style={{ fontSize: '10px' }}>Link can’t be edited in free plan. <span style={{ color: '#61A24F' }} className="font-bold text-xs">Upgrade to Pro</span></h1>
                  </span> */}
                <span className="flex justify-between px-2 pt-3">
                  <p className='mt-2'>Update Information</p>
                  <p className="cursor-pointer font-bold" onClick={(e) => setStatusModal(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>
                </span>

                <div className='my-10 text-[14px] text-gray-600'>
                  By Clicking on the Reviewed button, you agree to have reviewed this information
                </div>
                <form className="pb-4 rounded-lg" onSubmit={updateToReview}>
                  <span className="flex justify-center pt-4">
                    <button
                      type="submit"
                      disabled={loader}
                      style={{ borderRadius: '50px', color: '#F52424' }}
                      className=" text-red-700 bg-red-200 focus:ring-4 focus:outline-none focus:ring-grredeen-300 font-medium rounded-lg text-sm w-full px-2 py-2.5 text-center "
                    >
                      <div className="flex justify-center gap-3 ">
                        <span> Reviewed</span>
                        <Oval
                          visible={loader}
                          height="20"
                          width="20"
                          color="#E04343"
                          secondaryColor="#FCBF94"
                          ariaLabel="oval-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </div>

                    </button>
                  </span>

                  <span className="flex justify-center pt-4">
                    <button
                      type="button"
                      onClick={(e) => setStatusModal(false)}
                      style={{ borderRadius: '50px' }}
                      className=" text-black   focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm w-full px-2 py-1.5 text-center "
                    >
                      Cancel
                    </button>
                  </span>

                </form>
              </div>

            </div>

          </div>
        </Modal>
      </section>



      <section>
        <Modal
          visible={approveModal}
          width="350"
          height="300"
          effect="fadeInUp"
          onClickAway={() => setApproveModal(false)}
        >
          <div className=" " style={{ height: '100%', overflow: 'auto' }}>

            <div className="container  bg-[#fff] rounded-lg p-2">

              <div className="px-3">

                {/* <span className="flex justify-around">
                    <h1 className=" text-xs text-red-600" style={{ fontSize: '10px' }}>Link can’t be edited in free plan. <span style={{ color: '#61A24F' }} className="font-bold text-xs">Upgrade to Pro</span></h1>
                  </span> */}
                <span className="flex justify-between px-2 pt-3">
                  <p className='mt-2'>Update Information</p>
                  <p className="cursor-pointer font-bold" onClick={(e) => setApproveModal(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>
                </span>

                {data?.reviewStatus == 'pending'
                  ?
                  <div className='my-10 text-[14px] text-gray-600'>
                    Kindly await {data?.reviewedBy} to review before your approval.
                  </div>
                  :
                  <div className='my-10 text-[14px] text-gray-600'>
                    By Clicking on the Approved button, you agree to  Approve this information
                  </div>
                }


                <form className="pb-4 rounded-lg" onSubmit={updateToApproved}>
                  <span className="flex justify-center pt-4">
                    {data?.reviewStatus == 'pending'
                      ?
                      <button
                        type="submit"
                        disabled
                        style={{ borderRadius: '50px' }}
                        className=" text-gray-700 bg-gray-200 focus:ring-4 focus:outline-none focus:ring-grredeen-300 font-medium rounded-lg text-sm w-full px-2 py-2.5 text-center "
                      >
                        Awaiting Review
                      </button>
                      :
                      <button
                        type="submit"
                        disabled={loader}
                        style={{ borderRadius: '50px', color: '#F52424' }}
                        className=" text-red-700 bg-red-200 focus:ring-4 focus:outline-none focus:ring-grredeen-300 font-medium rounded-lg text-sm w-full px-2 py-2.5 text-center "
                      >

                        <div className="flex justify-center gap-3 ">
                          <span>Approve</span>
                          <Oval
                            visible={loader}
                            height="20"
                            width="20"
                            color="#E04343"
                            secondaryColor="#FCBF94"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                        </div>

                      </button>
                    }
                  </span>

                  <span className="flex justify-center pt-4">
                    <button
                      type="button"
                      onClick={(e) => setStatusModal(false)}
                      style={{ borderRadius: '50px' }}
                      className=" text-black   focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm w-full px-2 py-1.5 text-center "
                    >
                      Cancel
                    </button>
                  </span>

                </form>
              </div>

            </div>

          </div>
        </Modal>
      </section>



      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  )
}

export default CreditCustomer
/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import { SvgElement, icontypesEnum } from "../assets/svgElement";
import { Dispatch } from "redux";
import { useSelector, useDispatch } from 'react-redux';
import { login } from '../../reducer/loginSlice'
import { useNavigate } from 'react-router-dom';
import { AiOutlineSetting } from "react-icons/ai";
import { IoArrowBack } from "react-icons/io5";
import { MdDashboard } from "react-icons/md";
import { FaShippingFast } from "react-icons/fa";
import { HiCalculator } from "react-icons/hi2";
import { IoMdSettings } from "react-icons/io";
import { FaShip } from "react-icons/fa";
import { IoBookOutline } from "react-icons/io5";
import { FaCalculator } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { AuthApis } from "../../apis/authApis";
import { AxiosResponse } from "axios";
import { UserApis } from "../../apis/userApi";

export default function AdminSidebar(title: any) {
  const navigate = useNavigate();
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const dispatch: Dispatch = useDispatch();
  const userLoginData = useSelector((state: any) => state.data.login.value);

  const [canViewAdminStat, setCanViewAdminStat] = React.useState<boolean>(false);
  const [canViewUsers, setCanViewUsers] = React.useState<boolean>(false);
  const [canManageCreditCustomers, setCanManageCreditCustomers] = React.useState<boolean>(false);
  const [canCreateExpenseVoucher, setCanCreateExpenseVoucher] = React.useState<boolean>(false);
  const [canManageShipment, setCanManageShipment] = React.useState<boolean>(false);
  const [canManageManifest, setCanManageManifest] = React.useState<boolean>(false);
  const [canManageRunwayManifest, setCanManageRunwayManifest] = React.useState<boolean>(false);
  const [canManageFleet, setCanManageFleet] = React.useState<boolean>(false);
  const [canManageBranch, setCanManageBranch] = React.useState<boolean>(false);
  const [canViewAudit, setCanViewAudit] = React.useState<boolean>(false);
  const [canManageStaffs, setCanManageStaffs] = React.useState<boolean>(false);
  const [canManageInvoice, setCanManageInvoice] = React.useState<boolean>(false);
  const [canManageRates, setCanManageRates] = React.useState<boolean>(false);


  const [permissions, setPermissions] = React.useState<any>(userLoginData?.data?.permissions ? JSON.parse(userLoginData?.data?.permissions) : ['']);

  React.useEffect(() => {
    permissions?.map(
      (data: any, index: any) => {
        if (data === 'can_view_admin_stat') {
          setCanViewAdminStat(true);
        } else if (data === 'can_view_users') {
          setCanViewUsers(true);
        } else if (data === 'can_manage_credit_customers') {
          setCanManageCreditCustomers(true);
        } else if (data === 'can_create_expense_voucher') {
          setCanCreateExpenseVoucher(true);
        } else if (data === 'can_manage_shipment') {
          setCanManageShipment(true);
        } else if (data === 'can_manage_manifest') {
          setCanManageManifest(true);
        } else if (data === 'can_manage_runway_manifest') {
          setCanManageRunwayManifest(true);
        } else if (data === 'can_manage_fleet') {
          setCanManageFleet(true);
        } else if (data === 'can_manage_branch') {
          setCanManageBranch(true);
        } else if (data === 'can_view_audit') {
          setCanViewAudit(true);
        } else if (data === 'can_manage_staffs') {
          setCanManageStaffs(true);
        } else if (data === 'can_manage_invoice') {
          setCanManageInvoice(true);
        } else if (data === 'can_manage_rates') {
          setCanManageRates(true);
        }else if(userLoginData?.data?.role == 'admin'){
          setCanViewAdminStat(true);
          setCanViewUsers(true);
          setCanManageCreditCustomers(true);
          setCanCreateExpenseVoucher(true);
          setCanManageShipment(true);
          setCanManageManifest(true);
          setCanManageRunwayManifest(true);
          setCanManageFleet(true);
          setCanManageBranch(true);
          setCanViewAudit(true);
          setCanManageStaffs(true);
          setCanManageInvoice(true);
          setCanManageRates(true);
        }
      }
    )

  }, []);




  React.useEffect(() => {
    AuthApis.session().then(
      (response: AxiosResponse<any>) => {
        if (response?.data?.status) {
        } else {
          dispatch(login([]))
          navigate('/staff-login');
        }
      }
    ).catch(function (error: any) {
      // handle error
      console.log(error);
      console.log("new error");
    })
  }, []);

  React.useEffect(() => {
    userLoginData?.data?.isVerified == 'false'
      ?
      verify()
      :
      ''
  }, []);

  React.useEffect(() => {
    if (userLoginData?.data?.role == 'user') {
      dispatch(login([]))
      navigate('/staff-login')
    }

  }, []);


  const logOut = () => {
    AuthApis.logout('').then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          dispatch(login([]))
          navigate('/staff-login');

        }
      }
    ).catch(function (error: any) {
      // handle error
      console.log(error.response.data);
      console.log("new error");
    })

  };


  const verify = () => {
    AuthApis.logout('').then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          dispatch(login([]))
          navigate('/email-verify');

        }
        navigate('/email-verify');
      }
    ).catch(function (error: any) {
      // handle error
      // console.log(error.response.data);
      // console.log("new error");
    })

  };

  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0  md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl md:bg-[#ffffff]  mt-2 ml-1 relative md:w-64 z-2 py-2 px-1">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <b className="fas fa-bars text-3xl"> ≡ </b><span className="text-black">{title?.title}</span>
          </button>
          {/* Brand */}

          <span className="flex justify-end md:hidden">
            <span className="flex  flex-row flex-wrap items-center lg:ml-auto mr-3">
              <div className="relative flex w-full flex-wrap items-stretch gap-5">

                <h2 className="pt-2 capitalize">{(userLoginData?.data?.role)?.replace(/_/g, ' ')} </h2>
                <NavLink to='/editprofile'>
                  <h2 className=" pt-2"><SvgElement type={icontypesEnum.SETTINGS} /></h2>
                </NavLink>

              </div>
            </span>
          </span>

          <Link
            className="hidden md:pb-6 mt-10 lg:flex justify-center"
            to="/"
          >
            <SvgElement type={icontypesEnum.BARS2} />
          </Link>
          {/* User */}

          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow bg-[#ffffff] absolute top-4 left-0 right-1  h-auto flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-1 mb-4  ">
              <div className="flex justify-between ">

                <span className="flex justify-start gap-1" onClick={() => setCollapseShow("hidden")}><IoArrowBack style={{ color: '#333333' }} className="bg-gray-200 text-xs text-gray-500 rounded-full p-1 h-6 w-6" /> <span className="text-white ml-2">Back</span></span>

                <span
                  className="cursor-pointer opacity-50 md:hidden  py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <NavLink to='/editprofile'>
                    <b className="fas fa-times text-white"> <AiOutlineSetting /> </b>
                  </NavLink>
                </span>


              </div>

            </div>
            {/* Form */}


            {/* Divider */}
            {/* <hr className="md:min-w-full" /> */}

            <ul className="flex-col list-none flex bg-[#ffffff]  md:mt-1 mt-2 overflow-auto max-h-[70vh]">



              {
                canViewAdminStat ?
                  <li className="items-center mb-4">
                    <NavLink to="/admin/admin-dashboard" className="flex mx-4 py-[11px] pl-[15px] gap-2 rounded-[5px] text-[14px] text-[#808080] hover:bg-[#FF3B300D] hover:text-[#E04343] hover:border-l-[3px] hover:border-[#E04343]"
                      style={({ isActive }) =>
                        isActive ? { color: '#ffffff', background: "#E04343" } : { color: '' }
                      }
                    >
                      <MdDashboard className="" size={24} />
                      <span className="mt-0.5"> Overview</span>
                    </NavLink>
                  </li>
                  :
                  ''
              }



              {
                canViewUsers
                  ?
                  <li className="items-center mb-4">
                    <NavLink to="/admin/users" className="flex mx-4 py-[11px] pl-[15px] gap-2 rounded-[5px] text-[14px] text-[#808080] hover:bg-[#FF3B300D] hover:text-[#E04343] hover:border-l-[3px] hover:border-[#E04343]"
                      style={({ isActive }) =>
                        isActive ? { color: '#ffffff', background: "#E04343" } : { color: '' }
                      }
                    >
                      <MdDashboard className="" size={24} />
                      <span className="mt-0.5"> Users</span>
                    </NavLink>
                  </li>
                  :
                  ""
              }



              {
                canManageCreditCustomers
                  ?
                  <li className="items-center mb-4">
                    <NavLink to="/admin/credit-customers" className="flex mx-4 py-[11px] pl-[15px] gap-2 rounded-[5px] text-[14px] text-[#808080] hover:bg-[#FF3B300D] hover:text-[#E04343] hover:border-l-[3px] hover:border-[#E04343]"
                      style={({ isActive }) =>
                        isActive ? { color: '#ffffff', background: "#E04343" } : { color: '' }
                      }
                    >
                      <FaShip className="" size={24} />
                      <span className="mt-0.5">Credit Customer</span>
                    </NavLink>
                  </li>
                  :
                  ""
              }


             
              <li className="items-center mb-4">
                <NavLink to="/admin/expense-voucher" className="flex mx-4 py-[11px] pl-[15px] gap-2 rounded-[5px] text-[14px] text-[#808080] hover:bg-[#FF3B300D] hover:text-[#E04343] hover:border-l-[3px] hover:border-[#E04343]"
                  style={({ isActive }) =>
                    isActive ? { color: '#ffffff', background: "#E04343" } : { color: '' }
                  }
                >
                  <FaShip className="" size={24} />
                  <span className="mt-0.5">Expense Voucher</span>
                </NavLink>
              </li>
              {/* :
                  ""
              } */}



              {
                (canManageShipment || userLoginData?.data?.role == 'fleet')
                  ?
                  <li className="items-center mb-4">
                    <NavLink to="/admin/shipments" className="flex mx-4 py-[11px] pl-[15px] gap-2 rounded-[5px] text-[14px] text-[#808080] hover:bg-[#FF3B300D] hover:text-[#E04343] hover:border-l-[3px] hover:border-[#E04343]"
                      style={({ isActive }) =>
                        isActive ? { color: '#ffffff', background: "#E04343" } : { color: '' }
                      }
                    >
                      <FaShip className="" size={24} />
                      <span className="mt-0.5">Shipments</span>
                    </NavLink>
                  </li>
                  :
                  ''
              }



              {
                (canManageManifest || userLoginData?.data?.role == 'fleet')
                  ?
                  <li className="items-center mb-4">
                    <NavLink to="/admin/manifest" className="flex mx-4 py-[11px] pl-[15px] gap-2 rounded-[5px] text-[14px] text-[#808080] hover:bg-[#FF3B300D] hover:text-[#E04343] hover:border-l-[3px] hover:border-[#E04343]"
                      style={({ isActive }) =>
                        isActive ? { color: '#ffffff', background: "#E04343" } : { color: '' }
                      }
                    >
                      <FaShip className="" size={24} />
                      <span className="mt-0.5">Manifest</span>
                    </NavLink>
                  </li>
                  :
                  ""
              }



              {
               (canManageRunwayManifest || userLoginData?.data?.role == 'fleet')
                  ?
                  <li className="items-center mb-4">
                    <NavLink to="/admin/runway-manifest" className="flex mx-4 py-[11px] pl-[15px] gap-2 rounded-[5px] text-[14px] text-[#808080] hover:bg-[#FF3B300D] hover:text-[#E04343] hover:border-l-[3px] hover:border-[#E04343]"
                      style={({ isActive }) =>
                        isActive ? { color: '#ffffff', background: "#E04343" } : { color: '' }
                      }
                    >
                      <FaShip className="" size={24} />
                      <span className="mt-0.5">Runway Manifest</span>
                    </NavLink>
                  </li>
                  :
                  ""
              }



              <li className="items-center mb-4">
                <NavLink to="/admin/tracking" className="flex mx-4 py-[11px] pl-[15px] gap-2 rounded-[5px] text-[14px] text-[#808080] hover:bg-[#FF3B300D] hover:text-[#E04343] hover:border-l-[3px] hover:border-[#E04343]"
                  style={({ isActive }) =>
                    isActive ? { color: '#ffffff', background: "#E04343" } : { color: '' }
                  }
                >
                  <FaShippingFast className="" size={24} />
                  <span className="mt-0.5">Tracking</span>
                </NavLink>
              </li>


              <li className="items-center mb-4">
                <NavLink to="/admin/calculate-rate" className="flex mx-4 py-[11px] pl-[15px] gap-2 rounded-[5px] text-[14px] text-[#808080] hover:bg-[#FF3B300D] hover:text-[#E04343] hover:border-l-[3px] hover:border-[#E04343]"
                  style={({ isActive }) =>
                    isActive ? { color: '#ffffff', background: "#E04343" } : { color: '' }
                  }
                >
                  <HiCalculator className="" size={24} />
                  <span className="mt-0.5">Calculate Rate</span>
                </NavLink>
              </li>


              {
                (canManageFleet || userLoginData?.data?.role == 'fleet')
                  ?
                  <li className="items-center mb-4">
                    <NavLink to="/admin/manage-riders" className="flex mx-4 py-[11px] pl-[15px] gap-2 rounded-[5px] text-[14px] text-[#808080] hover:bg-[#FF3B300D] hover:text-[#E04343] hover:border-l-[3px] hover:border-[#E04343]"
                      style={({ isActive }) =>
                        isActive ? { color: '#ffffff', background: "#E04343" } : { color: '' }
                      }
                    >
                      <IoBookOutline className="" size={24} />
                      <span className="mt-0.5">Manage Fleet</span>
                    </NavLink>
                  </li>
                  :
                  ""
              }




              {
                canManageBranch
                  ?
                  <li className="items-center mb-4">
                    <NavLink to="/admin/manage-branch" className="flex mx-4 py-[11px] pl-[15px] gap-2 rounded-[5px] text-[14px] text-[#808080] hover:bg-[#FF3B300D] hover:text-[#E04343] hover:border-l-[3px] hover:border-[#E04343]"
                      style={({ isActive }) =>
                        isActive ? { color: '#ffffff', background: "#E04343" } : { color: '' }
                      }
                    >
                      <IoBookOutline className="" size={24} />
                      <span className="mt-0.5">Manage Branch</span>
                    </NavLink>
                  </li>
                  :
                  ""
              }


              {
                canViewAudit
                  ?
                  <li className="items-center mb-4">
                    <NavLink to="/admin/audits" className="flex mx-4 py-[11px] pl-[15px] gap-2 rounded-[5px] text-[14px] text-[#808080] hover:bg-[#FF3B300D] hover:text-[#E04343] hover:border-l-[3px] hover:border-[#E04343]"
                      style={({ isActive }) =>
                        isActive ? { color: '#ffffff', background: "#E04343" } : { color: '' }
                      }
                    >
                      <IoBookOutline className="" size={24} />
                      <span className="mt-0.5">Audits</span>
                    </NavLink>
                  </li>
                  :
                  ""
              }



              {
                canManageStaffs
                  ?
                  <li className="items-center mb-4">
                    <NavLink to="/admin/manage-staffs" className="flex mx-4 py-[11px] pl-[15px] gap-2 rounded-[5px] text-[14px] text-[#808080] hover:bg-[#FF3B300D] hover:text-[#E04343] hover:border-l-[3px] hover:border-[#E04343]"
                      style={({ isActive }) =>
                        isActive ? { color: '#ffffff', background: "#E04343" } : { color: '' }
                      }
                    >
                      <IoBookOutline className="" size={24} />
                      <span className="mt-0.5">Manage Staffs</span>
                    </NavLink>
                  </li>
                  :
                  ""
              }

              {
                canManageInvoice
                  ?
                  <li className="items-center mb-4">
                    <NavLink to="/admin/manage-invoice" className="flex mx-4 py-[11px] pl-[15px] gap-2 rounded-[5px] text-[14px] text-[#808080] hover:bg-[#FF3B300D] hover:text-[#E04343] hover:border-l-[3px] hover:border-[#E04343]"
                      style={({ isActive }) =>
                        isActive ? { color: '#ffffff', background: "#E04343" } : { color: '' }
                      }
                    >
                      <IoBookOutline className="" size={24} />
                      <span className="mt-0.5">Manage Invoice</span>
                    </NavLink>
                  </li>
                  :
                  ""
              }




              {
                canManageRates
                  ?
                  <li className="items-center mb-4">
                    <NavLink to="/admin/manage-rates" className="flex mx-4 py-[11px] pl-[15px] gap-2 rounded-[5px] text-[14px] text-[#808080] hover:bg-[#FF3B300D] hover:text-[#E04343] hover:border-l-[3px] hover:border-[#E04343]"
                      style={({ isActive }) =>
                        isActive ? { color: '#ffffff', background: "#E04343" } : { color: '' }
                      }
                    >
                      <FaCalculator className="" size={24} />
                      <span className="mt-0.5">Manage Rates</span>
                    </NavLink>
                  </li>
                  :
                  ""
              }







              <li className="items-center mb-4">
                <NavLink to="/settings" className="flex mx-4 py-[11px] pl-[15px] gap-2 rounded-[5px] text-[14px] text-[#808080] hover:bg-[#FF3B300D] hover:text-[#E04343] hover:border-l-[3px] hover:border-[#E04343]"
                  style={({ isActive }) =>
                    isActive ? { color: '#ffffff', background: "#E04343" } : { color: '' }
                  }
                >
                  <IoMdSettings className="" size={24} />
                  <span className="mt-0.5"> Settings</span>
                </NavLink>
              </li>

              {/* <li className="items-center mb-4">
                <NavLink to="/dashboard" className="flex mx-4 py-[11px] pl-[15px] gap-2 rounded-[5px] text-[14px] text-[#808080] hover:bg-[#FF3B300D] hover:text-[#E04343] hover:border-l-[3px] hover:border-[#E04343]"
                  style={({ isActive }) =>
                    isActive ? { color: '#ffffff', background: "#E04343" } : { color: '' }
                  }
                >
                  <MdDashboard className="" size={24} />
                  <span className="mt-0.5"> Overview</span>
                </NavLink>
              </li> */}









              <li className="items-center mx-3 pl-3 pt-10">
                <span
                  //  style={{backgroundColor:'#61A24F'}}
                  className={
                    "text-xs cursor-pointer block "
                  }

                  onClick={logOut}
                >

                  <span className="flex py-2  cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="mr-3 " viewBox="0 0 24 24"><path fill="red" d="M16 13v-2H7V8l-5 4l5 4v-3z" /><path fill="red" d="M20 3h-9c-1.103 0-2 .897-2 2v4h2V5h9v14h-9v-4H9v4c0 1.103.897 2 2 2h9c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2z" /></svg>
                    <span className=" text-[15px] mt-1 font-normal text-[#FF0000]">Sign out</span>
                    {/* <span style={{ color: 'red' }}>  <SvgElement type={icontypesEnum.REDARROW} /> </span> */}
                  </span>

                </span>
              </li>

            </ul>


          </div>
        </div>
      </nav>
    </>
  );
}

import React from 'react'
import AdminNavbar from "../Navbars/AdminNavbar.js";
import Sidebar from "../Sidebar/Sidebar";
import { AxiosResponse } from 'axios';
import { UserApis } from '../../apis/userApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { convertUTCDateToLocalDate } from '../Reusable/functions';
import { NavLink } from 'react-router-dom';
//@ts-ignore
import Modal from 'react-awesome-modal';
import { SvgElement, icontypesEnum } from '../assets/svgElement';
import { SuperAdminApis } from '../../apis/superAdminApi';
import { STATUSTYPES } from '../Reusable/Status';
import { CURRENCYCODE } from '../Reusable/CurrencyCode';
import { COUNTRYCODE } from '../Reusable/CountryCode';
import AdminSidebar from '../Sidebar/AdminSidebar';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import { MdDelete } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { MdDownload } from "react-icons/md";



function Shipments() {
  let [showScreen, setShowScreen] = React.useState<any>(1);

  const navigate = useNavigate();

  const userLoginData = useSelector((state: any) => state.data.login.value);
  let [shipmentData, setShipmentData] = React.useState<any>({
    SenderName: "cust", SenderEmail: userLoginData?.data?.email, SenderCountryCode: '+234', SenderAdd1: 'no address',
    SenderAddCity: 'empty', SenderAddState: '', SenderAddPostcode: 'empty',
    SenderTaxID: '', SenderPhone: '0', senderLga: 'empty',
    //Reciever information
    ReceiverName: "", ReceiverEmail: "", ReceiverCountryCode: '+234', ReceiverAdd1: 'no address',
    ReceiverAddCity: 'empty', ReceiverAddState: '', ReceiverAddPostcode: 'empty',
    ReceiverTaxID: '', recieverLga: 'empty', ReceiverPhone: '0',
    //Shipment Information 1
    ServiceTypeName: "", NoOfItems: "", HarmonisedCode: '', CubicL: '0',
    CubicW: '0', CubicH: '0', CubicWeight: '0', DeadWeight: '0', CustomValue: '0',
    CustomCurrencyCode: 'NG', CODAmount: '0', CODCurrencyCode: 'NG',
    //shipment information 2
    GoodsDescription: "", ShipmentTerm: "DDU", Incoterms: 'CFR', BusinessType: 'B2C', SenderRef1: '',
    SenderRef2: '', GoodsOriginCountryCode: 'NG', ReasonExport: '', DeliveryInstructions: '', Notes: '',
    //Shipment information 3
    CPCCode: "0", SKUNumber: "0", ATENumber: '0', ProductURL: '',
    GoodsValue: '0', GoodsCurrencyCode: 'NG', DutyValue: '0', DutyCurrencyCode: 'NG', EORINumber: '0',
    FreightCost: '', FreightCostCurrencyCode: 'NG', TotalGSTAmount: '0', TotalGSTCurrencyCode: 'NG',
  });


  // let [shipmentData, setShipmentData] = React.useState<any>({
  //   SenderName: "", SenderEmail: userLoginData?.data?.email, SenderCountryCode: '', SenderAdd1: 'no address',
  //   SenderAddCity: '', SenderAddState: '', SenderAddPostcode: 'empty',
  //   SenderTaxID: '', SenderPhone: '0', senderLga: 'empty',
  //   //Reciever information
  //   ReceiverName: "", ReceiverEmail: "", ReceiverCountryCode: '', ReceiverAdd1: 'no address',
  //   ReceiverAddCity: '', ReceiverAddState: '', ReceiverAddPostcode: 'empty',
  //   ReceiverTaxID: '',recieverLga: 'empty', ReceiverPhone: '0',
  //   //Shipment Information 1
  //   ServiceTypeName: "", NoOfItems: "", HarmonisedCode: '', CubicL: '0',
  //   CubicW: '0', CubicH: '0', CubicWeight: '0', DeadWeight: '', CustomValue: '0',
  //   CustomCurrencyCode: 'NG', CODAmount: '', CODCurrencyCode: 'NG',
  //   //shipment information 2
  //   GoodsDescription: "", ShipmentTerm: "DDU", Incoterms: 'CFR', BusinessType: 'B2C', SenderRef1: '',
  //   SenderRef2: '', GoodsOriginCountryCode: 'NG', ReasonExport: '', DeliveryInstructions: '', Notes: '',
  //   //Shipment information 3
  //   CPCCode: "", SKUNumber: "", ATENumber: '', ProductURL: '',
  //   GoodsValue: '', GoodsCurrencyCode: 'NG', DutyValue: '', DutyCurrencyCode: 'NG', EORINumber: '',
  //   FreightCost: '', FreightCostCurrencyCode: 'NG', TotalGSTAmount: '', TotalGSTCurrencyCode: 'NG',
  // });



  const [shipmentInfo, setShipmentInfo] = React.useState<any>([]);


  const [shipmentInfos, setShipmentInfos] = React.useState<any>([]);
  const [countryFrom, setCountryFrom] = React.useState<any>([]);
  const [countryTo, setCountryTo] = React.useState<any>([]);
  const [stateFrom, setStateFrom] = React.useState<any>([]);
  const [stateTo, setStateTo] = React.useState<any>([]);
  const [lgaFrom, setLgaFrom] = React.useState<any>([]);
  const [lgaTo, setLgaTo] = React.useState<any>([]);

  const [countryFromData, setCountryFromData] = React.useState<any>('');
  const [countryToData, setCountryToData] = React.useState<any>('');
  const [stateFromData, setStateFromData] = React.useState<any>('');
  const [stateToData, setStateToData] = React.useState<any>('');
  const [lgaFromData, setLgaFromData] = React.useState<any>('');
  const [lgaToData, setLgaToData] = React.useState<any>('');


  let [statusModal, setStatusModal] = React.useState<boolean>(false);
  let [officeModal, setOfficeModal] = React.useState<boolean>(false);
  let [riderModal, setRiderModal] = React.useState<boolean>(false);

  let [deleteModal, setDeleteModal] = React.useState<boolean>(false);



  let [shipmentId, setShipmentId] = React.useState<any>([]);

  let [delieveryInstruction, setDelieveryInstruction] = React.useState<any>("empty");
  let [shipmentStatus, setShipmentStatus] = React.useState<any>('');
  let [shipmentStatusId, setShipmentStatusId] = React.useState<any>('');
  let [shipmentOffice, setShipmentOffice] = React.useState<any>('');
  let [shipmentRider, setShipmentRider] = React.useState<any>('');
  let [shipmentVehicle, setShipmentvehicle] = React.useState<any>('');
  let [riders, setRiders] = React.useState<any>([]);
  let [vehicle, setVehicle] = React.useState<any>([]);

  let [loading, setLoading] = React.useState<boolean>(false);
  const [loader, setLoader] = React.useState<boolean>(false);
  const [loader2, setLoader2] = React.useState<boolean>(false);


  const toggleStatus = React.useCallback((data: any) => {
    setStatusModal(true);
    setShipmentId(data)

  }, [shipmentId, statusModal]);
  // function toggleStatus() {

  // }

  const updateShipment = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let eventCode = '';
    setLoading(true)

    STATUSTYPES?.filter((data: any) => data?.EventName == shipmentStatus)?.map((datas: any, index: any) => {
      setShipmentStatusId(datas?.EventCode)
      eventCode = datas?.EventCode
    }
    )

    let shiData = shipmentId;

    let shipment = [
      {
        "ThirdPartyToken": "null",
        "SenderDetails": {
          "SenderName": shiData?.SenderName ? shiData?.SenderName : 'null',
          "SenderCompanyName": shiData?.SenderCompanyName ? shiData?.SenderCompanyName : "null",
          "SenderCountryCode": shiData?.SenderCountryCode ? shiData?.SenderCountryCode : "null",
          "SenderAdd1": shiData?.SenderAdd1 ? shiData?.SenderAdd1 : "null",
          "SenderAdd2": shiData?.SenderAdd2 ? shiData?.SenderAdd2 : 'null',
          "SenderAdd3": shiData?.SenderAdd3 ? shiData?.SenderAdd3 : 'null',
          "SenderAddCity": shiData?.SenderAddCity ? shiData?.SenderAddCity : 'null',
          "SenderAddState": shiData?.SenderAddState ? shiData?.SenderAddState : 'null',
          "SenderAddPostcode": shiData?.SenderAddPostcode ? shiData?.SenderAddPostcode : 'null',
          "SenderPhone": shiData?.SenderPhone ? shiData?.SenderPhone : 'null',
          "SenderEmail": shiData?.SenderEmail ? shiData?.SenderEmail : 'null',
          "SenderFax": shiData?.SenderFax ? shiData?.SenderFax : 'null',
          "SenderKycType": shiData?.SenderKycType ? shiData?.SenderKycType : 'null',
          "SenderKycNumber": shiData?.SenderKycNumber ? shiData?.SenderKycNumber : 'null',
          "SenderReceivingCountryTaxID": shiData?.SenderReceivingCountryTaxID ? shiData?.SenderReceivingCountryTaxID : 'null'
        },
        "ReceiverDetails": {
          "ReceiverName": shiData?.ReceiverName ? shiData?.ReceiverName : 'null',
          "ReceiverCompanyName": shiData?.ReceiverCompanyName ? shiData?.ReceiverCompanyName : 'null',
          "ReceiverCountryCode": shiData?.ReceiverCountryCode ? shiData?.ReceiverCountryCode : 'null',
          "ReceiverAdd1": shiData?.ReceiverAdd1 ? shiData?.ReceiverAdd1 : 'null',
          "ReceiverAdd2": shiData?.ReceiverAdd2 ? shiData?.ReceiverAdd2 : 'null',
          "ReceiverAdd3": shiData?.ReceiverAdd3 ? shiData?.ReceiverAdd3 : 'null',
          "ReceiverAddCity": shiData?.ReceiverAddCity ? shiData?.ReceiverAddCity : 'null',
          "ReceiverAddState": shiData?.ReceiverAddState ? shiData?.ReceiverAddState : 'null',
          "ReceiverAddPostcode": shiData?.ReceiverAddPostcode ? shiData?.ReceiverAddPostcode : 'null',
          "ReceiverMobile": shiData?.ReceiverMobile ? shiData?.ReceiverMobile : 'null',
          "ReceiverPhone": shiData?.ReceiverPhone ? shiData?.ReceiverPhone : 'null',
          "ReceiverEmail": shiData?.ReceiverEmail ? shiData?.ReceiverEmail : 'null',
          "ReceiverAddResidential": shiData?.ReceiverAddResidential ? shiData?.ReceiverAddResidential : 'null',
          "ReceiverFax": shiData?.ReceiverFax ? shiData?.ReceiverFax : 'null',
          "ReceiverKycType": shiData?.ReceiverKycType ? shiData?.ReceiverKycType : 'null',
          "ReceiverKycNumber": shiData?.ReceiverKycNumber ? shiData?.ReceiverKycNumber : 'null'
        },
        "PackageDetails": {
          "GoodsDescription": shiData?.GoodsDescription ? shiData?.GoodsDescription : 'null',
          "CustomValue": shiData?.CustomValue ? +shiData?.CustomValue : 0,
          "CustomCurrencyCode": shiData?.CustomCurrencyCode ? shiData?.CustomCurrencyCode : 'null',
          "InsuranceValue": shiData?.InsuranceValue ? +shiData?.InsuranceValue : 0,
          "InsuranceCurrencyCode": shiData?.InsuranceCurrencyCode ? shiData?.InsuranceCurrencyCode : 'null',
          "ShipmentTerm": shiData?.ShipmentTerm ? shiData?.ShipmentTerm : 'null',
          "GoodsOriginCountryCode": shiData?.GoodsOriginCountryCode ? shiData?.GoodsOriginCountryCode : 'null',
          "DeliveryInstructions": shiData?.DeliveryInstructions ? shiData?.DeliveryInstructions : 'null',
          "Weight": shiData?.Weight ? +shiData?.Weight : 0,
          "WeightMeasurement": shiData?.WeightMeasurement ? shiData?.WeightMeasurement : 'null',
          "NoOfItems": shiData?.NoOfItems ? +shiData?.NoOfItems : 0,
          "CubicL": shiData?.CubicL ? +shiData?.CubicL : 0,
          "CubicW": shiData?.CubicW ? +shiData?.CubicW : 0,
          "CubicH": shiData?.CubicH ? +shiData?.CubicH : 0,
          "CubicWeight": shiData?.CubicWeight ? +shiData?.CubicWeight : 0,
          "ServiceTypeName": shiData?.ServiceTypeName ? shiData?.ServiceTypeName : 'null',
          "BookPickUP": false,
          "AlternateRef": shiData?.AlternateRef ? shiData?.AlternateRef : 'null',
          "SenderRef1": shiData?.SenderRef1 ? shiData?.SenderRef1 : 'null',
          "SenderRef2": shiData?.SenderRef2 ? shiData?.SenderRef2 : 'null',
          "SenderRef3": shiData?.SenderRef3 ? shiData?.SenderRef3 : 'null',
          "DeliveryAgentCode": shiData?.DeliveryAgentCode ? shiData?.DeliveryAgentCode : 'null',
          "DeliveryRouteCode": shiData?.DeliveryRouteCode ? shiData?.DeliveryRouteCode : 'null',
          "BusinessType": shiData?.BusinessType ? shiData?.BusinessType : 'B2B',
          "ShipmentResponseItem": [
            {
              "ItemAlt": "null",
              "ItemNoOfPcs": shiData?.NoOfItems ? +shiData?.NoOfItems : 0,
              "ItemCubicL": shiData?.CubicL ? +shiData?.CubicL : 0,
              "ItemCubicW": shiData?.CubicW ? +shiData?.CubicW : 0,
              "ItemCubicH": shiData?.CubicH ? +shiData?.CubicH : 0,
              "ItemWeight": shiData?.Weight ? +shiData?.Weight : 0,
              "ItemCubicWeight": shiData?.CubicWeight ? +shiData?.CubicWeight : 0,
              "ItemDescription": shiData?.GoodsDescription ? shiData?.GoodsDescription : 'null',
              "ItemCustomValue": shiData?.CustomValue ? +shiData?.CustomValue : 0,
              "ItemCustomCurrencyCode": shiData?.CustomCurrencyCode ? shiData?.CustomCurrencyCode : 'null',
              "Notes": shiData?.Notes ? shiData?.Notes : 'null'
            }
          ],
          "CODAmount": shiData?.CODAmount ? +shiData?.CODAmount : 0,
          "CODCurrencyCode": shiData?.CODCurrencyCode ? shiData?.CODCurrencyCode : 'null',
          "Bag": shiData?.Bag ? +shiData?.Bag : 0,
          "Notes": shiData?.Notes ? shiData?.Notes : 'null',
          "OriginLocCode": shiData?.OriginLocCode ? shiData?.OriginLocCode : 'null',
          "BagNumber": shiData?.BagNumber ? shiData?.BagNumber : 'null',
          "DeadWeight": shiData?.DeadWeight ? +shiData?.DeadWeight : 0,
          "ReasonExport": shiData?.ReasonExport ? shiData?.ReasonExport : 'null',
          "DestTaxes": shiData?.DestTaxes ? +shiData?.DestTaxes : 0,
          "Security": shiData?.Security ? +shiData?.Security : 0,
          "Surcharge": shiData?.Surcharge ? +shiData?.Surcharge : 0,
          "ReceiverTaxID": shiData?.ReceiverTaxID ? shiData?.ReceiverTaxID : 'null',
          "OrderNumber": shiData?.OrderNumber ? +shiData?.OrderNumber : 0,
          "Incoterms": shiData?.Incoterms ? shiData?.Incoterms : 'null',
          "ClearanceReference": shiData?.ClearanceReference ? shiData?.ClearanceReference : 'null'
        }

      }
    ];


    let data = {
      'id': shipmentId?.id,
      "Status": (shipmentStatus !== '' ? shipmentStatus : shipmentId?.status),
      "status_id": eventCode,
      "del_instruction": delieveryInstruction,
      'shiData': shipment
    }

    SuperAdminApis.updateShipmentStatus(data)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            setStatusModal(false)
            setShipmentStatus('')
            setLoading(false)
            setShipmentId([])
            SuperAdminApis.getAllShipments('', '').then(
              (response: any) => {
                if (response?.data) {
                  setShipmentId([])
                  setShipmentInfo(response?.data);
                  window.location.reload();
                }
              }
            )
          }
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        console.log(err);
        setLoading(false)
      })

  }, [shipmentData, statusModal, shipmentInfo, shipmentId, shipmentStatus, delieveryInstruction, loading]);

  const updateShipmentOffice = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let data = {
      'id': shipmentId?.id,
      "office": (shipmentOffice !== '' ? shipmentOffice : shipmentId?.office)
    }

    SuperAdminApis.updateShipmentOffice(data)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            setOfficeModal(false)
            setShipmentOffice('')
            SuperAdminApis.getAllShipments('', '').then(
              (response: any) => {
                if (response?.data) {
                  setShipmentInfo(response?.data);
                }
              }
            )
          }
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        console.log(err);
      })

  }, [shipmentData, officeModal, shipmentInfo, shipmentId, shipmentOffice]);

  const [total, setTotal] = React.useState<any>('');
  const [pending, setPending] = React.useState<any>('');
  const [verified, setVerified] = React.useState<any>('');
  const [others, setOthers] = React.useState<any>('');
  const [fleetData, setFleetData] = React.useState<any>('');


  const updateShipmentRider = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let data = {
      'id': shipmentId?.id,
      "rider": (shipmentRider !== '' ? shipmentRider : shipmentId?.rider),
      "fleet_id": fleetData?.id,
      "fleet_name": fleetData?.first_name,
      "vehicle_name": shipmentVehicle,
    }

    SuperAdminApis.updateShipmentRider(data)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            setRiderModal(false)
            setShipmentRider('')
            UserApis.getAllShipmentsByUser('').then(
              (response: any) => {
                if (response?.data) {
                  setShipmentInfo(response?.data?.data);

                  SuperAdminApis.getAllShipments('', '').then(
                    (response: any) => {
                      if (response?.data) {
                        setShipmentInfo(response?.data);
                        setPending(response?.data?.out_bound?.data?.filter((e: any) => e?.Status == 'inReview')?.length)
                        setVerified(response?.data?.out_bound?.data?.filter((e: any) => e?.Status == 'Verified')?.length)
                        setOthers(response?.data?.out_bound?.data?.filter((e: any) => (e?.Status !== 'Verified' && e?.Status !== 'inReview'))?.length)
                        setTotal(response?.data?.out_bound?.data?.length)
                      }
                    }
                  ).catch(function (error) {
                    // handle error

                  })

                }
              }
            )
          }
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        console.log(err);
      })

  }, [shipmentData, riderModal, shipmentInfo, shipmentId, shipmentVehicle, shipmentRider, shipmentInfo, pending, verified, others, total]);



  const deleteShipmnt = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    SuperAdminApis.deleteShipment(shipmentId?.id)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            setDeleteModal(false)
            setShipmentRider('')
            UserApis.getAllShipmentsByUser('').then(
              (response: any) => {
                if (response?.data) {
                  setShipmentInfo(response?.data?.data);
                  SuperAdminApis.getAllShipments('', '').then(
                    (response: any) => {
                      if (response?.data) {
                        setShipmentInfo(response?.data);
                        setPending(response?.data?.out_bound?.data?.filter((e: any) => e?.Status == 'inReview')?.length)
                        setVerified(response?.data?.out_bound?.data?.filter((e: any) => e?.Status == 'Verified')?.length)
                        setOthers(response?.data?.out_bound?.data?.filter((e: any) => (e?.Status !== 'Verified' && e?.Status !== 'inReview'))?.length)
                        setTotal(response?.data?.out_bound?.data?.length)
                      }
                    }
                  ).catch(function (error) {
                    // handle error

                  })

                }
              }
            )
          }
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        console.log(err);
      })

  }, [deleteModal, shipmentId, pending, verified, others, total]);



  const handleShipmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShipmentData({ ...shipmentData, [e.target.name]: e.target.value });
  }

  React.useEffect(() => {
    // Define the action you want to execute
    const executeAction = () => {
      console.log('Action executed');
      SuperAdminApis.getAllShipments('', '').then(
        (response: any) => {
          if (response?.data) {
            setShipmentInfo(response?.data);
            setPending(response?.data?.out_bound?.data?.filter((e: any) => e?.Status == 'inReview')?.length)
            setVerified(response?.data?.out_bound?.data?.filter((e: any) => e?.Status == 'Verified')?.length)
            setOthers(response?.data?.out_bound?.data?.filter((e: any) => (e?.Status !== 'Verified' && e?.Status !== 'inReview'))?.length)
            setTotal(response?.data?.out_bound?.data?.length)
            setLoader(true)
          }
        }
      )
      // Add your code to execute here
    };

    // Set interval to execute the action every 5 minutes (300,000 milliseconds)
    const intervalId = setInterval(() => {
      executeAction();
    }, 300000); // 5 minutes in milliseconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);


  React.useEffect(() => {
    SuperAdminApis.getAllShipments('', '').then(
      (response: any) => {
        if (response?.data) {
          console?.log(response?.data)
          setShipmentInfo(response?.data);
          setPending(response?.data?.out_bound?.data?.filter((e: any) => e?.Status == 'inReview')?.length)
          setVerified(response?.data?.out_bound?.data?.filter((e: any) => e?.Status == 'Verified')?.length)
          setOthers(response?.data?.out_bound?.data?.filter((e: any) => (e?.Status !== 'Verified' && e?.Status !== 'inReview'))?.length)
          setTotal(response?.data?.out_bound?.data?.length)
          setLoader(true)
        }
      }
    ).catch(function (error) {
      // handle error

    })

    const query: any = {
      search: '',
    };
    SuperAdminApis.getAllRiders('', query).then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setRiders(response?.data?.data?.data)
        }
      }
    )
  }, []);


  const getAllVehicles = React.useCallback((id: any) => {
    const query: any = {
      search: '',
    };

    let rider_id = '';
    riders?.filter((e: any) => (e?.first_name == id))?.map((e: any) => { rider_id = e?.id; setFleetData(e) })
    SuperAdminApis.getAllVehicle('', query, rider_id).then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setVehicle(response?.data?.data?.data)
        }
      }
    ).catch(function (error) {
      // handle error

    })
  }, [vehicle, riders, fleetData]);

 
  const [searchText, setSearchText] = React.useState('');



  const paginator = React.useCallback(
    (value: any) => {
      //   setLoader(true);
      let value2 = '';
      if (value !== null) {
        value2 = value;
      } else {
        value2 = ''
      }
      setLoader(false)
      SuperAdminApis.getAllShipments(value2, searchText).then(
        (response: any) => {
          if (response?.data) {
            setShipmentInfo(response?.data);
            setLoader(true)
          }
        }
      ).catch(function (error) {
        console.log(error.response.data);
      })

    }, [shipmentInfo, searchText, loader]);



  React.useEffect(() => {
    SuperAdminApis.getAllCountryFrom('').then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setCountryFrom(response?.data?.data)
        }
      }
    )

    SuperAdminApis.getAllCountryTo('').then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setCountryTo(response?.data?.data)
          // console?.log(response?.data?.data)
        }
      }
    )
  }, []);




  const getStateFrom = React.useCallback(
    (id: any) => {
      let data = {
        "country_id": id
      }
      setCountryFromData(id)
      SuperAdminApis.getAllStateFrom('', data).then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            setStateFrom(response?.data?.data)

          }
        }
      )

    }, [stateFrom, countryFromData]
  );

  const getStateTo = React.useCallback(
    (id: any) => {
      let data = {
        "country_id": id
      }
      setCountryToData(id)
      SuperAdminApis.getAllStateTo('', data).then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            setStateTo(response?.data?.data)

          }
        }
      )

    }, [stateTo, countryToData]
  );

  const getLgaFrom = React.useCallback(
    (id: any) => {
      let data = {
        "state_id": id,
        "country_id": countryFromData
      }
      setStateFromData(id)
      SuperAdminApis.getAllLgaFrom('', data).then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            setLgaFrom(response?.data?.data)

          }
        }
      )

    }, [lgaFrom, countryFromData, stateFromData]
  );

  const getLgaTo = React.useCallback(
    (id: any) => {
      let data = {
        "state_id": id,
        "country_id": countryToData
      }
      setStateToData(id)
      SuperAdminApis.getAllLgaTo('', data).then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            setLgaTo(response?.data?.data)

          }
        }
      )

    }, [lgaTo, countryToData, stateToData]
  );



  // const paginator = React.useCallback(
  //   (value: any) => {
  //     //   setLoader(true);
  //     let value2 = '';
  //     if (value !== null) {
  //       value2 = value;
  //     } else {
  //       value2 = ''
  //     }

  //     UserApis.getAllShipmentsByUser(value2).then(
  //       (response: any) => {
  //         if (response?.data) {
  //           setShipmentInfos(response?.data?.data);
  //         }
  //       }
  //     ).catch(function (error) {
  //       console.log(error.response.data);
  //     })

  //   }, [shipmentInfos]);

  const [totalCost, setTotalCost] = React.useState<any>('');

  const calculateShipment = React.useCallback(() => {
    const formData = new FormData()
    formData.append('country_from', countryFromData)
    formData.append('country_to', countryToData)
    formData.append('state_from', stateFromData)
    formData.append('state_to', stateToData)
    formData.append('lga_from', lgaFromData)
    formData.append('lga_to', lgaToData)
    formData.append('CubicWeight', ((shipmentData?.CubicL * shipmentData?.CubicW * shipmentData?.CubicH) / 6000)?.toString())
    formData.append('Weight', shipmentData?.CubicWeight)

    UserApis.calculateShipment(formData)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.success) {
            // toast.success(response?.data?.message);
          }
          setTotalCost(response?.data?.data)
        }
      }).catch((err: any) => {
        console.log(err);
      })

  }, [shipmentData, totalCost, countryFromData, countryToData, stateFromData, stateToData, lgaFromData, lgaToData, countryFrom, countryTo, userLoginData]);


  React.useEffect(() => {
    calculateShipment()
  }, [shipmentData?.CubicWeight, shipmentData?.CubicL, shipmentData?.CubicW, , shipmentData?.CubicH]);


  const schedule = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    // setLoader(false)
    setLoader2(true);
    e.preventDefault();
    const formData = new FormData()
    formData.append('SenderName', shipmentData?.SenderName)
    formData.append('SenderEmail', shipmentData?.SenderEmail)
    countryFrom?.data?.filter((data: any) => data?.id == countryFromData)?.map((d: any) => { return formData.append('SenderCountryCode', d?.country_code) })
    formData.append('SenderAdd1', shipmentData?.SenderAdd1)
    formData.append('SenderAddCity', shipmentData?.SenderAddCity)
    stateFrom?.data?.filter((data: any) => data?.id == stateFromData)?.map((d: any) => { return formData.append('SenderAddState', d?.state_name) })
    // formData.append('SenderAddState', stateFrom?.data?.state_name)
    formData.append('SenderAddPostcode', shipmentData?.SenderAddPostcode)
    formData.append('SenderTaxID', shipmentData?.SenderTaxID)
    formData.append('SenderPhone', shipmentData?.SenderPhone)
    formData.append('SenderCompanyName', 'null')
    lgaFromData !== '' ? (lgaFrom?.data?.filter((data: any) => data?.id == lgaFromData)?.map((d: any) => { return formData.append('senderLga', d?.lga_name) })) : formData.append('senderLga', 'empty')
    lgaToData !== '' ? (lgaTo?.data?.filter((data: any) => data?.id == lgaToData)?.map((d: any) => { return formData.append('recieverLga', d?.lga_name) })) : formData.append('recieverLga', 'empty')
    formData.append('country_from', countryFromData)
    formData.append('country_to', countryToData)
    formData.append('state_from', stateFromData)
    formData.append('state_to', stateToData)
    formData.append('lga_from', lgaFromData ? lgaFromData : 'empty')
    formData.append('lga_to', lgaToData ? lgaToData : 'empty')
    //Reciever Info
    formData.append('ReceiverName', shipmentData?.ReceiverName)
    formData.append('ReceiverEmail', shipmentData?.ReceiverEmail)
    countryTo?.data?.filter((data: any) => data?.id == countryToData)?.map((d: any) => { return formData.append('ReceiverCountryCode', d?.country_code) })
    // formData.append('ReceiverCountryCode', countryTo?.country_code)
    formData.append('ReceiverAdd1', shipmentData?.ReceiverAdd1)
    formData.append('ReceiverAddCity', shipmentData?.ReceiverAddCity)
    stateTo?.data?.filter((data: any) => data?.id == stateToData)?.map((d: any) => { return formData.append('ReceiverAddState', d?.state_name) })
    // formData.append('ReceiverAddState', stateTo?.state_name)
    formData.append('ReceiverAddPostcode', shipmentData?.ReceiverAddPostcode)
    formData.append('ReceiverTaxID', shipmentData?.ReceiverTaxID)
    formData.append('ReceiverPhone', shipmentData?.ReceiverPhone)
    formData.append('ReceiverCompanyName', 'null')
    //Shipment Info
    formData.append('ServiceTypeName', shipmentData?.ServiceTypeName)
    formData.append('NoOfItems', shipmentData?.NoOfItems)
    formData.append('HarmonisedCode', shipmentData?.HarmonisedCode)
    formData.append('CubicL', shipmentData?.CubicL)
    formData.append('CubicW', shipmentData?.CubicW)
    formData.append('CubicH', shipmentData?.CubicH)
    formData.append('CubicWeight', (shipmentData?.CubicL * shipmentData?.CubicW * shipmentData?.CubicH)?.toString())
    formData.append('Weight', shipmentData?.CubicWeight)
    formData.append('WeightMeasurement', 'KG')
    formData.append('CubicWeight', shipmentData?.CubicWeight)
    formData.append('DeadWeight', ((shipmentData?.CubicL * shipmentData?.CubicW * shipmentData?.CubicH) / 6000)?.toString())
    formData.append('CustomValue', shipmentData?.CustomValue)
    formData.append('CustomCurrencyCode', shipmentData?.CustomCurrencyCode)
    formData.append('CODAmount', shipmentData?.CODAmount)
    formData.append('CODCurrencyCode', shipmentData?.CODCurrencyCode)
    formData.append('GoodsDescription', shipmentData?.GoodsDescription)
    formData.append('ShipmentTerm', shipmentData?.ShipmentTerm)
    formData.append('Incoterms', shipmentData?.Incoterms)
    formData.append('BusinessType', shipmentData?.BusinessType)
    formData.append('SenderRef1', shipmentData?.SenderRef1)
    formData.append('SenderRef2', shipmentData?.SenderRef2)
    formData.append('GoodsOriginCountryCode', shipmentData?.GoodsOriginCountryCode)
    formData.append('ReasonExport', shipmentData?.ReasonExport)
    formData.append('DeliveryInstructions', shipmentData?.DeliveryInstructions ? shipmentData?.DeliveryInstructions : 'empty')
    formData.append('Notes', shipmentData?.Notes)
    formData.append('CPCCode', shipmentData?.CPCCode)
    formData.append('SKUNumber', shipmentData?.SKUNumber)
    formData.append('ATENumber', shipmentData?.ATENumber)
    formData.append('ProductURL', shipmentData?.ProductURL)
    formData.append('GoodsValue', shipmentData?.GoodsValue)
    formData.append('GoodsCurrencyCode', shipmentData?.GoodsCurrencyCode)
    formData.append('DutyValue', shipmentData?.DutyValue)
    formData.append('DutyCurrencyCode', shipmentData?.DutyCurrencyCode)
    formData.append('EORINumber', shipmentData?.EORINumber)
    formData.append('FreightCost', totalCost)
    formData.append('FreightCostCurrencyCode', shipmentData?.FreightCostCurrencyCode)
    formData.append('TotalGSTAmount', shipmentData?.TotalGSTAmount)
    formData.append('TotalGSTCurrencyCode', shipmentData?.TotalGSTCurrencyCode)
    formData.append('CODAmount', shipmentData?.CODAmount)
    formData.append('origin_state', userLoginData?.data?.office)
    UserApis.createShipment(formData)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.success) {
            // setLoader(true)
            setLoader2(false);
            toast.success(response?.data?.message);
            window.location.reload()
          }
        }
      }).catch((err: any) => {
        setLoader2(false);
        console.log(err);
      }).finally(() => {
        setLoader2(false);
      })

  }, [shipmentData, loader2, totalCost, loader, countryFromData, countryToData, stateFromData, stateToData, lgaFromData, lgaToData, countryFrom, countryTo]);


  const [shipType, setShipType] = React.useState<any>('out_bound');


  type StateMapping = {
    [key: string]: string[];
  };

  const stateMappings: StateMapping = {
    "Ibadan": ["Kwara", "Ondo", "Ekiti", "Ogun", "Osun", "Oyo"],
    "Port Harcourt": ["Edo", "Cross River", "Akwa Ibom", "Delta", "Bayelsa"],
    "Enugu": ["Abia", "Anambra", "Imo", "Delta Asaba", "Ebonyi"],
    "Abuja": [
      "Kano", "Niger", "Kaduna", "Adamawa", "Kebbi", "Gombe", "Borno",
      "Bauchi", "Sokoto", "Zamfara", "Benue", "Jigawa", "Katsina",
      "Plateau", "Nassarawa", "Kogi", "Taraba", "Yobe"
    ]
  };


  const capitalize = (str: string): string =>
    str.replace(/\b\w/g, char => char.toUpperCase());

  const isSubStateUnderMainState = (mainState: string, subState: string): boolean => {
    if (mainState && subState) {
      const capitalizedMainState = capitalize(mainState?.trim());
      const capitalizedSubState = capitalize(subState?.trim());

      if (stateMappings[capitalizedMainState]) {
        return stateMappings[capitalizedMainState].includes(capitalizedSubState);
      }
    }


    return false;
  };



  return (
    <>
      <AdminSidebar title="Shipment" />
      <div className="relative md:ml-64 bg-[#FAFAFA] ">
        <AdminNavbar title="Shipments" />

        <div className={showScreen === 1 ? "block " : "hidden"}>
          <div className=' lg:px-10 pb-6 px-6 pt-5 bg-[#FAFAFA] min-h-screen'>

            {
              userLoginData?.data?.role == 'fleet' ?
                ''
                :
                <div className='grid lg:grid-cols-4 grid-cols-1 lg:divide-x-2 lg:divide-dotted lg:space-y-0 space-y-5'>
                  <div className='bg-white rounded-[8px] pl-[26px] pt-[17px] pb-[18px] '>
                    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="17.5" cy="17.5" r="17.25" fill="black" stroke="white" stroke-width="0.5" />
                      <path d="M26.88 11.3665L18.63 6.84966C18.4371 6.74313 18.2204 6.68726 18 6.68726C17.7796 6.68726 17.5629 6.74313 17.37 6.84966L9.12 11.3665C8.91357 11.4795 8.7413 11.6459 8.62123 11.8482C8.50115 12.0506 8.4377 12.2815 8.4375 12.5169V21.4831C8.4377 21.7184 8.50115 21.9493 8.62123 22.1517C8.7413 22.3541 8.91357 22.5205 9.12 22.6334L17.37 27.1503C17.5629 27.2566 17.7797 27.3124 18 27.3124C18.2203 27.3124 18.4371 27.2566 18.63 27.1503L26.88 22.6334C27.0864 22.5205 27.2587 22.3541 27.3788 22.1517C27.4988 21.9493 27.5623 21.7184 27.5625 21.4831V12.5169C27.5623 12.2815 27.4988 12.0506 27.3788 11.8482C27.2587 11.6459 27.0864 11.4795 26.88 11.3665ZM17.9062 7.83591C17.9338 7.82082 17.9648 7.8129 17.9963 7.8129C18.0277 7.8129 18.0587 7.82082 18.0863 7.83591L25.9228 12.125L22.7409 13.8659L14.8172 9.52904L17.9062 7.83591ZM17.4375 25.9062L9.65625 21.6472C9.6275 21.6306 9.60367 21.6066 9.5872 21.5778C9.57073 21.549 9.5622 21.5163 9.5625 21.4831V13.1244L17.4375 17.4369V25.9062ZM10.0772 12.125L13.6462 10.1703L21.5691 14.5072L18 16.46L10.0772 12.125ZM26.4375 21.4831C26.4378 21.5163 26.4293 21.549 26.4128 21.5778C26.3963 21.6066 26.3725 21.6306 26.3438 21.6472L18.5625 25.9062V17.435L21.9375 15.5872V19.25C21.9375 19.3992 21.9968 19.5422 22.1023 19.6477C22.2077 19.7532 22.3508 19.8125 22.5 19.8125C22.6492 19.8125 22.7923 19.7532 22.8977 19.6477C23.0032 19.5422 23.0625 19.3992 23.0625 19.25V14.9722L26.4375 13.1244V21.4831Z" fill="white" />
                    </svg>
                    <h1 className='mt-5 text-[20px] font-bold text-[#1C1C1C]'>{total}</h1>
                    <h3 className='mt-1 text-[12px] text-[#808080]'>Total</h3>
                  </div>
                  <div className='bg-white rounded-[8px] pl-[26px] pt-[17px] pb-[18px]'>
                    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="17.5" cy="17.5" r="17.25" fill="#DBFFE5" stroke="#149E49" stroke-width="0.5" />
                      <path d="M26.88 11.3665L18.63 6.84966C18.4371 6.74313 18.2204 6.68726 18 6.68726C17.7796 6.68726 17.5629 6.74313 17.37 6.84966L9.12 11.3665C8.91357 11.4795 8.7413 11.6459 8.62123 11.8482C8.50115 12.0506 8.4377 12.2815 8.4375 12.5169V21.4831C8.4377 21.7184 8.50115 21.9493 8.62123 22.1517C8.7413 22.3541 8.91357 22.5205 9.12 22.6334L17.37 27.1503C17.5629 27.2566 17.7797 27.3124 18 27.3124C18.2203 27.3124 18.4371 27.2566 18.63 27.1503L26.88 22.6334C27.0864 22.5205 27.2587 22.3541 27.3788 22.1517C27.4988 21.9493 27.5623 21.7184 27.5625 21.4831V12.5169C27.5623 12.2815 27.4988 12.0506 27.3788 11.8482C27.2587 11.6459 27.0864 11.4795 26.88 11.3665ZM17.9062 7.83591C17.9338 7.82082 17.9648 7.8129 17.9963 7.8129C18.0277 7.8129 18.0587 7.82082 18.0863 7.83591L25.9228 12.125L22.7409 13.8659L14.8172 9.52904L17.9062 7.83591ZM17.4375 25.9062L9.65625 21.6472C9.6275 21.6306 9.60367 21.6066 9.5872 21.5778C9.57073 21.549 9.5622 21.5163 9.5625 21.4831V13.1244L17.4375 17.4369V25.9062ZM10.0772 12.125L13.6462 10.1703L21.5691 14.5072L18 16.46L10.0772 12.125ZM26.4375 21.4831C26.4378 21.5163 26.4293 21.549 26.4128 21.5778C26.3963 21.6066 26.3725 21.6306 26.3438 21.6472L18.5625 25.9062V17.435L21.9375 15.5872V19.25C21.9375 19.3992 21.9968 19.5422 22.1023 19.6477C22.2077 19.7532 22.3508 19.8125 22.5 19.8125C22.6492 19.8125 22.7923 19.7532 22.8977 19.6477C23.0032 19.5422 23.0625 19.3992 23.0625 19.25V14.9722L26.4375 13.1244V21.4831Z" fill="#149E49" />
                    </svg>

                    <h1 className='mt-5 text-[20px] font-bold text-[#1C1C1C]'>{verified}</h1>
                    <h3 className='mt-1 text-[12px] text-[#808080]'>Verified</h3>
                  </div>

                  <div className='bg-white rounded-[8px] pl-[26px] pt-[17px] pb-[18px]'>
                    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="17.5" cy="17.5" r="17.25" fill="#FFF0D7" stroke="#F59E0B" stroke-width="0.5" />
                      <path d="M26.88 11.3665L18.63 6.84966C18.4371 6.74313 18.2204 6.68726 18 6.68726C17.7796 6.68726 17.5629 6.74313 17.37 6.84966L9.12 11.3665C8.91357 11.4795 8.7413 11.6459 8.62123 11.8482C8.50115 12.0506 8.4377 12.2815 8.4375 12.5169V21.4831C8.4377 21.7184 8.50115 21.9493 8.62123 22.1517C8.7413 22.3541 8.91357 22.5205 9.12 22.6334L17.37 27.1503C17.5629 27.2566 17.7797 27.3124 18 27.3124C18.2203 27.3124 18.4371 27.2566 18.63 27.1503L26.88 22.6334C27.0864 22.5205 27.2587 22.3541 27.3788 22.1517C27.4988 21.9493 27.5623 21.7184 27.5625 21.4831V12.5169C27.5623 12.2815 27.4988 12.0506 27.3788 11.8482C27.2587 11.6459 27.0864 11.4795 26.88 11.3665ZM17.9062 7.83591C17.9338 7.82082 17.9648 7.8129 17.9963 7.8129C18.0277 7.8129 18.0587 7.82082 18.0863 7.83591L25.9228 12.125L22.7409 13.8659L14.8172 9.52904L17.9062 7.83591ZM17.4375 25.9062L9.65625 21.6472C9.6275 21.6306 9.60367 21.6066 9.5872 21.5778C9.57073 21.549 9.5622 21.5163 9.5625 21.4831V13.1244L17.4375 17.4369V25.9062ZM10.0772 12.125L13.6462 10.1703L21.5691 14.5072L18 16.46L10.0772 12.125ZM26.4375 21.4831C26.4378 21.5163 26.4293 21.549 26.4128 21.5778C26.3963 21.6066 26.3725 21.6306 26.3438 21.6472L18.5625 25.9062V17.435L21.9375 15.5872V19.25C21.9375 19.3992 21.9968 19.5422 22.1023 19.6477C22.2077 19.7532 22.3508 19.8125 22.5 19.8125C22.6492 19.8125 22.7923 19.7532 22.8977 19.6477C23.0032 19.5422 23.0625 19.3992 23.0625 19.25V14.9722L26.4375 13.1244V21.4831Z" fill="#F59E0B" />
                    </svg>

                    <h1 className='mt-5 text-[20px] font-bold text-[#1C1C1C]'>{pending}</h1>
                    <h3 className='mt-1 text-[12px] text-[#808080]'>In Review</h3>
                  </div>

                  <div className='bg-white rounded-[8px] pl-[26px] pt-[17px] pb-[18px]'>
                    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="17.5" cy="17.5" r="17.25" fill="#FFEAEF" stroke="#EE1D52" stroke-width="0.5" />
                      <path d="M26.88 11.3665L18.63 6.84966C18.4371 6.74313 18.2204 6.68726 18 6.68726C17.7796 6.68726 17.5629 6.74313 17.37 6.84966L9.12 11.3665C8.91357 11.4795 8.7413 11.6459 8.62123 11.8482C8.50115 12.0506 8.4377 12.2815 8.4375 12.5169V21.4831C8.4377 21.7184 8.50115 21.9493 8.62123 22.1517C8.7413 22.3541 8.91357 22.5205 9.12 22.6334L17.37 27.1503C17.5629 27.2566 17.7797 27.3124 18 27.3124C18.2203 27.3124 18.4371 27.2566 18.63 27.1503L26.88 22.6334C27.0864 22.5205 27.2587 22.3541 27.3788 22.1517C27.4988 21.9493 27.5623 21.7184 27.5625 21.4831V12.5169C27.5623 12.2815 27.4988 12.0506 27.3788 11.8482C27.2587 11.6459 27.0864 11.4795 26.88 11.3665ZM17.9062 7.83591C17.9338 7.82082 17.9648 7.8129 17.9963 7.8129C18.0277 7.8129 18.0587 7.82082 18.0863 7.83591L25.9228 12.125L22.7409 13.8659L14.8172 9.52904L17.9062 7.83591ZM17.4375 25.9062L9.65625 21.6472C9.6275 21.6306 9.60367 21.6066 9.5872 21.5778C9.57073 21.549 9.5622 21.5163 9.5625 21.4831V13.1244L17.4375 17.4369V25.9062ZM10.0772 12.125L13.6462 10.1703L21.5691 14.5072L18 16.46L10.0772 12.125ZM26.4375 21.4831C26.4378 21.5163 26.4293 21.549 26.4128 21.5778C26.3963 21.6066 26.3725 21.6306 26.3438 21.6472L18.5625 25.9062V17.435L21.9375 15.5872V19.25C21.9375 19.3992 21.9968 19.5422 22.1023 19.6477C22.2077 19.7532 22.3508 19.8125 22.5 19.8125C22.6492 19.8125 22.7923 19.7532 22.8977 19.6477C23.0032 19.5422 23.0625 19.3992 23.0625 19.25V14.9722L26.4375 13.1244V21.4831Z" fill="#EE1D52" />
                    </svg>

                    <h1 className='mt-5 text-[20px] font-bold text-[#1C1C1C]'>{others}</h1>
                    <h3 className='mt-1 text-[12px] text-[#808080]'>Others</h3>
                  </div>

                </div>
            }



            {
              userLoginData?.data?.role == 'fleet' ?
                ''
                :
                <div className='flex justify-end mt-5'>
                  <button
                    onClick={() => setShowScreen(2)}
                    type='button'
                    className='bg-[#E04343] rounded-[12px]  p-[8px] text-white text-[12px] font-medium'
                  >
                    + Create Shipment
                  </button>
                </div>
            }


            <div className={'md:flex ' + ((userLoginData?.data?.role == 'fleet' || userLoginData?.data?.role == 'admin') ? ' justify-end' : ' justify-between') + ' mt-5'}>
              {(userLoginData?.data?.role == 'fleet' || userLoginData?.data?.role == 'admin') ?
                ''
                :
                <div className='flex md:justify-between justify-start gap-2'>
                  <h3 className='text-[15px] text-[#1C1C1C] font-semibold'>All Shipments </h3>

                  <button
                    onClick={() => setShipType('in_bound')}
                    type='button'
                    className={shipType == 'in_bound' ? 'bg-[#E04343] rounded-[12px] p-[4px] border-red-100 text-[12px] text-white' : 'bg-[#ffffff] border-red-500 border rounded-[12px] p-[4px] text-[12px] text-[#E04343]'}
                  >
                    In Bound
                  </button>


                  <button
                    onClick={() => setShipType('out_bound')}
                    type='button'
                    className={shipType == 'out_bound' ? 'bg-[#E04343] rounded-[12px] p-[4px] border-red-100 text-[12px] text-white' : 'bg-[#ffffff] border-red-500 border rounded-[12px] p-[4px] text-[12px] text-[#E04343]'}
                  >
                    Out Bound
                  </button>

                </div>
              }

              <div className='flex gap-3 md:pt-0 pt-4'>

                {
                  userLoginData?.data?.role == 'fleet' ?
                    <select id="countries" onChange={(e: any) => setSearchText(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full md:w-44 p-1.5">
                      <option selected>Choose a filter</option>
                      <option value="Proof of Delivery">Proof of Delivery</option>
                      <option value="In Transit to Destination">In Transit to Destination</option>
                      <option value="Out for Delivery">Out for Delivery</option>
                      <option value="Attempted Delivery - Inaccurate / Incomplete Address">Attempted Delivery - Inaccurate / Incomplete Address</option>

                    </select>
                    :
                    <select id="countries" onChange={(e: any) => setSearchText(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full md:w-44 p-1.5">
                      <option selected>Choose a filter</option>
                      <option value="">All</option>
                      <option value="InReview">InReview</option>
                      <option value="Verified">Verified</option>
                      <option value="Proof of Delivery">Proof of Delivery</option>
                      <option value="In Transit to Destination">In Transit to Destination</option>
                      <option value="Out for Delivery">Out for Delivery</option>
                      <option value="Attempted Delivery - Inaccurate / Incomplete Address">Attempted Delivery - Inaccurate / Incomplete Address</option>

                      {/* {
                        STATUSTYPES?.map((datas: any, index: any) => (
                          <option value={datas?.EventName}>{datas?.EventName}</option>
                        )
                        )} */}
                    </select>
                }


                <div className="relative w-full">
                  <div className="absolute inset-y-0 start-0 flex items-center pl-3 pointer-events-none">

                    <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                    </svg>
                  </div>
                  <input type="text" id="simple-search" onChange={(e: any) => setSearchText(e.target.value)} className="bg-white  border border-gray-300 text-gray-900 text-sm rounded-[10px] block md:w-44 w-full pl-10 p-1.5" placeholder="Search here..." required />
                </div>

                <button
                  onClick={paginator}
                  type='button'
                  className={'bg-[#E04343] rounded-[12px] px-[10px] border-red-100 text-[12px] text-white'}
                >
                  Search
                </button>
              </div>
            </div>
            <hr className='mt-3 bg-[#C4C4C44D]' />
            {loader ?
              <div className="relative overflow-x-auto">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 border-separate border-spacing-y-3">

                  {userLoginData?.data?.role == 'fleet' ?
                    <thead className="text-[12px] text-[#808080] bg-[#FAFAFA] ">
                      <tr>

                        <th scope="col" className="px-2 py-2">
                          S/N
                        </th>
                        <th scope="col" className="px-2 py-2">
                          Reciever&nbsp;Name
                        </th>
                        <th scope="col" className="px-2 py-2">
                          Reciever&nbsp;Phone&nbsp;Number
                        </th>
                        <th scope="col" className="px-2 py-2">
                          Reciever&nbsp;Address
                        </th>
                        <th scope="col" className="px-2 py-2">
                          Status
                        </th>

                        <th scope="col" className="px-2 py-2">
                          Shipment&nbsp;Number
                        </th>

                        <th scope="col" className="px-2 py-2">
                          Fleet
                        </th>

                        <th scope="col" className="px-2 py-2">
                          Vehicle
                        </th>

                      </tr>
                    </thead>
                    :
                    <thead className="text-[12px] text-[#808080] bg-[#FAFAFA] ">
                      <tr>

                        <th scope="col" className="px-2 py-2">
                          S/N
                        </th>
                        {/* <th scope="col" className="px-2 py-2">
                    Run&nbsp;ID
                  </th> */}
                        <th scope="col" className="px-2 py-2">
                          Created&nbsp;By
                        </th>
                        <th scope="col" className="px-2 py-2">
                          Created&nbsp;Date
                        </th>
                        <th scope="col" className="px-2 py-2">
                          Shipment&nbsp;ID
                        </th>
                        <th scope="col" className="px-2 py-2">
                          Sender&nbsp;Ref
                        </th>


                        {/* <th scope="col" className="px-2 py-2">
                    Sender&nbsp;Ref2
                  </th> */}
                        <th scope="col" className="px-2 py-2">
                          Serv.
                        </th>

                        <th scope="col" className="px-2 py-2">
                          Recipient
                        </th>
                        <th scope="col" className="px-2 py-2">
                          Sender State
                        </th>
                        <th scope="col" className="px-2 py-2">
                          Reciever State
                        </th>

                        <th scope="col" className="px-2 py-2">
                          Fleet
                        </th>

                        <th scope="col" className="px-2 py-2">
                          Vehicle
                        </th>

                        <th scope="col" className="px-2 py-2">
                          Department
                        </th>
                        <th scope="col" className="px-2 py-2">
                          Shipment Status
                        </th>
                        <th scope="col" className="px-2 py-2">
                          Payment Status
                        </th>
                        <th scope="col" className="px-2 py-2">
                          Action
                        </th>



                      </tr>
                    </thead>
                  }




                  <tbody className=''>

                    {
                      userLoginData?.data?.role == 'fleet' ?
                        shipmentInfo?.[shipType]?.data?.map(
                          (datas: any, index: any) => (
                            <tr className="bg-white  ">

                              <td className="px-2 py-2">
                                {index + 1}
                              </td>
                              <td className="px-6 py-4">
                                {datas?.ReceiverName}
                              </td>
                              <td className="px-6 py-4">
                                {datas?.ReceiverPhone}
                              </td>

                              <td className="px-6 py-4">
                                {datas?.ReceiverAdd1}
                              </td>

                              <td className="px-2 py-2 cursor-pointer" onClick={() => { toggleStatus(datas); setDelieveryInstruction(datas?.DeliveryInstructions); }}>
                                {(datas?.Status == 'inReview' || datas?.Status == 'InReview') ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#FFDDBD', color: '#F29339', borderRadius: '10px', padding: '2px 10px' }}>{datas?.Status}</b></p> : <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#C9F0D0', color: '#51CF66', borderRadius: '10px', padding: '2px 10px' }}>{datas?.Status}</b></p>}
                              </td>

                              <td className="px-6 py-4 ">
                                {datas?.ShipmentNumber ? datas?.ShipmentNumber : `Not Available`}
                              </td>

                              <td className="px-6 py-4 cursor-pointer">
                                {datas?.fleet_name ? datas?.fleet_name : "No Fleet"}
                              </td>

                              <td className="px-6 py-4" >
                                {datas?.vehicle_name ? datas?.vehicle_name : "No Vehicle"}
                              </td>

                            </tr>
                          )
                        )
                        :
                        (userLoginData?.data?.role == 'admin' ?
                          shipmentInfo?.[shipType]?.data?.map(
                            (datas: any, index: any) => (
                              <tr className="bg-white  ">

                                <td className="px-2 py-2">
                                  {index + 1}
                                </td>
                                <td className="px-6 py-4">
                                  {datas?.SenderName}
                                </td>
                                <td className="px-6 py-4">
                                  {convertUTCDateToLocalDate(datas?.created_at)}
                                </td>
                                <td className="px-6 py-4 ">
                                  {datas?.ShipmentNumber ? datas?.ShipmentNumber : `Not Available`}
                                </td>
                                <td className="px-6 py-4">
                                  {datas?.SenderRef1}
                                </td>

                                <td className="px-6 py-4">
                                  {datas?.ServiceTypeName}
                                </td>
                                <td className="px-6 py-4">
                                  {datas?.ReceiverName}
                                </td>
                                <td className="px-6 py-4">
                                  {datas?.SenderAddState}
                                </td>
                                <td className="px-6 py-4">
                                  {datas?.ReceiverAddState}
                                </td>

                                <td className="px-6 py-4 cursor-pointer" onClick={() => { setRiderModal(true); setShipmentId(datas); setDelieveryInstruction(datas?.DeliveryInstructions); }}>
                                  {datas?.fleet_name ? datas?.fleet_name : "No Fleet"}
                                </td>

                                <td className="px-6 py-4" >
                                  {datas?.vehicle_name ? datas?.vehicle_name : "No Vehicle"}
                                </td>

                                <td className="px-6 py-4 cursor-pointer" onClick={() => { setOfficeModal(true); setShipmentId(datas) }}>
                                  {datas?.office !== '' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#E6BDFF', color: '#B24BF1', borderRadius: '10px', padding: '2px 10px' }}>{(datas?.office)?.replace(/ /g, '_')}</b></p> : ''}

                                </td>
                                <td className="px-2 py-2 cursor-pointer" onClick={() => { toggleStatus(datas); }}>

                                  {(datas?.Status == 'inReview' || datas?.Status == 'InReview') ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#FFDDBD', color: '#F29339', borderRadius: '10px', padding: '2px 10px' }}>{datas?.Status}</b></p> : <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#C9F0D0', color: '#51CF66', borderRadius: '10px', padding: '2px 10px' }}>{datas?.Status}</b></p>}

                                </td>

                                <td className="px-2 py-2 " >
                                  {datas?.payment_status == 'successful' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#C9F0D0', color: '#51CF66', borderRadius: '10px', padding: '2px 10px' }}>{datas?.payment_status}</b></p> : ''}
                                  {datas?.payment_status == 'pending' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#FFDDBD', color: '#FF922B', borderRadius: '10px', padding: '2px 10px' }}>{datas?.payment_status}</b></p> : ''}
                                  {datas?.payment_status == 'failed' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#E6BDFF', color: '#B24BF1', borderRadius: '10px', padding: '2px 10px' }}>{datas?.payment_status}</b></p> : ''}
                                  {datas?.payment_status == 'Missed' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#CAD8FA', color: '#4979ED', borderRadius: '10px', padding: '2px 10px' }}>{datas?.payment_status}</b></p> : ''}
                                  {datas?.Spayment_statustatus == 'Closed' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#CAD8FA', color: '#4979ED', borderRadius: '10px', padding: '2px 10px' }}>{datas?.payment_status}</b></p> : ''}

                                </td>

                                {/* <td className="px-6 py-4 cursor-pointer"> */}
                                <td className="px-6 py-4 cursor-pointer flex justify-between gap-2 mt-4">
                                  <NavLink to={`/admin/edit-shipment/${datas?.id}`} className="cursor-pointer">
                                    <FaEye color='#3F83F8' />
                                  </NavLink>




                                  {datas?.ShipmentNumber ?
                                    <a href={datas?.LabelURL} target='_blank'>
                                      <MdDownload size={17} />
                                    </a>

                                    :
                                    <div className="cursor-pointer pl-1" onClick={() => { setDeleteModal(true); setShipmentId(datas) }}>
                                      <MdDelete color='red' />
                                    </div>
                                  }



                                </td>
                                {/* </td> */}
                              </tr>
                            )
                          )
                          :
                          shipmentInfo?.[shipType]?.data?.map(
                            (datas: any, index: any) => (
                              <tr className="bg-white  ">

                                <td className="px-2 py-2">
                                  {index + 1}
                                </td>
                                <td className="px-6 py-4">
                                  {datas?.SenderName}
                                </td>
                                <td className="px-6 py-4">
                                  {convertUTCDateToLocalDate(datas?.created_at)}
                                </td>
                                <td className="px-6 py-4 ">
                                  {datas?.ShipmentNumber ? datas?.ShipmentNumber : `Not Available`}
                                </td>
                                <td className="px-6 py-4">
                                  {datas?.SenderRef1}
                                </td>

                                <td className="px-6 py-4">
                                  {datas?.ServiceTypeName}
                                </td>
                                <td className="px-6 py-4">
                                  {datas?.ReceiverName}
                                </td>
                                <td className="px-6 py-4">
                                  {datas?.SenderAddState}
                                </td>
                                <td className="px-6 py-4">
                                  {datas?.ReceiverAddState}
                                </td>

                                <td className="px-6 py-4 cursor-pointer" onClick={() => { setRiderModal(true); setShipmentId(datas); setDelieveryInstruction(datas?.DeliveryInstructions); }}>
                                  {datas?.fleet_name ? datas?.fleet_name : "No Fleet"}
                                </td>

                                <td className="px-6 py-4" >
                                  {datas?.vehicle_name ? datas?.vehicle_name : "No Vehicle"}
                                </td>

                                <td className="px-6 py-4 cursor-pointer" onClick={() => { setOfficeModal(true); setShipmentId(datas) }}>
                                  {datas?.office !== '' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#E6BDFF', color: '#B24BF1', borderRadius: '10px', padding: '2px 10px' }}>{(datas?.office)?.replace(/ /g, '_')}</b></p> : ''}

                                </td>
                                <td className="px-2 py-2 cursor-pointer" onClick={() => { toggleStatus(datas) }}>
                                  {(datas?.Status == 'inReview' || datas?.Status == 'InReview') ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#FFDDBD', color: '#F29339', borderRadius: '10px', padding: '2px 10px' }}>{datas?.Status}</b></p> : <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#C9F0D0', color: '#51CF66', borderRadius: '10px', padding: '2px 10px' }}>{datas?.Status}</b></p>}

                                </td>

                                <td className="px-2 py-2 " >
                                  {datas?.payment_status == 'successful' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#C9F0D0', color: '#51CF66', borderRadius: '10px', padding: '2px 10px' }}>{datas?.payment_status}</b></p> : ''}
                                  {datas?.payment_status == 'pending' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#FFDDBD', color: '#FF922B', borderRadius: '10px', padding: '2px 10px' }}>{datas?.payment_status}</b></p> : ''}
                                  {datas?.payment_status == 'failed' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#E6BDFF', color: '#B24BF1', borderRadius: '10px', padding: '2px 10px' }}>{datas?.payment_status}</b></p> : ''}
                                  {datas?.payment_status == 'Missed' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#CAD8FA', color: '#4979ED', borderRadius: '10px', padding: '2px 10px' }}>{datas?.payment_status}</b></p> : ''}
                                  {datas?.Spayment_statustatus == 'Closed' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#CAD8FA', color: '#4979ED', borderRadius: '10px', padding: '2px 10px' }}>{datas?.payment_status}</b></p> : ''}
                                </td>

                                <td className="px-6 py-4 cursor-pointer flex justify-between gap-6 mt-4">
                                  <NavLink to={`/admin/edit-shipment/${datas?.id}`} className="cursor-pointer">
                                    <FaEye color='#3F83F8' />
                                  </NavLink>




                                  {datas?.ShipmentNumber ?
                                    <a href={datas?.LabelURL} target='_blank'>
                                      <MdDownload size={17} />
                                    </a>
                                    :
                                    <div className="cursor-pointer" onClick={() => { setDeleteModal(true); setShipmentId(datas) }}>
                                      <MdDelete color='red' />
                                    </div>
                                  }
                                </td>




                              </tr>
                            )
                          )
                        )

                    }

                  </tbody>

                </table>
              </div>
              :
              <div className='flex justify-center my-3 mt-20 pb-10'>
                <Oval
                  visible={true}
                  height="80"
                  width="80"
                  color="#E04343"
                  secondaryColor="#FF3B300D"
                  ariaLabel="oval-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            }

            <div className='m-4 flex justify-end mt-20'>
              {
                shipmentInfo?.[shipType]?.links?.filter(((item: any, idx: any) => idx < 1000)).map(
                  (datas: any, index: any) => (
                    <button onClick={() => paginator(datas?.label == 'Next &raquo;' ? datas?.url?.charAt(datas?.url?.length - 1) : (datas?.label === '&laquo; Previous') ? datas?.url?.charAt(datas?.url?.length - 1) : datas?.label)} disabled={datas?.active} className={'mx-1 py-1 px-2 ' + ((datas?.active == false || datas?.url == null) ? 'bg-gray-300 text-black ' : 'bg-[#E04343] text-white')}>
                      {datas?.label == '&laquo; Previous' ? '< Previous' : (datas?.label === 'Next &raquo;') ? 'Next  >' : datas?.label}
                    </button>
                  )
                )
              }

            </div>

          </div>
        </div>
        <div>
          <form onSubmit={schedule}>
            <div className={showScreen === 2 ? "block " : "hidden"}>
              <div className=' lg:px-10 px-6 bg-[#FAFAFA] min-h-screen'>
                {/* <!-- Sender Information --> */}
                <div className="px-6 lg:mt-5 bg-white pt-[24px] rounded-[10px]">
                  <div className="flex justify-between">
                    <span className="font-[700] text-[#19213D] lg:text-[14px] text-[12px] bg-[#EBEFF6] pl-[12px] py-[12px] pr-[40px] rounded-[13px]">Sender Information</span>
                    {/* <span className="bg-[#E2E8F0] font-[700] text-[#19213D] lg:text-[12px] text-[10px] rounded-[19px] px-[12px] py-[12px]">Use address book</span> */}
                  </div>
                  <div>

                    <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                      <div className="relative flex flex-col min-w-0 break-words w-full  ">
                        <div className="flex-auto   py-10 pt-0">
                          <div className="flex flex-wrap ">

                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Name  <span className=' text-red-600'>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Enter Name"
                                  name="SenderName"
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>
                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Email  <span className=' text-red-600'>*</span>
                                </label>
                                <input
                                  type="email"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Enter Email"
                                  name="SenderEmail"
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>
                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Country  <span className=' text-red-600'>*</span>
                                </label>
                                <select required id="countries" name='SenderCountryCode' onChange={(e: any) => { handleShipmentChange(e); getStateFrom(e.target.value) }} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                  <option disabled selected>--Select Country--</option>

                                  {(userLoginData?.data?.customer_type == 'credit' ?
                                    countryFrom?.data?.filter((data: any) => data?.client_id == userLoginData?.data?.email)?.map((datas: any, index: any) => (
                                      <option value={datas?.id}>{datas?.country_name}</option>

                                    )
                                    )
                                    :
                                    countryFrom?.data?.filter((data: any) => data?.client_id == null)?.map((datas: any, index: any) => (
                                      <option value={datas?.id}>{datas?.country_name}</option>

                                    )
                                    ))
                                  }
                                </select>
                              </div>
                            </div>
                            <div className="w-full lg:w-12/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Address  <span className=' text-red-600'>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Enter Name"
                                  name="SenderAdd1"
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>
                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  City <span className=' text-red-600'>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Enter City"
                                  name="SenderAddCity"
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>
                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  State  <span className=' text-red-600'>*</span>
                                </label>
                                <select required id="countries" name='SenderAddState' onChange={(e: any) => { handleShipmentChange(e); getLgaFrom(e.target.value) }} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                  <option disabled selected>--Select State--</option>
                                  {stateFrom?.data?.map((datas: any, index: any) => (
                                    <option value={datas?.id}>{datas?.state_name}</option>
                                  )
                                  )}
                                </select>

                              </div>
                            </div>

                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  LGA
                                </label>
                                <select id="countries" name='senderLga' onChange={(e: any) => { handleShipmentChange(e); setLgaFromData(e.target.value) }} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                  <option disabled selected>--Select LGA--</option>
                                  {lgaFrom?.data?.map((datas: any, index: any) => (
                                    <option value={datas?.id}>{datas?.lga_name}</option>
                                  )
                                  )}
                                </select>

                              </div>
                            </div>


                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Post Code
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Enter Post Code"
                                  name="SenderAddPostcode"
                                  onChange={handleShipmentChange}

                                />
                              </div>
                            </div>
                            {/* <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full ">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Receving Country
                                </label>
                                <select id="countries" name='ReceiverCountryCode' onChange={(e: any) => handleShipmentChange(e)} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                  <option selected>Select</option>
                                  <option value="US">United States</option>
                                  <option value="CA">Canada</option>
                                  <option value="FR">France</option>
                                  <option value="DE">Germany</option>
                                </select>
                              </div>
                            </div> */}
                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Tax ID
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Enter Tax ID"
                                  name="SenderTaxID"
                                  onChange={handleShipmentChange}

                                />
                              </div>
                            </div>
                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Phone Number
                                </label>
                                <input
                                  type="number"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Enter Phone Number"
                                  name="SenderPhone"
                                  onChange={handleShipmentChange}

                                />
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>

                {/* <!-- Receiver  Information --> */}
                <div className="px-6 lg:mt-5 bg-white pt-[24px] rounded-[10px]">
                  <div className="flex justify-between">
                    <span className="font-[700] text-[#19213D] lg:text-[14px] text-[12px] bg-[#EBEFF6] pl-[12px] py-[12px] pr-[40px] rounded-[13px]">Receiver  Information</span>
                    {/* <span className="bg-[#E2E8F0] font-[700] text-[#19213D] lg:text-[12px] text-[10px] rounded-[19px] px-[12px] py-[12px]">Use address book</span> */}
                  </div>
                  <div>

                    <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                      <div className="relative flex flex-col min-w-0 break-words w-full  ">
                        <div className="flex-auto   py-10 pt-0">
                          <div className="flex flex-wrap ">

                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Name  <span className=' text-red-600'>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Enter Name"
                                  name="ReceiverName"
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>
                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Email  <span className=' text-red-600'>*</span>
                                </label>
                                <input
                                  type="email"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Enter Email"
                                  name="ReceiverEmail"
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>
                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Country  <span className=' text-red-600'>*</span>
                                </label>
                                <select required id="countries" name='SenderCountryCode' onChange={(e: any) => { handleShipmentChange(e); getStateTo(e.target.value) }} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                  <option disabled selected>--Select Country--</option>

                                  {(userLoginData?.data?.customer_type == 'credit' ?
                                    countryTo?.data?.filter((data: any) => data?.client_id == userLoginData?.data?.email)?.map((datas: any, index: any) => (
                                      <option value={datas?.id}>{datas?.country_name}</option>

                                    )
                                    )
                                    :
                                    countryTo?.data?.filter((data: any) => data?.client_id == null)?.map((datas: any, index: any) => (
                                      <option value={datas?.id}>{datas?.country_name}</option>

                                    )
                                    ))
                                  }
                                </select>
                              </div>
                            </div>
                            <div className="w-full lg:w-12/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Address  <span className=' text-red-600'>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Enter Name"
                                  name="ReceiverAdd1"
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>
                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  City  <span className=' text-red-600'>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Enter City"
                                  name="ReceiverAddCity"
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>
                            {/* <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  State
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Enter State"
                                  name="ReceiverAddState"
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>   */}



                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  State  <span className=' text-red-600'>*</span>
                                </label>
                                <select required id="countries" name='ReceiverAddState' onChange={(e: any) => { handleShipmentChange(e); getLgaTo(e.target.value) }} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                  <option disabled selected>--Select State--</option>
                                  {stateTo?.data?.map((datas: any, index: any) => (
                                    <option value={datas?.id}>{datas?.state_name}</option>
                                  )
                                  )}
                                </select>
                              </div>
                            </div>

                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  LGA
                                </label>
                                <select id="countries" name='recieverLga' onChange={(e: any) => { handleShipmentChange(e); setLgaToData(e.target.value) }} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                  <option disabled selected>--Select LGA--</option>
                                  {lgaTo?.data?.map((datas: any, index: any) => (
                                    <option value={datas?.id}>{datas?.lga_name}</option>
                                  )
                                  )}
                                </select>
                              </div>
                            </div>




                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Post Code
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Enter Post Code"
                                  name="ReceiverAddPostcode"
                                  onChange={handleShipmentChange}

                                />
                              </div>
                            </div>
                            {/* <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full ">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Receving Country
                                </label>
                                <select id="countries" name='' className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                  <option selected>Select</option>
                                  <option value="US">United States</option>
                                  <option value="CA">Canada</option>
                                  <option value="FR">France</option>
                                  <option value="DE">Germany</option>
                                </select>
                              </div>
                            </div> */}
                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Tax ID
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Enter Post Code"
                                  name="ReceiverTaxID"
                                  onChange={handleShipmentChange}

                                />
                              </div>
                            </div>
                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Phone Number
                                </label>
                                <input
                                  type="number"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Enter Phone Number"
                                  name="ReceiverPhone"
                                  onChange={handleShipmentChange}

                                />
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div className='flex justify-end mt-6'>
                  <div className='flex space-x-10'>
                    {/* <div>
                      <button onClick={() => setShowScreen(2)} className='bg-[#B3B3BF] rounded-[40px] py-[5px] px-[20px] text-white'>Previous</button>
                    </div> */}
                    {/* <div>
                      <button
                        onClick={() => setShowScreen(3)}
                        type='button'
                        className='bg-[#E04343] rounded-[40px] transition ease-out delay-300 py-[5px] px-[20px] text-white inline-flex'>Shipment Information
                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd"><path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" /><path fill="white" d="M15.707 11.293a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 1 1-1.414-1.414l4.95-4.95l-4.95-4.95a1 1 0 0 1 1.414-1.414z" /></g></svg>
                      </button>
                    </div> */}
                  </div>
                </div>

              </div>
            </div>

            {/* <!-- Shipments  Information --> */}
            <div className={showScreen === 2 ? "block " : "hidden"}>
              <div className=' lg:px-10 pb-6 px-6 bg-[#FAFAFA] min-h-screen'>
                <div className="px-6  bg-white pt-[24px] rounded-[10px]">
                  <div className="flex justify-between">
                    <span className="font-[700] text-[#19213D] lg:text-[14px] text-[12px] bg-[#EBEFF6] pl-[12px] py-[12px] pr-[40px] rounded-[13px]">Shipments Information</span>
                  </div>
                  <div>

                    <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                      <div className="relative flex flex-col min-w-0 break-words w-full  ">
                        <div className="flex-auto   py-10 pt-0">
                          <div className="flex flex-wrap ">

                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Service <span className=' text-red-600'>*</span>
                                </label>
                                <select id="countries" required name='ServiceTypeName' onChange={(e: any) => handleShipmentChange(e)} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                  <option>Select One</option>
                                  <option value="DOME">Domestic Express</option>
                                  <option value="ED">Express Documents</option>
                                  <option value="EN">Express Non Documents</option>
                                  <option value="DOMS">Domestic Saver</option>
                                  <option value="SKYSAV">SkySaver</option>
                                  <option value="AF">Air Freight</option>
                                  <option value="Cargo">Cargo</option>
                                  <option value="DG">Dangerous Goods</option>
                                  <option value="HV">Heavy Shipment</option>
                                </select>
                                {/* <div className="flex items-center mb-4 mt-2">
                                  <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded" />
                                  <label htmlFor="default-checkbox" className="ml-2 text-[12px] font-medium text-gray-900">Extended Liability[?]</label>
                                </div> */}
                              </div>
                            </div>
                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  No of Items <span className=' text-red-600'>*</span>
                                </label>
                                <input
                                  type="number"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Item Number"
                                  name="NoOfItems"
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>
                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Harmonized Code
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder=""
                                  name="HarmonisedCode"
                                  onChange={handleShipmentChange}

                                />
                              </div>
                            </div>
                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Cubic Measurement (L x W x H)
                                </label>
                                <div className='flex space-x-3'>
                                  <input
                                    type="number"
                                    className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                    placeholder="L"
                                    name="CubicL"
                                    onChange={handleShipmentChange}

                                  />
                                  <span className='py-[3px]'>x</span>
                                  <input
                                    type="number"
                                    className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-1 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                    placeholder="W"
                                    name="CubicW"
                                    onChange={handleShipmentChange}

                                  />
                                  <span className='py-[3px]'>x</span>
                                  <input
                                    type="number"
                                    className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-1 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                    placeholder="H"
                                    name="CubicH"
                                    onChange={handleShipmentChange}

                                  />

                                </div>

                              </div>
                            </div>

                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Volume Weight (KG)
                                </label>
                                <input
                                  type="number"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder=""
                                  name="CubicWeight"
                                  disabled
                                  // defaultValue={shipmentData?.DeadWeight}
                                  value={((shipmentData?.CubicL * shipmentData?.CubicW * shipmentData?.CubicH) / 6000)}
                                  onChange={(e) => { handleShipmentChange(e); }}
                                />
                              </div>
                            </div>


                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Gross Weight (KG) <span className=' text-red-600'>*</span>
                                </label>
                                <input
                                  type="number"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Weight"
                                  name="CubicWeight"
                                  onChange={(e) => { handleShipmentChange(e); }}
                                  required
                                />
                              </div>
                            </div>





                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full ">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Customs Value
                                </label>
                                <div className='flex'>
                                  <div className=' basis-2/3'>
                                    <input
                                      type="number"
                                      className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-l-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                      placeholder=""
                                      name="CustomValue"
                                      onChange={(e) => { handleShipmentChange(e); }}

                                    />
                                  </div>
                                  <div className=' basis-1/3'>
                                    <select id="countries" onChange={(e: any) => handleShipmentChange(e)} name='CustomCurrencyCode' className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                      <option selected value={"NG"}>NG</option>
                                      {
                                        CURRENCYCODE?.map((datas: any, index: any) => (
                                          <option value={datas?.CurrencyCode}>{datas?.CurrencyCode}</option>
                                        )
                                        )}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  COD Amount
                                </label>
                                <div className='flex'>
                                  <div className=' basis-2/3'>
                                    <input
                                      type="number"
                                      className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-l-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                      placeholder=""
                                      name="CODAmount"
                                      onChange={handleShipmentChange}

                                    />
                                  </div>
                                  <div className=' basis-1/3'>
                                    <select id="countries" onChange={(e: any) => handleShipmentChange(e)} name='CODCurrencyCode' className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                      <option selected value={"NG"}>NG</option>
                                      {
                                        CURRENCYCODE?.map((datas: any, index: any) => (
                                          <option value={datas?.CurrencyCode}>{datas?.CurrencyCode}</option>
                                        )
                                        )}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>


                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>

                {/* <!-- Shipment Information --> */}
                <div className="px-6 lg:mt-5 bg-white pt-[24px] rounded-[10px]">
                  <div className="flex justify-between">
                    <span className="font-[700] text-[#19213D] lg:text-[14px] text-[12px] bg-[#EBEFF6] pl-[12px] py-[12px] pr-[40px] rounded-[13px]">Shipment  Information</span>
                  </div>
                  <div>

                    <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                      <div className="relative flex flex-col min-w-0 break-words w-full  ">
                        <div className="flex-auto   py-10 pt-0">
                          <div className="flex flex-wrap ">

                            <div className="w-full lg:w-12/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label htmlFor="message" className="block mb-2 text-sm font-semibold text-[#19213D]">Description <span className=' text-red-600'>*</span></label>
                                <textarea id="message" required onChange={() => handleShipmentChange} name='GoodsDescription' rows={4} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150" placeholder="Write your thoughts here..."></textarea>
                              </div>
                            </div>
                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label htmlFor="countries" className="block mb-2 text-sm font-semibold text-[#19213D]">Shipment Terms</label>
                                <select id="countries" onChange={(e: any) => handleShipmentChange(e)} name='ShipmentTerm' className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                  <option selected>Select One</option>
                                  <option value="DDU">DDU</option>
                                  <option value="DDP">DDP</option>
                                </select>
                              </div>
                            </div>
                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label htmlFor="countries" className="block mb-2 text-sm font-semibold text-[#19213D]">Incoterms</label>
                                <select id="countries" onChange={(e: any) => handleShipmentChange(e)} name='Incoterms' className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                  <option selected>Select One</option>
                                  <option value="CIF">CIF</option>
                                  <option value="CFR">CFR</option>
                                  <option value="FOB">FOB</option>
                                  <option value="DE">DE</option>
                                </select>
                              </div>
                            </div>
                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label htmlFor="countries" className="block mb-2 text-sm font-semibold text-[#19213D]">Type of Business</label>
                                <select id="countries" onChange={(e: any) => handleShipmentChange(e)} name='BusinessType' className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                  <option selected>Select One</option>
                                  <option value="B2C">B2C</option>
                                  <option value="B2B">B2B</option>
                                  <option value="C2C">C2C</option>
                                </select>
                              </div>
                            </div>

                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Customer Ref #1
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"

                                  name="SenderRef1"
                                  onChange={handleShipmentChange}

                                />
                                <div className="flex items-center mb-4 mt-2">
                                  <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded" />
                                  <label htmlFor="default-checkbox" className="ml-2 text-[12px] font-medium text-gray-900">Use receiver's customer ref.</label>
                                </div>
                              </div>
                            </div>
                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Customer Ref #2
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  name="SenderRef2"
                                  onChange={handleShipmentChange}
                                />
                              </div>
                            </div>
                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label htmlFor="countries" className="block mb-2 text-sm font-semibold text-[#19213D]">Origin of goods</label>
                                <select id="countries" onChange={(e: any) => handleShipmentChange(e)} name='GoodsOriginCountryCode' className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                  <option selected value={"NG"}>NIGERIA</option>
                                  {
                                    COUNTRYCODE?.map((datas: any, index: any) => (
                                      <option value={datas?.CountryCode}>{datas?.CountrynName}</option>
                                    )
                                    )}
                                </select>
                              </div>
                            </div>
                            <div className="w-full lg:w-12/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label htmlFor="message" className="block mb-2 text-sm font-semibold text-[#19213D]">Reason for Export</label>
                                <textarea id="message" onChange={(e: any) => handleShipmentChange(e)} name='ReasonExport' rows={4} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150" placeholder="Write here..."></textarea>
                              </div>
                            </div>
                            {/* <div className="w-full lg:w-12/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label htmlFor="message" className="block mb-2 text-sm font-semibold text-[#19213D]">Delivery Notes</label>
                                <textarea id="message" onChange={(e: any) => handleShipmentChange(e)} name='DeliveryInstructions' rows={4} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150" placeholder="Write here..."></textarea>
                              </div>
                            </div> */}

                            <div className="w-full lg:w-12/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label htmlFor="message" className="block mb-2 text-sm font-semibold text-[#19213D]">Notes</label>
                                <textarea id="message" onChange={(e: any) => handleShipmentChange(e)} name='Notes' rows={4} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150" placeholder="Write here..."></textarea>
                              </div>
                            </div>


                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="px-6 lg:mt-5 bg-white pt-[24px] rounded-[10px]">
                  <div className="flex justify-between">
                    <span className="font-[700] text-[#19213D] lg:text-[14px] text-[12px] bg-[#EBEFF6] pl-[12px] py-[12px] pr-[40px] rounded-[13px]">Shipments Information</span>
                  </div>
                  <div>

                    <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                      <div className="relative flex flex-col min-w-0 break-words w-full  ">
                        <div className="flex-auto   py-10 pt-0">
                          <div className="flex flex-wrap ">

                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  CPC Code
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  name="CPCCode"
                                  onChange={handleShipmentChange}
                                />
                              </div>
                            </div>
                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  SKU Number
                                </label>
                                <input
                                  type="number"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  name="SKUNumber"
                                  onChange={handleShipmentChange}
                                />
                              </div>
                            </div>
                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  ATENumber
                                </label>
                                <input
                                  type="number"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  name="ATENumber"
                                  onChange={handleShipmentChange}
                                />
                              </div>
                            </div>
                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Product URL
                                </label>

                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder=""
                                  name="ProductURL"
                                  onChange={handleShipmentChange}
                                />

                              </div>
                            </div>
                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Total Cost of Goods
                                </label>
                                <div className='flex'>
                                  <div className=' basis-2/3'>
                                    <input
                                      type="number"
                                      className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-l-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                      placeholder=""
                                      name="GoodsValue"
                                      onChange={handleShipmentChange}
                                    />
                                  </div>
                                  <div className=' basis-1/3'>
                                    <select id="countries" onChange={(e: any) => handleShipmentChange(e)} name='GoodsCurrencyCode' className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                      <option selected value={"NG"}>NG</option>
                                      {
                                        CURRENCYCODE?.map((datas: any, index: any) => (
                                          <option value={datas?.CurrencyCode}>{datas?.CurrencyCode}</option>
                                        )
                                        )}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Duties
                                </label>
                                <div className='flex'>
                                  <div className=' basis-2/3'>
                                    <input
                                      type="number"
                                      className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-l-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                      placeholder=""
                                      name="DutyValue"
                                      onChange={handleShipmentChange}
                                    />
                                  </div>
                                  <div className=' basis-1/3'>
                                    <select id="countries" onChange={(e: any) => handleShipmentChange(e)} name='DutyCurrencyCode' className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                      <option selected value={"NG"}>NG</option>
                                      {
                                        CURRENCYCODE?.map((datas: any, index: any) => (
                                          <option value={datas?.CurrencyCode}>{datas?.CurrencyCode}</option>
                                        )
                                        )}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full ">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  EQRI
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder=""
                                  name="EORINumber"
                                  onChange={handleShipmentChange}
                                />
                              </div>
                            </div>
                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Freight Cost
                                </label>
                                <div className='flex'>
                                  <div className=' basis-2/3'>
                                    <input
                                      type="number"
                                      className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-l-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                      placeholder=""
                                      disabled
                                      name="FreightCost"
                                      value={totalCost}
                                      onChange={handleShipmentChange}
                                    />
                                  </div>
                                  <div className=' basis-1/3'>
                                    <select disabled id="countries" onChange={(e: any) => handleShipmentChange(e)} name='FreightCostCurrencyCode' className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                      <option selected value={"NG"}>NG</option>
                                      {
                                        CURRENCYCODE?.map((datas: any, index: any) => (
                                          <option value={datas?.CurrencyCode}>{datas?.CurrencyCode}</option>
                                        )
                                        )}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Total GST paid (if any)
                                </label>
                                <div className='flex'>
                                  <div className=' basis-2/3'>
                                    <input
                                      type="number"
                                      className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-l-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                      placeholder=""
                                      name="TotalGSTAmount"
                                      onChange={handleShipmentChange}
                                    />
                                  </div>
                                  <div className=' basis-1/3'>
                                    <select id="countries" onChange={(e: any) => handleShipmentChange(e)} name='TotalGSTCurrencyCode' className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                      <option selected value={"NG"}>NG</option>
                                      {
                                        CURRENCYCODE?.map((datas: any, index: any) => (
                                          <option value={datas?.CurrencyCode}>{datas?.CurrencyCode}</option>
                                        )
                                        )}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>


                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>

                {/* <div className="px-6 lg:mt-5 bg-white pt-[24px] rounded-[10px]">
                  <div className="flex justify-between">
                    <span className="font-[700] text-[#19213D] lg:text-[14px] text-[12px] bg-[#EBEFF6] pl-[12px] py-[12px] pr-[40px] rounded-[13px]">Shipment Options</span>
                  </div>
                  <div>

                    <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                      <div className="relative flex flex-col min-w-0 break-words w-full  ">
                        <div className="flex-auto   py-10 pt-0">
                          <div className="flex flex-wrap ">

                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Run ID
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder=""
                                  name="f_name"

                                />

                                <div className="flex items-center mb-4 mt-2">
                                  <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded" />
                                  <label htmlFor="default-checkbox" className="ml-2 text-[12px] font-medium text-gray-900">Assign to Run ID</label>
                                </div>

                              </div>
                            </div>
                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Despatch Date
                                </label>
                                <input
                                  type="date"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder=""
                                  name="f_name"

                                />
                                <div className='flex justify-between'>
                                  <div className="flex items-center mb-4 mt-2">
                                    <input type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded" />
                                    <label className="ml-2 text-[12px] font-medium text-gray-900">Ship After Saving  Record</label>
                                  </div>
                                  <div className="flex items-center mb-4 mt-2">
                                    <input type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded" />
                                    <label className="ml-2 text-[12px] font-medium text-gray-900">Book a Pick Up </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                </div> */}

                <div className='flex justify-end mt-6'>
                  <div className='flex space-x-10'>

                    <div>
                      <button
                        // onClick={() => setShowScreen(2)}
                        type='submit'
                        disabled={loader2}
                        className='bg-[#E04343] rounded-[10px] flex justify-center gap-2 py-[5px] px-[20px] text-white'>
                        <span> Create Shipment</span>

                        <span>
                          {loader2 ?
                            <Oval
                              visible={true}
                              height="20"
                              width="20"
                              color="#f9adad"
                              secondaryColor="#FF3B300D"
                              ariaLabel="oval-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                            />
                            :
                            ''
                          }
                        </span>


                      </button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </form>
        </div>

      </div>

      <section>
        <Modal
          visible={statusModal}
          width="400"
          height="300"
          effect="fadeInUp"
          onClickAway={() => setStatusModal(false)}
        >
          <div className=" " style={{ overflow: 'auto' }}>

            <div className="container  bg-[#fff] rounded-lg p-2">

              <div className="px-3">

                {/* <span className="flex justify-around">
                    <h1 className=" text-xs text-red-600" style={{ fontSize: '10px' }}>Link can’t be edited in free plan. <span style={{ color: '#61A24F' }} className="font-bold text-xs">Upgrade to Pro</span></h1>
                  </span> */}
                <span className="flex justify-between px-2 pt-3">
                  <p className='mt-2'>Change Status</p>
                  <p className="cursor-pointer font-bold" onClick={(e) => setStatusModal(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>
                </span>
                <form className="pb-4 rounded-lg" onSubmit={updateShipment}>

                  <div className="relative w-full mt-10 mb-4">
                    <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                      Status {shipmentId?.Status}
                    </label>



                    {
                      userLoginData?.data?.role == 'fleet' ?
                        <div>
                          <select id="countries" name='SenderCountryCode' defaultValue={shipmentId?.Status} onChange={(e: any) => setShipmentStatus(e?.target?.value)} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                            <option selected>{shipmentId?.Status}</option>
                            <option value="Proof of Delivery">Proof of Delivery</option>
                            <option value="In Transit to Destination">In Transit to Destination</option>
                            <option value="Out for Delivery">Out for Delivery</option>
                            <option value="Attempted Delivery - Inaccurate / Incomplete Address">Attempted Delivery - Inaccurate / Incomplete Address</option>

                          </select>

                          {(shipmentStatus == 'Proof of Delivery' || shipmentId?.Status == 'Proof of Delivery') ?
                            <div className="w-full lg:w-12/12 ">
                              <div className="relative w-full mb-5">
                                <label htmlFor="message" className="block mb-2 text-sm font-semibold text-[#19213D]">Delivery Notes</label>
                                <textarea onChange={(e: any) => setDelieveryInstruction(e.target.value)} name='DeliveryInstructions' rows={3} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150" placeholder="Write here..." defaultValue={shipmentId?.DeliveryInstructions}></textarea>
                              </div>
                            </div>
                            :
                            ''
                          }


                        </div>
                        :
                        <select id="countries" name='SenderCountryCode' onChange={(e: any) => setShipmentStatus(e?.target?.value)} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                          <option selected>{shipmentId?.Status}</option>
                          <option value="InReview">InReview</option>
                          <option value="Verified">Verified</option>
                          {
                            STATUSTYPES?.map((datas: any, index: any) => (
                              <option value={datas?.EventName}>{datas?.EventName}</option>
                            )
                            )}
                        </select>
                    }



                  </div>


                  <span className="flex justify-center pt-4">
                    <button
                      type="submit"
                      style={{ borderRadius: '50px', color: '#F52424' }}
                      className=" text-red-700 bg-red-200 focus:ring-4 focus:outline-none focus:ring-grredeen-300 font-medium rounded-lg text-sm w-full px-2 py-2.5 text-center "
                    >
                      <div className="flex justify-center gap-3 ">
                        <span>Update</span>
                        <Oval
                          visible={loading}
                          height="20"
                          width="20"
                          color="#E04343"
                          secondaryColor="#FCBF94"
                          ariaLabel="oval-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </div>
                    </button>
                  </span>

                  <span className="flex justify-center pt-4">
                    <button
                      type="button"
                      onClick={(e) => setStatusModal(false)}
                      style={{ borderRadius: '50px' }}
                      className=" text-black   focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm w-full px-2 py-1.5 text-center "
                    >
                      Cancel
                    </button>
                  </span>

                </form>
              </div>

            </div>

          </div>
        </Modal>
      </section>


      <section>
        <Modal
          visible={officeModal}
          width="350"
          height="300"
          effect="fadeInUp"
          onClickAway={() => setOfficeModal(false)}
        >
          <div className=" " style={{ height: '100%', overflow: 'auto' }}>

            <div className="container  bg-[#fff] rounded-lg p-2">

              <div className="px-3">

                {/* <span className="flex justify-around">
                    <h1 className=" text-xs text-red-600" style={{ fontSize: '10px' }}>Link can’t be edited in free plan. <span style={{ color: '#61A24F' }} className="font-bold text-xs">Upgrade to Pro</span></h1>
                  </span> */}
                <span className="flex justify-between px-2 pt-3">
                  <p className='mt-2'>Change Department</p>
                  <p className="cursor-pointer font-bold" onClick={(e) => setOfficeModal(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>
                </span>





                <form className="pb-4 rounded-lg" onSubmit={updateShipmentOffice}>

                  <div className="relative w-full mt-10 mb-4">
                    <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                      Department {shipmentId?.office}
                    </label>
                    <select id="countries" defaultValue={shipmentId?.office} onChange={(e: any) => setShipmentOffice(e?.target?.value)} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                      <option selected>{shipmentId?.office}</option>
                      <option value="customer service">customer service</option>
                      <option value="operations">operations</option>
                      <option value="financial">financial</option>
                      <option value="rider">rider</option>
                      <option value="admin">admin</option>
                      {/* <option value="In Transit">In-Transit</option>
                      <option value="Out for Delievery">Out for Delievery</option> */}
                    </select>
                  </div>


                  <span className="flex justify-center pt-4">
                    <button
                      type="submit"
                      style={{ borderRadius: '50px', color: '#F52424' }}
                      className=" text-red-700 bg-red-200 focus:ring-4 focus:outline-none focus:ring-grredeen-300 font-medium rounded-lg text-sm w-full px-2 py-2.5 text-center "
                    >
                      Update
                    </button>
                  </span>

                  <span className="flex justify-center pt-4">
                    <button
                      type="button"
                      onClick={(e) => setStatusModal(false)}
                      style={{ borderRadius: '50px' }}
                      className=" text-black   focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm w-full px-2 py-1.5 text-center "
                    >
                      Cancel
                    </button>
                  </span>

                </form>
              </div>

            </div>

          </div>
        </Modal>
      </section>

      <section>
        <Modal
          visible={riderModal}
          width="350"
          height="400"
          effect="fadeInUp"
          onClickAway={() => setRiderModal(false)}
        >
          <div className=" " style={{ height: '100%', overflow: 'auto' }}>

            <div className="container  bg-[#fff] rounded-lg p-2">

              <div className="px-3">

                <span className="flex justify-between px-2 pt-3">
                  <p className='mt-2 font-bold'>Attach Fleet</p>
                  <p className="cursor-pointer font-bold" onClick={(e) => setRiderModal(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>
                </span>


                <form className="pb-4 rounded-lg" onSubmit={updateShipmentRider}>

                  <div className="relative w-full mt-10 mb-4">
                    <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                      Select Fleet
                    </label>
                    <select id="countries" onChange={(e: any) => { setShipmentRider(e?.target?.value); getAllVehicles(e?.target?.value) }} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                      <option selected>{shipmentId?.rider ? shipmentId?.rider : 'Select Fleet'}</option>

                      {riders?.map((datas: any, index: any) => (
                        <option value={datas?.first_name}>{datas?.first_name}</option>
                      )
                      )}

                      {/* <option value="In Transit">In-Transit</option>
                      <option value="Out for Delievery">Out for Delievery</option> */}
                    </select>
                  </div>


                  <div className="relative w-full mt-4 mb-4">
                    <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                      Select Vehicle
                    </label>
                    <select id="countries" onChange={(e: any) => setShipmentvehicle(e?.target?.value)} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                      <option selected>{shipmentId?.vehicle_name ? shipmentId?.vehicle_name : 'Select Vehicle'}</option>

                      {vehicle?.filter((data: any) => data?.status == 'Available')?.map((datas: any, index: any) => (
                        <option value={datas?.vehicle_name}>{datas?.vehicle_name}</option>
                      )
                      )}

                      {/* <option value="In Transit">In-Transit</option>
                      <option value="Out for Delievery">Out for Delievery</option> */}
                    </select>
                  </div>


                  <span className="flex justify-center pt-4">
                    <button
                      type="submit"
                      style={{ borderRadius: '50px', color: '#F52424' }}
                      className=" text-red-700 bg-red-200 focus:ring-4 focus:outline-none focus:ring-grredeen-300 font-medium rounded-lg text-sm w-full px-2 py-2.5 text-center "
                    >
                      Update
                    </button>
                  </span>

                  <span className="flex justify-center pt-4">
                    <button
                      type="button"
                      onClick={(e) => setRiderModal(false)}
                      style={{ borderRadius: '50px' }}
                      className=" text-black   focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm w-full px-2 py-1.5 text-center "
                    >
                      Cancel
                    </button>
                  </span>

                </form>
              </div>

            </div>

          </div>
        </Modal>
      </section>

      <section>
        <Modal
          visible={deleteModal}
          width="400"
          height="200"
          effect="fadeInUp"
          onClickAway={() => setDeleteModal(false)}
        >
          <div className="p-2" style={{ overflow: 'auto' }}>
            <span className="flex justify-between px-1 mb-3">
              <p className='font-bold mt-1'>Delete Shipment</p>
              <p className="cursor-pointer font-bold" onClick={() => setDeleteModal(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>

            </span>

            <form onSubmit={deleteShipmnt}>
              <div className='my-10 flex justify-center'>
                <span>Are you sure you want to delete this shipment ?</span>
              </div>
              <div className="w-full flex justify-end">
                <button type='submit' className='bg-[#E04343] text-white py-[6px] rounded w-full'>Delete Shipment</button>
              </div>
            </form>
          </div>
        </Modal>
      </section>



      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  )
}

export default Shipments
import React from 'react'
import AdminNavbar from "../Navbars/AdminNavbar";
import Sidebar from "../Sidebar/Sidebar";
import { SuperAdminApis } from '../../apis/superAdminApi';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { convertUTCDateToLocalDate } from '../Reusable/functions';
import AddCountryFromModal from './RateModal/AddCountryFromModal';
import EditCountryFromModal from './RateModal/EditCountryFromModal';
import DeleteCountryFromModal from './RateModal/DeleteCountryFromModal';
import AddCountryToModal from './RateModal/AddCountryToModal';
import EditCountryToModal from './RateModal/EditCountryToModal';
import DeleteCountryToModal from './RateModal/DeleteCountryToModal';
import AddStateFromModal from './RateModal/AddStateFromModal';
import EditstateFromModal from './RateModal/EditStateFromModal';
import DeleteStateFromModal from './RateModal/DeleteStateFromModal';
import AddStateToModal from './RateModal/AddStateToModal';
import EditstateToModal from './RateModal/EditStateToModal';
import DeleteStateToModal from './RateModal/DeleteStateToModal';
import AddLgaFromModal from './RateModal/AddLgaFromModal';
import EditLgaFromModal from './RateModal/EditLgaFromModal';
import DeleteLgaFromModal from './RateModal/DeleteLgaFromModal';
import AddLgaToModal from './RateModal/AddLgaToModal';
import EditLgaToModal from './RateModal/EditLgaToModal';
import DeleteLgaToModal from './RateModal/DeleteLgaToModal';
import AdminSidebar from '../Sidebar/AdminSidebar';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
function ManageRates() {

  const navigate = useNavigate();
  const [userLists, setUserList] = React.useState<any>([]);

  const [countryTo, setCountryTo] = React.useState<any>([]);
  const [stateFrom, setStateFrom] = React.useState<any>([]);
  const [stateTo, setStateTo] = React.useState<any>([]);

  const [lgaFrom, setLgaFrom] = React.useState<any>([]);
  const [lgaTo, setLgaTo] = React.useState<any>([]);

  function refreshPage() {
    window.location.reload();
  }
  const [tabNo, setTabNo] = React.useState<any>('1');
  const [countryFromData, setCountryFromData] = React.useState<any>('');
  const [countryToData, setCountryToData] = React.useState<any>('');
  const [stateFromData, setStateFromData] = React.useState<any>('');
  const [stateToData, setStateToData] = React.useState<any>('');
  const [lgaFromData, setLgaFromData] = React.useState<any>('');
  const [lgaToData, setLgaToData] = React.useState<any>('');


  const [showCreateCountryFromModal, setShowCreateCountryFromModal] = React.useState<any>(false);
  const closeCreateCountryFromModal = () => { setShowCreateCountryFromModal(false); refreshPage() };

  const [showCreateCountryToModal, setShowCreateCountryToModal] = React.useState<any>(false);
  const closeCreateCountryToModal = () => { setShowCreateCountryToModal(false); refreshPage() };

  const [showCreateStateFromModal, setShowCreateStateFromModal] = React.useState<any>(false);
  const closeCreateStateFromModal = () => { setShowCreateStateFromModal(false); };

  const [showCreateStateToModal, setShowCreateStateToModal] = React.useState<any>(false);
  const closeCreateStateToModal = () => { setShowCreateStateToModal(false); };

  const [showCreateLgaFromModal, setShowCreateLgaFromModal] = React.useState<any>(false);
  const closeCreateLgaFromModal = () => { setShowCreateLgaFromModal(false); };

  const [showCreateLgaToModal, setShowCreateLgaToModal] = React.useState<any>(false);
  const closeCreateLgaToModal = () => { setShowCreateLgaToModal(false); };



  const [showEditCountryFromModal, setShowEditCountryFromModal] = React.useState<any>(false);
  const closeEditCountryFromModal = () => { setShowEditCountryFromModal(false); refreshPage() };

  const [showEditCountryToModal, setShowEditCountryToModal] = React.useState<any>(false);
  const closeEditCountryToModal = () => { setShowEditCountryToModal(false); refreshPage() };

  const [showEditStateFromModal, setShowEditStateFromModal] = React.useState<any>(false);
  const closeEditStateFromModal = () => { setShowEditStateFromModal(false); };

  const [showEditStateToModal, setShowEditStateToModal] = React.useState<any>(false);
  const closeEditStateToModal = () => { setShowEditStateToModal(false); };

  const [showEditLgaFromModal, setShowEditLgaFromModal] = React.useState<any>(false);
  const closeEditLgaFromModal = () => { setShowEditLgaFromModal(false); };

  const [showEditLgaToModal, setShowEditLgaToModal] = React.useState<any>(false);
  const closeEditLgaToModal = () => { setShowEditLgaToModal(false); };



  const [showDeleteCountryFromModal, setShowDeleteCountryFromModal] = React.useState<any>(false);
  const closeDeletetCountryFromModal = () => { setShowDeleteCountryFromModal(false); refreshPage() };

  const [showDeleteCountryToModal, setShowDeleteCountryToModal] = React.useState<any>(false);
  const closeDeletetCountryToModal = () => { setShowDeleteCountryToModal(false); refreshPage() };

  const [showDeleteStateFromModal, setShowDeleteStateFromModal] = React.useState<any>(false);
  const closeDeletetStateFromModal = () => { setShowDeleteStateFromModal(false); };

  const [showDeleteStateToModal, setShowDeleteStateToModal] = React.useState<any>(false);
  const closeDeletetStateToModal = () => { setShowDeleteStateToModal(false); };

  const [showDeleteLgaFromModal, setShowDeleteLgaFromModal] = React.useState<any>(false);
  const closeDeletetLgaFromModal = () => { setShowDeleteLgaFromModal(false); };

  const [showDeleteLgaToModal, setShowDeleteLgaToModal] = React.useState<any>(false);
  const closeDeletetLgaToModal = () => { setShowDeleteLgaToModal(false); };




  React.useEffect(() => {
    SuperAdminApis.getAllCountryFrom('').then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setUserList(response?.data?.data)
        }
      }
    )

    SuperAdminApis.getAllCountryTo('').then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setCountryTo(response?.data?.data)
          // console?.log(response?.data?.data)
        }
      }
    )
  }, [showCreateCountryFromModal, showEditCountryFromModal, showDeleteCountryFromModal, showCreateCountryToModal, showEditCountryToModal, showDeleteCountryToModal]);


  const getStateFrom = React.useCallback(
    (country: any) => {
      let data = {
        "country_id": country?.id
      }
      setCountryFromData(country)
      SuperAdminApis.getAllStateFrom('', data).then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            setStateFrom(response?.data?.data)

          }
        }
      )

    }, [tabNo, stateFrom, countryFromData]
  );

  const getStateTo = React.useCallback(
    (country: any) => {
      let data = {
        "country_id": country?.id
      }
      setCountryToData(country)
      SuperAdminApis.getAllStateTo('', data).then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            setStateTo(response?.data?.data)

          }
        }
      )

    }, [tabNo, stateTo, countryToData]
  );

  React.useEffect(() => {
    getStateFrom(countryFromData)
  }, [showCreateStateFromModal, showEditStateFromModal, showDeleteStateFromModal]);

  React.useEffect(() => {
    getStateTo(countryToData)
  }, [showCreateStateToModal, showEditStateToModal, showDeleteStateToModal]);



  const getLgaFrom = React.useCallback(
    (state: any) => {
      let data = {
        "state_id": state?.id,
        "country_id": countryFromData?.id
      }
      setStateFromData(state)
      SuperAdminApis.getAllLgaFrom('', data).then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            setLgaFrom(response?.data?.data)

          }
        }
      )

    }, [tabNo, lgaFrom, countryFromData, stateFromData]
  );


  const getLgaTo = React.useCallback(
    (state: any) => {
      let data = {
        "state_id": state?.id,
        "country_id": countryToData?.id
      }
      setStateToData(state)
      SuperAdminApis.getAllLgaTo('', data).then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            setLgaTo(response?.data?.data)

          }
        }
      )

    }, [tabNo, lgaTo, countryToData, stateToData]
  );

  function uploadImg1(e: any) {
    if (e.target.files && e.target.files[0]) {
      e.preventDefault();
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      SuperAdminApis.uploadCountryToExcel(formData).then(
        (response: AxiosResponse<any>) => {
          if (response?.data?.status) {
            // console?.log(response?.data)
            setLgaTo(response?.data?.data)
            SuperAdminApis.getAllCountryTo('').then(
              (response: AxiosResponse<any>) => {
                if (response?.data) {
                  setCountryTo(response?.data?.data)
                  toast.success('Country To rate updated successfully');
                  // console?.log(response?.data?.data)
                }
              }
            )
          } else {
            toast.warn(response?.data?.data);
          }
        }
      ).catch(function (error) {
        toast.error('Wrong file format');
      })
    }
  };


  function uploadLga(e: any) {
    if (e.target.files && e.target.files[0]) {
      e.preventDefault();
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      SuperAdminApis.uploadLgaToExcel(formData).then(
        (response: AxiosResponse<any>) => {
          if (response?.data?.status) {
            // console?.log(response?.data)
            setLgaTo(response?.data?.data)
            toast.success('Lga To rate updated successfully');
          } else {
            toast.warn(response?.data?.data);
          }
        }
      ).catch(function (error) {
        toast.error('Wrong file format');
      })
    }
  };



  React.useEffect(() => {
    getLgaFrom(stateFromData)
  }, [showCreateLgaFromModal, showEditLgaFromModal, showDeleteLgaFromModal]);

  React.useEffect(() => {
    getLgaTo(stateToData)
  }, [showCreateLgaToModal, showEditLgaToModal, showDeleteLgaToModal]);



  return (
    <>
      <AdminSidebar title="Rates" />
      <div className="relative md:ml-64 bg-[#FAFAFA] ">
        <AdminNavbar title="Rates" />

        <div className='flex justify-between m-5'>
          <div className='flex justify-start gap-2'>
            <div className='mt-0.5 ml-2'><button type='button' onClick={() => setTabNo('1')} className={"font-normal text-white bg-[#E04343] px-3 py-0.5 rounded-md"}> Country From</button> </div>
            <div className='mt-0.5 ml-2'><button type='button' onClick={() => setTabNo('2')} className={"font-normal text-white bg-[#E04343] px-3 py-0.5 rounded-md"}> Country To</button> </div>
          </div>
          <div>
            {/* <button className='bg-[#E04343] text-white text-[14px] px-[12px] py-[8px] rounded-[10px]'>+ Add Customers</button> */}

          </div>
        </div>


        {tabNo == "1" ?
          <div className=' lg:px-10 pb-6 px-6 bg-[#FAFAFA] min-h-screen'>

            <div className='flex justify-between'>
              <h3 className='text-[15px] text-[#1C1C1C] font-semibold'>Country From</h3>
              <div className='mt-0.5 ml-2'><button type='button' onClick={() => setShowCreateCountryFromModal(true)} className={"font-normal text-white bg-[#E04343] px-3 py-0.5 rounded-md"}>Add Country From</button> </div>

            </div>


            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 border-separate border-spacing-y-3">
                <thead className="text-[12px] text-[#808080] bg-[#FAFAFA] ">
                  <tr>

                    <th scope="col" className="px-2 py-2">
                      S/N
                    </th>

                    <th scope="col" className="px-2 py-2">
                      Country Name
                    </th>

                    <th scope="col" className="px-2 py-2">
                      Country Code
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Client
                    </th>

                    <th scope="col" className="px-2 py-2">
                      Action
                    </th>

                  </tr>
                </thead>

                <tbody className=''>
                  {
                    userLists?.data?.length > 0 ?
                      userLists?.data?.map(
                        (datas: any, index: any) => (
                          <tr className="bg-white  ">

                            <td className="px-2 py-2">
                              {index + 1}
                            </td>

                            <td className="px-2 ">
                              {datas?.country_name}
                            </td>
                            <td className="px-2 ">
                              {datas?.country_code}
                            </td>
                            <td className="px-2 text-center">
                              {datas?.client_id ? datas?.client_id : '--'}
                            </td>

                            <td className="px-2 flex justify-start gap-3 mt-2">
                              <span className='cursor-pointer' onClick={() => { setCountryFromData(datas); setShowEditCountryFromModal(true); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3 21h18M12.222 5.828L15.05 3L20 7.95l-2.828 2.828m-4.95-4.95l-5.607 5.607a1 1 0 0 0-.293.707v4.536h4.536a1 1 0 0 0 .707-.293l5.607-5.607m-4.95-4.95l4.95 4.95" /></svg>
                              </span>

                              <span className='cursor-pointer' onClick={() => { getStateFrom(datas); setTabNo("3"); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 32 32"><path fill="blue" d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68M16 25c-5.3 0-10.9-3.93-12.93-9C5.1 10.93 10.7 7 16 7s10.9 3.93 12.93 9C26.9 21.07 21.3 25 16 25" /><path fill="blue" d="M16 10a6 6 0 1 0 6 6a6 6 0 0 0-6-6m0 10a4 4 0 1 1 4-4a4 4 0 0 1-4 4" /></svg>
                              </span>

                              <span onClick={() => { setCountryFromData(datas); setShowDeleteCountryFromModal(true) }} className='cursor-pointer'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24"><path fill="red" d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2zM18 4h-2.5l-.71-.71c-.18-.18-.44-.29-.7-.29H9.91c-.26 0-.52.11-.7.29L8.5 4H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1" /></svg>
                              </span>



                            </td>
                          </tr>
                        )
                      )
                      :
                      <div className='flex justify-start mt-4'>
                        <td >No Record Found</td>
                      </div>


                  }

                </tbody>

              </table>
            </div>

          </div>
          :
          ""
        }

        {tabNo == "2" ?
          <div className=' lg:px-10 pb-6 px-6 bg-[#FAFAFA] min-h-screen'>
            <div className='flex justify-between'>
              <h3 className='text-[15px] text-[#1C1C1C] font-semibold'>Country To</h3>

              <div className='flex justify-between'>
              <div className='mt-0.5 ml-2'>
                  <label className="flex flex-col items-center justify-center w-full  rounded-[5px] cursor-pointer ">
                    <span className={"font-normal text-white bg-[#E04343] px-3 py-0.5 rounded-md"}>Upload LGA Excel</span>
                    <input id="dropzone-file" accept="image/xlsx" onChange={uploadLga} placeholder="upload brand logo" name='uploadImg1' type="file" className={"hidden mb-2 text-sm text-[#6C757D] font-medium"} />
                  </label>
                </div>

                <div className='mt-0.5 ml-2'>
                  <label className="flex flex-col items-center justify-center w-full  rounded-[5px] cursor-pointer ">
                    <span className={"font-normal text-white bg-[#E04343] px-3 py-0.5 rounded-md"}>Upload Country To Excel</span>
                    <input id="dropzone-file" accept="image/xlsx" onChange={uploadImg1} placeholder="upload brand logo" name='uploadImg1' type="file" className={"hidden mb-2 text-sm text-[#6C757D] font-medium"} />
                  </label>
                </div>

                <div className='mt-0.5 ml-2'><button type='button' onClick={() => setShowCreateCountryToModal(true)} className={"font-normal text-white bg-[#E04343] px-3 py-0.5 rounded-md"}>Add Country To</button> </div>

              </div>

            </div>

            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 border-separate border-spacing-y-3">
                <thead className="text-[12px] text-[#808080] bg-[#FAFAFA] ">
                  <tr>

                    <th scope="col" className="px-2 py-2">
                      S/N
                    </th>

                    <th scope="col" className="px-2 py-2">
                      Country Name
                    </th>

                    <th scope="col" className="px-2 py-2">
                      Country Code
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Client
                    </th>

                    <th scope="col" className="px-2 py-2">
                      Base Value
                    </th>

                    <th scope="col" className="px-2 py-2">
                      Per KG
                    </th>

                    <th scope="col" className="px-2 py-2">
                      Action
                    </th>

                  </tr>
                </thead>

                <tbody className=''>
                  {
                    countryTo?.data?.length > 0 ?
                      countryTo?.data?.map(
                        (datas: any, index: any) => (
                          <tr className="bg-white  ">

                            <td className="px-2 py-2">
                              {index + 1}
                            </td>

                            <td className="px-2 ">
                              {datas?.country_name}
                            </td>
                            <td className="px-2 ">
                              {datas?.country_code}
                            </td>

                            <td className="px-2 text-center">
                              {datas?.client_id ? datas?.client_id : '--'}
                            </td>

                            <td className="px-2 ">
                              {datas?.base_value}
                            </td>

                            <td className="px-2 ">
                              {datas?.per_kg}
                            </td>

                            <td className="px-2 flex justify-start gap-3 mt-2">
                              <span className='cursor-pointer' onClick={() => { setCountryToData(datas); setShowEditCountryToModal(true); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3 21h18M12.222 5.828L15.05 3L20 7.95l-2.828 2.828m-4.95-4.95l-5.607 5.607a1 1 0 0 0-.293.707v4.536h4.536a1 1 0 0 0 .707-.293l5.607-5.607m-4.95-4.95l4.95 4.95" /></svg>
                              </span>

                              <span className='cursor-pointer' onClick={() => { getStateTo(datas); setTabNo("4"); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 32 32"><path fill="blue" d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68M16 25c-5.3 0-10.9-3.93-12.93-9C5.1 10.93 10.7 7 16 7s10.9 3.93 12.93 9C26.9 21.07 21.3 25 16 25" /><path fill="blue" d="M16 10a6 6 0 1 0 6 6a6 6 0 0 0-6-6m0 10a4 4 0 1 1 4-4a4 4 0 0 1-4 4" /></svg>
                              </span>

                              <span onClick={() => { setCountryToData(datas); setShowDeleteCountryToModal(true) }} className='cursor-pointer'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24"><path fill="red" d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2zM18 4h-2.5l-.71-.71c-.18-.18-.44-.29-.7-.29H9.91c-.26 0-.52.11-.7.29L8.5 4H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1" /></svg>
                              </span>



                            </td>
                          </tr>
                        )
                      )
                      :
                      <div className='flex justify-start mt-4'>
                        <td >No Record Found</td>
                      </div>


                  }

                </tbody>

              </table>
            </div>

          </div>
          :
          ""
        }

        {tabNo == "3" ?
          <div className=' lg:px-10 pb-6 px-6 bg-[#FAFAFA] min-h-screen'>
            <div className='flex justify-between'>
              <h3 className='text-[15px] text-[#1C1C1C] font-semibold'>States From {countryFromData?.country_name}</h3>
              <div className='mt-0.5 ml-2'><button type='button' onClick={() => setShowCreateStateFromModal(true)} className={"font-normal text-white bg-[#E04343] px-3 py-0.5 rounded-md"}>Add State</button> </div>

            </div>

            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 border-separate border-spacing-y-3">
                <thead className="text-[12px] text-[#808080] bg-[#FAFAFA] ">
                  <tr>

                    <th scope="col" className="px-2 py-2">
                      S/N
                    </th>

                    <th scope="col" className="px-2 py-2">
                      State Name
                    </th>

                    <th scope="col" className="px-2 py-2">
                      State Code
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Client
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Zone
                    </th>

                    <th scope="col" className="px-2 py-2">
                      Action
                    </th>

                  </tr>
                </thead>

                <tbody className=''>
                  {
                    stateFrom?.data?.length > 0 ?
                      stateFrom?.data?.map(
                        (datas: any, index: any) => (
                          <tr className="bg-white  ">

                            <td className="px-2 py-2">
                              {index + 1}
                            </td>

                            <td className="px-2 ">
                              {datas?.state_name}
                            </td>
                            <td className="px-2 ">
                              {datas?.state_code}
                            </td>
                            <td className="px-2 text-center">
                              {datas?.client_id ? datas?.client_id : '--'}
                            </td>
                            <td className="px-2 ">
                              {datas?.zone}
                            </td>

                            <td className="px-2 flex justify-start gap-3 mt-2">
                              <span className='cursor-pointer' onClick={() => { setStateFromData(datas); setShowEditStateFromModal(true); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3 21h18M12.222 5.828L15.05 3L20 7.95l-2.828 2.828m-4.95-4.95l-5.607 5.607a1 1 0 0 0-.293.707v4.536h4.536a1 1 0 0 0 .707-.293l5.607-5.607m-4.95-4.95l4.95 4.95" /></svg>
                              </span>

                              <span className='cursor-pointer' onClick={() => { getLgaFrom(datas); setTabNo("5"); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 32 32"><path fill="blue" d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68M16 25c-5.3 0-10.9-3.93-12.93-9C5.1 10.93 10.7 7 16 7s10.9 3.93 12.93 9C26.9 21.07 21.3 25 16 25" /><path fill="blue" d="M16 10a6 6 0 1 0 6 6a6 6 0 0 0-6-6m0 10a4 4 0 1 1 4-4a4 4 0 0 1-4 4" /></svg>
                              </span>

                              <span onClick={() => { setStateFromData(datas); setShowDeleteStateFromModal(true) }} className='cursor-pointer'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24"><path fill="red" d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2zM18 4h-2.5l-.71-.71c-.18-.18-.44-.29-.7-.29H9.91c-.26 0-.52.11-.7.29L8.5 4H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1" /></svg>
                              </span>



                            </td>
                          </tr>
                        )
                      )
                      :
                      <div className='flex justify-start mt-4'>
                        <td >No Record Found</td>
                      </div>


                  }

                </tbody>

              </table>
            </div>

          </div>
          :
          ""
        }


        {tabNo == "4" ?
          <div className=' lg:px-10 pb-6 px-6 bg-[#FAFAFA] min-h-screen'>
            <div className='flex justify-between'>
              <h3 className='text-[15px] text-[#1C1C1C] font-semibold'>States in {countryToData?.country_name}</h3>
              <div className='mt-0.5 ml-2'><button type='button' onClick={() => setShowCreateStateToModal(true)} className={"font-normal text-white bg-[#E04343] px-3 py-0.5 rounded-md"}>Add State</button> </div>

            </div>

            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 border-separate border-spacing-y-3">
                <thead className="text-[12px] text-[#808080] bg-[#FAFAFA] ">
                  <tr>

                    <th scope="col" className="px-2 py-2">
                      S/N
                    </th>

                    <th scope="col" className="px-2 py-2">
                      State Name
                    </th>

                    <th scope="col" className="px-2 py-2">
                      State Code
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Client
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Zone
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Base Value
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Per Kg
                    </th>

                    <th scope="col" className="px-2 py-2">
                      Action
                    </th>

                  </tr>
                </thead>

                <tbody className=''>
                  {
                    stateTo?.data?.length > 0 ?
                      stateTo?.data?.map(
                        (datas: any, index: any) => (
                          <tr className="bg-white  ">

                            <td className="px-2 py-2">
                              {index + 1}
                            </td>

                            <td className="px-2 ">
                              {datas?.state_name}
                            </td>
                            <td className="px-2 ">
                              {datas?.state_code}
                            </td>
                            <td className="px-2 text-center">
                              {datas?.client_id ? datas?.client_id : '--'}
                            </td>
                            <td className="px-2 ">
                              {datas?.zone}
                            </td>

                            <td className="px-2 ">
                              {datas?.base_value}
                            </td>

                            <td className="px-2 ">
                              {datas?.per_kg}
                            </td>

                            <td className="px-2 flex justify-start gap-3 mt-2">
                              <span className='cursor-pointer' onClick={() => { setStateToData(datas); setShowEditStateToModal(true); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3 21h18M12.222 5.828L15.05 3L20 7.95l-2.828 2.828m-4.95-4.95l-5.607 5.607a1 1 0 0 0-.293.707v4.536h4.536a1 1 0 0 0 .707-.293l5.607-5.607m-4.95-4.95l4.95 4.95" /></svg>
                              </span>

                              <span className='cursor-pointer' onClick={() => { getLgaTo(datas); setTabNo("6"); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 32 32"><path fill="blue" d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68M16 25c-5.3 0-10.9-3.93-12.93-9C5.1 10.93 10.7 7 16 7s10.9 3.93 12.93 9C26.9 21.07 21.3 25 16 25" /><path fill="blue" d="M16 10a6 6 0 1 0 6 6a6 6 0 0 0-6-6m0 10a4 4 0 1 1 4-4a4 4 0 0 1-4 4" /></svg>
                              </span>

                              <span onClick={() => { setStateToData(datas); setShowDeleteStateToModal(true) }} className='cursor-pointer'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24"><path fill="red" d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2zM18 4h-2.5l-.71-.71c-.18-.18-.44-.29-.7-.29H9.91c-.26 0-.52.11-.7.29L8.5 4H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1" /></svg>
                              </span>



                            </td>
                          </tr>
                        )
                      )
                      :
                      <div className='flex justify-start mt-4'>
                        <td >No Record Found</td>
                      </div>


                  }

                </tbody>

              </table>
            </div>

          </div>
          :
          ""
        }


        {tabNo == "5" ?
          <div className=' lg:px-10 pb-6 px-6 bg-[#FAFAFA] min-h-screen'>
            <div className='flex justify-between'>
              <h3 className='text-[15px] text-[#1C1C1C] font-semibold'>LGA From {stateFromData?.state_name}</h3>
              <div className='mt-0.5 ml-2'><button type='button' onClick={() => setShowCreateLgaFromModal(true)} className={"font-normal text-white bg-[#E04343] px-3 py-0.5 rounded-md"}>Add LGA</button> </div>

            </div>

            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 border-separate border-spacing-y-3">
                <thead className="text-[12px] text-[#808080] bg-[#FAFAFA] ">
                  <tr>

                    <th scope="col" className="px-2 py-2">
                      S/N
                    </th>

                    <th scope="col" className="px-2 py-2">
                      LGA Name
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Client
                    </th>

                    <th scope="col" className="px-2 py-2">
                      Cost
                    </th>


                    <th scope="col" className="px-2 py-2">
                      Action
                    </th>

                  </tr>
                </thead>

                <tbody className=''>
                  {
                    lgaFrom?.data?.length > 0 ?
                      lgaFrom?.data?.map(
                        (datas: any, index: any) => (
                          <tr className="bg-white  ">

                            <td className="px-2 py-2">
                              {index + 1}
                            </td>

                            <td className="px-2 ">
                              {datas?.lga_name}
                            </td>
                            <td className="px-2 text-center">
                              {datas?.client_id ? datas?.client_id : '--'}
                            </td>
                            <td className="px-2 ">
                              {datas?.cost}
                            </td>


                            <td className="px-2 flex justify-start gap-3 mt-2">
                              <span className='cursor-pointer' onClick={() => { setLgaFromData(datas); setShowEditLgaFromModal(true); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3 21h18M12.222 5.828L15.05 3L20 7.95l-2.828 2.828m-4.95-4.95l-5.607 5.607a1 1 0 0 0-.293.707v4.536h4.536a1 1 0 0 0 .707-.293l5.607-5.607m-4.95-4.95l4.95 4.95" /></svg>
                              </span>

                              {/* <span className='cursor-pointer'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 32 32"><path fill="blue" d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68M16 25c-5.3 0-10.9-3.93-12.93-9C5.1 10.93 10.7 7 16 7s10.9 3.93 12.93 9C26.9 21.07 21.3 25 16 25" /><path fill="blue" d="M16 10a6 6 0 1 0 6 6a6 6 0 0 0-6-6m0 10a4 4 0 1 1 4-4a4 4 0 0 1-4 4" /></svg>
                              </span> */}

                              <span onClick={() => { setLgaFromData(datas); setShowDeleteLgaFromModal(true) }} className='cursor-pointer'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24"><path fill="red" d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2zM18 4h-2.5l-.71-.71c-.18-.18-.44-.29-.7-.29H9.91c-.26 0-.52.11-.7.29L8.5 4H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1" /></svg>
                              </span>



                            </td>
                          </tr>
                        )
                      )
                      :
                      <div className='flex justify-start mt-4'>
                        <td >No Record Found</td>
                      </div>


                  }

                </tbody>

              </table>
            </div>

          </div>
          :
          ""
        }


        {tabNo == "6" ?
          <div className=' lg:px-10 pb-6 px-6 bg-[#FAFAFA] min-h-screen'>
            <div className='flex justify-between'>
              <h3 className='text-[15px] text-[#1C1C1C] font-semibold'>LGA in {stateToData?.state_name}</h3>
              <div className='mt-0.5 ml-2'><button type='button' onClick={() => setShowCreateLgaToModal(true)} className={"font-normal text-white bg-[#E04343] px-3 py-0.5 rounded-md"}>Add LGA</button> </div>

            </div>

            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 border-separate border-spacing-y-3">
                <thead className="text-[12px] text-[#808080] bg-[#FAFAFA] ">
                  <tr>

                    <th scope="col" className="px-2 py-2">
                      S/N
                    </th>

                    <th scope="col" className="px-2 py-2">
                      LGA Name
                    </th>

                    <th scope="col" className="px-2 py-2">
                      Client
                    </th>

                    <th scope="col" className="px-2 py-2">
                      Cost
                    </th>


                    <th scope="col" className="px-2 py-2">
                      Action
                    </th>

                  </tr>
                </thead>

                <tbody className=''>
                  {
                    lgaTo?.data?.length > 0 ?
                      lgaTo?.data?.map(
                        (datas: any, index: any) => (
                          <tr className="bg-white  ">

                            <td className="px-2 py-2">
                              {index + 1}
                            </td>

                            <td className="px-2 ">
                              {datas?.lga_name}
                            </td>
                            <td className="px-2 text-center">
                              {datas?.client_id ? datas?.client_id : '--'}
                            </td>
                            <td className="px-2 ">
                              {datas?.cost}
                            </td>


                            <td className="px-2 flex justify-start gap-3 mt-2">
                              <span className='cursor-pointer' onClick={() => { setLgaToData(datas); setShowEditLgaToModal(true); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3 21h18M12.222 5.828L15.05 3L20 7.95l-2.828 2.828m-4.95-4.95l-5.607 5.607a1 1 0 0 0-.293.707v4.536h4.536a1 1 0 0 0 .707-.293l5.607-5.607m-4.95-4.95l4.95 4.95" /></svg>
                              </span>

                              {/* <span className='cursor-pointer'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 32 32"><path fill="blue" d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68M16 25c-5.3 0-10.9-3.93-12.93-9C5.1 10.93 10.7 7 16 7s10.9 3.93 12.93 9C26.9 21.07 21.3 25 16 25" /><path fill="blue" d="M16 10a6 6 0 1 0 6 6a6 6 0 0 0-6-6m0 10a4 4 0 1 1 4-4a4 4 0 0 1-4 4" /></svg>
                              </span> */}

                              <span onClick={() => { setLgaToData(datas); setShowDeleteLgaToModal(true) }} className='cursor-pointer'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24"><path fill="red" d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2zM18 4h-2.5l-.71-.71c-.18-.18-.44-.29-.7-.29H9.91c-.26 0-.52.11-.7.29L8.5 4H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1" /></svg>
                              </span>



                            </td>
                          </tr>
                        )
                      )
                      :
                      <div className='flex justify-start mt-4'>
                        <td >No Record Found</td>
                      </div>


                  }

                </tbody>

              </table>
            </div>

          </div>
          :
          ""
        }


      </div>


      <AddCountryFromModal show={showCreateCountryFromModal} handleClose={closeCreateCountryFromModal} />
      <EditCountryFromModal show={showEditCountryFromModal} data={countryFromData} handleClose={closeEditCountryFromModal} />
      <DeleteCountryFromModal show={showDeleteCountryFromModal} data={countryFromData} handleClose={closeDeletetCountryFromModal} />



      <AddCountryToModal show={showCreateCountryToModal} handleClose={closeCreateCountryToModal} />
      <EditCountryToModal show={showEditCountryToModal} data={countryToData} handleClose={closeEditCountryToModal} />
      <DeleteCountryToModal show={showDeleteCountryToModal} data={countryToData} handleClose={closeDeletetCountryToModal} />



      <AddStateFromModal show={showCreateStateFromModal} data={countryFromData} handleClose={closeCreateStateFromModal} />
      <EditstateFromModal show={showEditStateFromModal} data={countryFromData} stateData={stateFromData} handleClose={closeEditStateFromModal} />
      <DeleteStateFromModal show={showDeleteStateFromModal} data={countryFromData} stateData={stateFromData} handleClose={closeDeletetStateFromModal} />


      <AddStateToModal show={showCreateStateToModal} data={countryToData} handleClose={closeCreateStateToModal} />
      <EditstateToModal show={showEditStateToModal} data={countryToData} stateData={stateToData} handleClose={closeEditStateToModal} />
      <DeleteStateToModal show={showDeleteStateToModal} data={countryToData} stateData={stateToData} handleClose={closeDeletetStateToModal} />



      <AddLgaFromModal show={showCreateLgaFromModal} data={countryFromData} stateData={stateFromData} handleClose={closeCreateLgaFromModal} />
      <EditLgaFromModal show={showEditLgaFromModal} data={countryFromData} stateData={stateFromData} lgaData={lgaFromData} handleClose={closeEditLgaFromModal} />
      <DeleteLgaFromModal show={showDeleteLgaFromModal} data={countryFromData} stateData={stateFromData} lgaData={lgaFromData} handleClose={closeDeletetLgaFromModal} />


      <AddLgaToModal show={showCreateLgaToModal} data={countryToData} stateData={stateToData} handleClose={closeCreateLgaToModal} />
      <EditLgaToModal show={showEditLgaToModal} data={countryToData} stateData={stateToData} lgaData={lgaToData} handleClose={closeEditLgaToModal} />
      <DeleteLgaToModal show={showDeleteLgaToModal} data={countryToData} stateData={stateToData} lgaData={lgaToData} handleClose={closeDeletetLgaToModal} />



      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  )
}

export default ManageRates
import React from 'react'
import AdminNavbar from "../Navbars/AdminNavbar";
import Sidebar from "../Sidebar/Sidebar";
import { SuperAdminApis } from '../../apis/superAdminApi';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';

import { convertUTCDateToLocalDate } from '../Reusable/functions';
//@ts-ignore
import Modal from 'react-awesome-modal';
import { SvgElement, icontypesEnum } from '../assets/svgElement';
import { AuthApis } from '../../apis/authApis';
import { error } from 'console';
import AdminSidebar from '../Sidebar/AdminSidebar';
import { Oval } from 'react-loader-spinner';
import { MdDelete } from "react-icons/md";


function ManageRiders() {

  const navigate = useNavigate();
  const [userLists, setUserList] = React.useState<any>([]);
  const [vehicleList, setVehicleList] = React.useState<any>([]);

  let [loader, setLoader] = React.useState<boolean>(false);

  const [vehicleName, setVehicleName] = React.useState<any>('');
  const [vehicleDescription, setVehicleDescription] = React.useState<any>('');

  const userLoginData = useSelector((state: any) => state.data.login.value);

  const [searchText, setSearchText] = React.useState('');

  let [addModal, setAddModal] = React.useState<boolean>(false);
  let [addVehicleModal, setAddVehicleModal] = React.useState<boolean>(false);

  let [editVehicleModal, setEditVehicleModal] = React.useState<boolean>(false);

  let [deleteFleetModal, setDeleteFleetModal] = React.useState<boolean>(false);

  let [officeModal, setOfficeModal] = React.useState<boolean>(false);
  const [tabNo, setTabNo] = React.useState<any>('1');
  const [fleetData, setFleetData] = React.useState<any>([]);

  const [phone, setPhone] = React.useState<any>("");

  const [userData, setUserdata] = React.useState<any>({
    'first_name': "",
    'last_name': " ",
    'email': "",
    'password': "",
    'confirm_password': "",
    'phone_number': "",
    'where_goods_will_be_delievered_to': "null",
    // 'checked': false
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    setUserdata({ ...userData, [name]: value });
  };




  const handleSubmit = React.useCallback(
    (e: React.ChangeEvent<HTMLFormElement>) => {
      e.preventDefault();
      setLoader(true)

      if (userData?.password !== userData?.confirm_password) {
        return toast.error("Password does not match");
      }

      const formData = new FormData()
      formData.append('first_name', userData?.first_name)
      formData.append('last_name', 'fleet')
      formData.append('email', userData?.email)
      formData.append('where_goods_will_be_delievered_to', userData?.where_goods_will_be_delievered_to)
      formData.append('phone_number', (userData?.phone_number))
      formData.append('password', userData?.password)
      formData.append('confirm_password', userData?.confirm_password)


      SuperAdminApis.createRider(formData).then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            if (response?.data?.status === true) {
              // dispatch(login({ email: userData?.email, token: response.data.token, name: response.data.name }))
              setLoader(false)
              toast.success(response?.data?.message);
              
              // navigate('/email-verify');

            } else {setLoader(false)
              // toast.warn("Something went wrong,Try Again");
            }

          } else {
            setLoader(false)
            toast.warn("Something went wrong,Try Again");
          }

          setAddModal(false)
          const query: any = {
            search: searchText,
          };
          SuperAdminApis.getAllRiders('', query).then(
            (response: AxiosResponse<any>) => {
              if (response?.data) {
                setLoader(false)
                setUserList(response?.data?.data)
                window.location.reload();
              }
            }
          )

          setUserdata([])

          // toast.success(response?.data?.message);
        }
      ).catch(function (error) {
        // handle error
        setLoader(false)
        console.log(error.response.data);
        toast.warn(error.response?.data?.message);
        toast.error("Offfline");
      }).finally(() => {

      });
    },
    [userData, phone,loader, userLists]
  );


  const handleVehicleSubmit = React.useCallback(
    (e: React.ChangeEvent<HTMLFormElement>) => {
      e.preventDefault();
      setLoader(true)

      const formData = new FormData()
      formData.append('staff_id', userLoginData?.data?.id)
      formData.append('fleet_id', fleetData?.id)
      formData.append('vehicle_name', vehicleName)
      formData.append('status', 'Available')
      formData.append('description', (vehicleDescription))

      SuperAdminApis.createVehicle(formData).then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            if (response?.data?.status === true) {
              setLoader(false)
              toast.success(response?.data?.message);
              setAddVehicleModal(false)
              setVehicleName('');
              setVehicleDescription('');
            } else {
              setVehicleName('');
              setVehicleDescription('');
              setLoader(false)
              // toast.warn("Something went wrong,Try Again");
            }

          } else {
            setLoader(false)
            toast.warn("Something went wrong,Try Again");
          }

          setAddModal(false)
          const query: any = {
            search: searchText,
          };
          SuperAdminApis.getAllVehicle('', query, fleetData?.id).then(
            (response: AxiosResponse<any>) => {
              if (response?.data) {
                setVehicleList(response?.data?.data)
              }
            }
          )

          setUserdata([])

          // toast.success(response?.data?.message);
        }
      ).catch(function (error) {
        // handle error
        setLoader(false)
        setVehicleName('');
        setVehicleDescription('');
        toast.warn(error.response?.data?.message);
        toast.error("Offfline");
      }).finally(() => {

      });
    },
    [userData,loader, vehicleList, vehicleName, vehicleDescription, fleetData, userLoginData]
  );

  let [riderModal, setRiderModal] = React.useState<boolean>(false);
  let [shipmentId, setShipmentId] = React.useState<any>([]);
  let [vehicleData, setVehicleData] = React.useState<any>([]);
  let [shipmentRider, setShipmentRider] = React.useState<any>('');
  let [riders, setRiders] = React.useState<any>([]);

  const updateRider = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true)

    SuperAdminApis.updateRider(shipmentId)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            setRiderModal(false);
            setShipmentRider('')
            setLoader(false)

          }
          const query: any = {
            search: '',
          };
          SuperAdminApis.getAllRiders('', query).then(
            (response: AxiosResponse<any>) => {
              if (response?.data) {
                setLoader(false)
                setUserList(response?.data?.data)
              }
            }
          )
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        setLoader(false)
        console.log(err);
      })

  }, [shipmentId, shipmentRider,loader, userLists]);


  const updateRiderStat = React.useCallback((e: React.FormEvent<HTMLFormElement>, status: any) => {
    e.preventDefault();
    setLoader(true)

    let data = {
      "id": status?.id,
      "first_name": status?.first_name,
      "last_name": status?.last_name,
      "phone_number": status?.phone_number,
      "isVerified": status?.isVerified == 'false' ? 'true' : 'false',
    }
    SuperAdminApis.updateRider(data)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(status?.isVerified == 'false' ? "Account Activated" : "Account Deactivated");
            setRiderModal(false);
            setShipmentRider('')
            setLoader(false)

          }
          const query: any = {
            search: '',
          };
          SuperAdminApis.getAllRiders('', query).then(
            (response: AxiosResponse<any>) => {
              if (response?.data) {
                setLoader(false)
                setUserList(response?.data?.data)
              }
            }
          )
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        setLoader(false)
        console.log(err);
      })

  }, [shipmentId,loader, shipmentRider, userLists]);


  const deleteFleet = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true)
    SuperAdminApis.deleteFleet(fleetData?.id)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            setDeleteFleetModal(false);
            setShipmentRider('')
            setLoader(false)

          }
          const query: any = {
            search: '',
          };
          SuperAdminApis.getAllRiders('', query).then(
            (response: AxiosResponse<any>) => {
              if (response?.data) {
                setLoader(false)
                setUserList(response?.data?.data)
              }
            }
          )
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        setDeleteFleetModal(false);
        setLoader(false)
        console.log(err);
      })

  }, [shipmentId,fleetData, shipmentRider,deleteFleetModal, loader, userLists]);




  React.useEffect(() => {
    const query: any = {
      search: searchText,
    };
    SuperAdminApis.getAllRiders('', query).then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setUserList(response?.data?.data)
        }
      }
    ).catch(function (error) {
      // handle error

    })
  }, []);


  //Vehicles
  const getAllVehicles = React.useCallback((id: any) => {
    const query: any = {
      search: searchText,
    };
    SuperAdminApis.getAllVehicle('', query, id).then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setVehicleList(response?.data?.data)
          setTabNo('2');
        }
      }
    ).catch(function (error) {
      // handle error

    })
  }, [vehicleList, tabNo]);

  const updateVehicle = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true)
    SuperAdminApis.updateVehicle(vehicleData)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            setEditVehicleModal(false);
            setVehicleData('')
            setLoader(false)

          }
          const query: any = {
            search: '',
          };
          SuperAdminApis.getAllVehicle('', query, fleetData?.id).then(
            (response: AxiosResponse<any>) => {
              if (response?.data) {
                setLoader(false)
                setVehicleList(response?.data?.data)
              }
            }
          )
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        setLoader(false)
        console.log(err);
      })

  }, [fleetData, vehicleData,loader, userLists, editVehicleModal, vehicleList]);

  const updateVehicleStat = React.useCallback((e: React.FormEvent<HTMLFormElement>, vehData: any) => {
    e.preventDefault();
    setLoader(true)

    let data = {
      "id": vehData?.id,
      "staff_id": vehData?.staff_id,
      "fleet_id": vehData?.fleet_id,
      "vehicle_name": vehData?.vehicle_name,
      "description": vehData?.description,
      "status": vehData?.status == 'Available' ? 'Busy' : 'Available',
    }
    SuperAdminApis.updateVehicle(data)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            setLoader(false)
            toast.success(vehData?.status == 'Available' ? "Status Updated" : "Status Updated");
          }
          const query: any = {
            search: '',
          };
          SuperAdminApis.getAllVehicle('', query, fleetData?.id).then(
            (response: AxiosResponse<any>) => {
              if (response?.data) {
                setLoader(false)
                setVehicleList(response?.data?.data)
              }
            }
          )
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        setLoader(false)
        console.log(err);
      })

  }, [shipmentId, fleetData,loader, vehicleList]);


  const paginator = React.useCallback(
    (value: any) => {
      //   setLoader(true);
      let value2 = '';
      if (value !== null) {
        value2 = value;
      } else {
        value2 = ''
      }
      const query: any = {
        search: searchText,
      };

      SuperAdminApis.getAllRiders(value2, query).then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            setUserList(response?.data?.data)
          }
        }
      ).catch(function (error) {
        console.log(error.response.data);
      })

    }, [userLists, searchText]);

  const handleChange2 = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    setShipmentId({ ...shipmentId, [name]: value });
  };


  const handleVehicleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    setVehicleData({ ...vehicleData, [name]: value });
  };



  return (
    <>
      <AdminSidebar title="Fleet Mangement" />
      <div className="relative md:ml-64 bg-[#FAFAFA] ">
        <AdminNavbar title="Fleet Mangement" />

        {tabNo == "1" ?
          <div className=' lg:px-10 pb-6 px-6 bg-[#FAFAFA] min-h-screen'>

            <div className='flex justify-between my-5'>
              <div>
                <h3 className='text-[15px] text-[#1C1C1C] font-semibold'>Fleet List</h3>

              </div>
              <div>
                {/* <button className='bg-[#E04343] text-white text-[14px] px-[12px] py-[8px] rounded-[10px]'>+ Add Customers</button> */}
                <div className='flex justify-end'>
                  <div className="relative flex w-full flex-wrap items-stretch">
                    <div className="absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#9da4aa" d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0z" /></svg>
                    </div>
                    <input type="text" onClick={() => paginator('')} onChange={e => setSearchText(e.target.value)} placeholder='search...' id="simple-search" className=" border border-gray-300 text-gray-500 text-sm rounded-md block w-full pl-4 p-1  " required />
                  </div>

                  <div className='mt-0.5 ml-2'><button type='button' onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => paginator('')} className={"font-normal text-white bg-[#E04343] px-3 py-0.5 rounded-md"}>Search</button> </div>

                </div>

                <div className='flex justify-end mt-4'>
                  <div className='mt-0.5 ml-2'><button type='button' onClick={() => setAddModal(true)} className={"font-normal text-white bg-[#E04343] px-3 py-0.5 rounded-md"}>Add Fleet</button> </div>
                </div>


              </div>
            </div>


            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 border-separate border-spacing-y-3">
                <thead className="text-[12px] text-[#030229] bg-[#FAFAFA] ">
                  <tr>

                    <th scope="col" className="px-2 py-2">
                      S/N
                    </th>

                    <th scope="col" className="px-2 py-2">
                      Name
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Email
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Phone Number
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Activated
                    </th>
                    <th scope="col" className="px-12 py-4">
                      Action
                    </th>

                  </tr>
                </thead>


                <tbody className=''>

                  {
                    userLists?.data?.length > 0 ?
                      userLists?.data?.map(
                        (datas: any, index: any) => (
                          <tr className="bg-white  ">

                            <td className="px-2 py-2">
                              {index + 1}
                            </td>

                            <td className="px-6 py-4">
                              {datas?.first_name}  {datas?.last_name}
                            </td>
                            <td className="px-6 py-4 ">
                              {datas?.email}
                            </td>
                            <td className="px-6 py-4 ">
                              {datas?.phone_number}
                            </td>
                            <td className="px-6 py-4 cursor-pointer" onClick={(e: any) => { updateRiderStat(e, datas); }}>
                              {datas?.isVerified == 'false' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#E6BDFF', color: '#B24BF1', borderRadius: '10px', padding: '2px 10px' }}>{datas?.isVerified}</b></p> : ''}
                              {datas?.isVerified == 'true' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#CAD8FA', color: '#4979ED', borderRadius: '10px', padding: '2px 10px' }}>{datas?.isVerified}</b></p> : ''}
                              {/* {datas?.isVerified} */}
                            </td>

                            <td className='flex justify-between px-6 py-4'>
                              <span className="hover:underline text-blue-500 cursor-pointer" onClick={() => { setRiderModal(true); setShipmentId(datas) }}>
                                Edit
                              </span>


                              <span className=" cursor-pointer" onClick={(e: any) => { getAllVehicles(datas?.id); setFleetData(datas) }}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <rect width="24" height="24" rx="4" fill="#EBEBEB" />
                                  <path d="M12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5Z" fill="#3F83F8" />
                                  <path d="M15.2536 9.30891C14.2289 8.60625 13.1323 8.25 11.9944 8.25C10.9704 8.25 9.97219 8.55469 9.02742 9.15187C8.07469 9.75539 7.1407 10.8516 6.375 12C6.99422 13.0312 7.84125 14.0916 8.72344 14.6995C9.73547 15.3966 10.8359 15.75 11.9944 15.75C13.1428 15.75 14.2409 15.3968 15.2592 14.7002C16.1557 14.0859 17.0088 13.027 17.625 12C17.0067 10.9821 16.1508 9.92437 15.2536 9.30891ZM12 14.25C11.555 14.25 11.12 14.118 10.75 13.8708C10.38 13.6236 10.0916 13.2722 9.92127 12.861C9.75097 12.4499 9.70642 11.9975 9.79323 11.561C9.88005 11.1246 10.0943 10.7237 10.409 10.409C10.7237 10.0943 11.1246 9.88005 11.561 9.79323C11.9975 9.70642 12.4499 9.75097 12.861 9.92127C13.2722 10.0916 13.6236 10.38 13.8708 10.75C14.118 11.12 14.25 11.555 14.25 12C14.2493 12.5965 14.012 13.1684 13.5902 13.5902C13.1684 14.012 12.5965 14.2493 12 14.25Z" fill="#3F83F8" />
                                </svg>
                              </span>


                              <span className="hover:underline text-blue-500 cursor-pointer" onClick={() => { setDeleteFleetModal(true); setFleetData(datas) }}>
                              <MdDelete color='red' />
                              </span>

                            </td>






                          </tr>
                        )
                      )
                      :
                      <div className='flex justify-start mt-4'>
                        <td >No Record Found</td>
                      </div>


                  }

                </tbody>
              </table>
            </div>
            <div className='m-4 flex justify-end mt-20'>
              {
                userLists?.links?.filter(((item: any, idx: any) => idx < 1000)).map(
                  (datas: any, index: any) => (
                    <button onClick={() => paginator(datas?.label == 'Next &raquo;' ? datas?.url?.charAt(datas?.url?.length - 1) : (datas?.label === '&laquo; Previous') ? datas?.url?.charAt(datas?.url?.length - 1) : datas?.label)} disabled={datas?.active} className={'mx-1 py-1 px-2 ' + ((datas?.active == false || datas?.url == null) ? 'bg-gray-300 text-black ' : 'bg-[#E04343] text-white')}>
                      {datas?.label == '&laquo; Previous' ? '< Previous' : (datas?.label === 'Next &raquo;') ? 'Next  >' : datas?.label}
                    </button>
                  )
                )
              }

            </div>
          </div>
          :
          ''
        }

        {tabNo == "2" ?
          <div className=' lg:px-10 pb-6 px-6 bg-[#FAFAFA] min-h-screen'>

            <div className='flex justify-between my-5'>
              <div>
                <h3 className='text-[15px] text-[#1C1C1C] font-semibold capitalize'>{fleetData?.first_name} Vehicle's List</h3>

              </div>
              <div>
                {/* <button className='bg-[#E04343] text-white text-[14px] px-[12px] py-[8px] rounded-[10px]'>+ Add Customers</button> */}
                <div className='flex justify-end'>
                  <div className="relative flex w-full flex-wrap items-stretch">
                    <div className="absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#9da4aa" d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0z" /></svg>
                    </div>
                    <input type="text" onClick={() => paginator('')} onChange={e => setSearchText(e.target.value)} placeholder='search...' id="simple-search" className=" border border-gray-300 text-gray-500 text-sm rounded-md block w-full pl-4 p-1  " required />
                  </div>

                  <div className='mt-0.5 ml-2'><button type='button' onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => paginator('')} className={"font-normal text-white bg-[#E04343] px-3 py-0.5 rounded-md"}>Search</button> </div>

                </div>

                <div className='flex justify-end mt-4'>
                  <div className='mt-0.5 ml-2'><button type='button' onClick={() => setAddVehicleModal(true)} className={"font-normal text-white bg-[#E04343] px-3 py-0.5 rounded-md"}>Add Vehicle</button> </div>
                </div>


              </div>
            </div>


            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 border-separate border-spacing-y-3">
                <thead className="text-[12px] text-[#030229] bg-[#FAFAFA] ">
                  <tr>

                    <th scope="col" className="px-2 py-2">
                      S/N
                    </th>

                    <th scope="col" className="px-2 py-2">
                      Vehicle Name
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Description
                    </th>
                    <th scope="col" className="px-2 py-2 pl-10">
                      Status
                    </th>

                    <th scope="col" className="px-2 py-2">
                      Action
                    </th>

                  </tr>
                </thead>


                <tbody className=''>

                  {
                    vehicleList?.data?.length > 0 ?
                      vehicleList?.data?.map(
                        (datas: any, index: any) => (
                          <tr className="bg-white  ">

                            <td className="px-2 py-2">
                              {index + 1}
                            </td>

                            <td className="px-6 py-4">
                              {datas?.vehicle_name}
                            </td>
                            <td className="px-6 py-4 ">
                              {datas?.description}
                            </td>
                            <td className="px-6 py-4 cursor-pointer" onClick={(e: any) => { updateVehicleStat(e, datas); }}>
                              {datas?.status == 'Available' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#E6BDFF', color: '#B24BF1', borderRadius: '10px', padding: '2px 10px' }}>{datas?.status}</b></p> : ''}
                              {datas?.status == 'Busy' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#CAD8FA', color: '#4979ED', borderRadius: '10px', padding: '2px 10px' }}>{datas?.status}</b></p> : ''}
                              {/* {datas?.isVerified} */}
                            </td>

                            <td className='px-6 py-4 flex '>
                              <span className="hover:underline text-blue-500 cursor-pointer" onClick={() => { setEditVehicleModal(true); setVehicleData(datas) }}>
                                Edit
                              </span>

                            </td>

                          </tr>
                        )
                      )
                      :
                      <div className='flex justify-start mt-4'>
                        <td >No Record Found</td>
                      </div>


                  }

                </tbody>
              </table>
            </div>
            <div className='m-4 flex justify-end mt-20'>
              {
                userLists?.links?.filter(((item: any, idx: any) => idx < 1000)).map(
                  (datas: any, index: any) => (
                    <button onClick={() => paginator(datas?.label == 'Next &raquo;' ? datas?.url?.charAt(datas?.url?.length - 1) : (datas?.label === '&laquo; Previous') ? datas?.url?.charAt(datas?.url?.length - 1) : datas?.label)} disabled={datas?.active} className={'mx-1 py-1 px-2 ' + ((datas?.active == false || datas?.url == null) ? 'bg-gray-300 text-black ' : 'bg-[#E04343] text-white')}>
                      {datas?.label == '&laquo; Previous' ? '< Previous' : (datas?.label === 'Next &raquo;') ? 'Next  >' : datas?.label}
                    </button>
                  )
                )
              }

            </div>
          </div>
          :
          ''
        }
      </div>


      <section>
        <Modal
          visible={addModal}
          width="500"
          height="500"
          effect="fadeInUp"
          onClickAway={() => setAddModal(false)}
        >
          <div className=" " style={{ height: '100%', overflow: 'auto' }}>

            <div className="container  bg-[#fff] rounded-lg p-2">

              <div className="px-3">

                {/* <span className="flex justify-around">
                    <h1 className=" text-xs text-red-600" style={{ fontSize: '10px' }}>Link can’t be edited in free plan. <span style={{ color: '#61A24F' }} className="font-bold text-xs">Upgrade to Pro</span></h1>
                  </span> */}
                <span className="flex justify-between px-2 pt-3">
                  <p className='mt-2'>Create New Fleet</p>
                  <p className="cursor-pointer font-bold" onClick={(e) => setAddModal(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>
                </span>

                <form onSubmit={handleSubmit}>
                  <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                    <div className="relative flex flex-col min-w-0 break-words w-full  ">
                      <div className="flex-auto   py-10 pt-0">
                        <div className="flex flex-wrap ">

                          <div className="lg:w-12/12 w-full lg:pr-4">
                            <div className="flex justify-between mb-3">
                              <label className="text-[#333333] text-[12px]">Fleet Name</label>
                              <SvgElement type={icontypesEnum.QUESTIONMARK} />
                            </div>
                            <div className="relative mb-4">
                              <input type="text" name="first_name" onChange={handleChange} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="" />
                            </div>
                          </div>

                          {/* <div className="lg:w-6/12 w-full ">
                          <div className="flex justify-between mb-3">
                            <label className="text-[#333333] text-[12px]">Last name</label>
                            <SvgElement type={icontypesEnum.QUESTIONMARK} />
                          </div>
                          <div className="relative mb-4">

                            <input type="text" name="last_name" defaultValue={userData?.last_name} onChange={handleChange} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="Smith" />
                          </div>
                        </div> */}

                          <div className="lg:w-6/12 w-full lg:pr-4">
                            <div className="flex justify-between mb-3">
                              <label className="text-[#333333] text-[12px] font-[400]">Email</label>
                              <SvgElement type={icontypesEnum.QUESTIONMARK} />
                            </div>
                            <div className="relative mb-4">

                              <input type="email" name="email" onChange={handleChange} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="john@gmail.com" />
                            </div>
                          </div>



                          <div className="lg:w-6/12 w-full">
                            <div className="flex justify-between mb-3">
                              <label className="text-[#333333] text-[12px] font-[400]">Phone Number</label>
                              <SvgElement type={icontypesEnum.QUESTIONMARK} />
                            </div>
                            <div className="relative mb-4">

                              <input type="number" name="phone_number"  onChange={handleChange} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="+234..." />
                            </div>
                          </div>

                          {/* <div className="lg:w-12/12 w-full lg:pr-4">
                          <div className="flex justify-between mb-3">
                            <label className="text-[#333333] text-[12px] font-[400]">Home Address</label>
                            <SvgElement type={icontypesEnum.QUESTIONMARK} />
                          </div>
                          <div className="relative mb-4">

                            <input type="text" name="where_goods_will_be_delievered_to" onChange={handleChange} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="Location" />
                          </div>
                        </div> */}

                          <div className="lg:w-6/12 w-full lg:pr-4">
                            <div className="flex justify-between mb-3">
                              <label className="text-[#333333] text-[12px] font-[400]">Password</label>
                              <SvgElement type={icontypesEnum.QUESTIONMARK} />
                            </div>
                            <div className="relative mb-4">

                              <input type="password" name="password" defaultValue={userData?.password} onChange={handleChange} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="" />
                            </div>
                          </div>

                          <div className="lg:w-6/12 w-full">
                            <div className="flex justify-between mb-3">
                              <label className="text-[#333333] text-[12px] font-[400]">Confirm Password</label>
                              <SvgElement type={icontypesEnum.QUESTIONMARK} />
                            </div>
                            <div className="relative mb-4">

                              <input type="password" name="confirm_password" defaultValue={userData?.confirm_password} onChange={handleChange} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="" />
                            </div>
                          </div>



                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end pb-8">

                    {/* <div className="flex items-center mb-2 mt-4">
                    <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-[#D1D1D1] rounded " />
                    <label htmlFor="default-checkbox" className="ml-3 text-sm font-medium text-[#333333]">I agree with <span className="text-[#E04343]">terms and conditins</span></label>
                  </div> */}

                    <div className="mt-1 ">
                      <button type="submit" disabled={loader} className="text-white bg-[#E04343] rounded-[30px] transition ease-out delay-300 py-[10px] px-[42px] font-[700] text-[14px]">
                        <div className="flex justify-center gap-3 ">
                          <span> Register</span>
                          <Oval
                            visible={loader}
                            height="20"
                            width="20"
                            color="#E04343"
                            secondaryColor="#FCBF94"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                        </div>

                      </button>
                    </div>

                  </div>





                </form>
              </div>

            </div>

          </div>
        </Modal>
      </section>


      <section>
        <Modal
          visible={riderModal}
          width="550"
          height="400"
          effect="fadeInUp"
          onClickAway={() => setRiderModal(false)}
        >
          <div className=" " style={{ height: '100%', overflow: 'auto' }}>

            <div className="container  bg-[#fff] rounded-lg p-2">

              <div className="px-3">

                {/* <span className="flex justify-around">
                    <h1 className=" text-xs text-red-600" style={{ fontSize: '10px' }}>Link can’t be edited in free plan. <span style={{ color: '#61A24F' }} className="font-bold text-xs">Upgrade to Pro</span></h1>
                  </span> */}
                <span className="flex justify-between px-2 pt-3">
                  <p className='mt-2'>Update Fleet</p>
                  <p className="cursor-pointer font-bold" onClick={(e) => setRiderModal(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>
                </span>


                <form onSubmit={updateRider}>
                  <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                    <div className="relative flex flex-col min-w-0 break-words w-full  ">
                      <div className="flex-auto   py-10 pt-0">
                        <div className="flex flex-wrap ">

                          <div className="lg:w-6/12 w-full lg:pr-4">
                            <div className="flex justify-between mb-3">
                              <label className="text-[#333333] text-[12px]">Fleet Name</label>
                              <SvgElement type={icontypesEnum.QUESTIONMARK} />
                            </div>
                            <div className="relative mb-4">
                              <input type="text" name="first_name" defaultValue={shipmentId?.first_name} onChange={handleChange2} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="John" />
                            </div>
                          </div>

                          {/* <div className="lg:w-6/12 w-full ">
                            <div className="flex justify-between mb-3">
                              <label className="text-[#333333] text-[12px]">Last name</label>
                              <SvgElement type={icontypesEnum.QUESTIONMARK} />
                            </div>
                            <div className="relative mb-4">

                              <input type="text" name="last_name" defaultValue={shipmentId?.last_name} onChange={handleChange2} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="Smith" />
                            </div>
                          </div> */}

                          <div className="lg:w-6/12 w-full lg:pr-4">
                            <div className="flex justify-between mb-3">
                              <label className="text-[#333333] text-[12px] font-[400]">Email</label>
                              <SvgElement type={icontypesEnum.QUESTIONMARK} />
                            </div>
                            <div className="relative mb-4">

                              <input type="email" disabled name="email" defaultValue={shipmentId?.email} onChange={handleChange2} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="john@gmail.com" />
                            </div>
                          </div>



                          <div className="lg:w-6/12 w-full">
                            <div className="flex justify-between mb-3">
                              <label className="text-[#333333] text-[12px] font-[400]">Phone Number</label>
                              <SvgElement type={icontypesEnum.QUESTIONMARK} />
                            </div>
                            <div className="relative mb-4">

                              <input type="number" name="phone_number" defaultValue={shipmentId?.phone_number} onChange={handleChange2} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="+234..." />
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end pb-8">

                    {/* <div className="flex items-center mb-2 mt-4">
                    <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-[#D1D1D1] rounded " />
                    <label htmlFor="default-checkbox" className="ml-3 text-sm font-medium text-[#333333]">I agree with <span className="text-[#E04343]">terms and conditins</span></label>
                  </div> */}

                    <div className="mt-1 ">
                      <button type="submit" disabled={loader} className="text-white bg-[#E04343] rounded-[30px] transition ease-out delay-300 py-[10px] px-[42px] font-[700] text-[14px]">
                        
                      <div className="flex justify-center gap-3 ">
                          <span> Update</span>
                          <Oval
                            visible={loader}
                            height="20"
                            width="20"
                            color="#E04343"
                            secondaryColor="#FCBF94"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                        </div>
                        </button>
                    </div>

                  </div>





                </form>
              </div>

            </div>

          </div>
        </Modal>
      </section>



      {/* Add Vehicle */}
      <section>
        <Modal
          visible={addVehicleModal}
          width="500"
          height="400"
          effect="fadeInUp"
          onClickAway={() => setAddVehicleModal(false)}
        >
          <div className=" " style={{ height: '100%', overflow: 'auto' }}>

            <div className="container  bg-[#fff] rounded-lg p-2">

              <div className="px-3">

                {/* <span className="flex justify-around">
                    <h1 className=" text-xs text-red-600" style={{ fontSize: '10px' }}>Link can’t be edited in free plan. <span style={{ color: '#61A24F' }} className="font-bold text-xs">Upgrade to Pro</span></h1>
                  </span> */}
                <span className="flex justify-between px-2 pt-3">
                  <p className='mt-2'>Add Vehicle to {fleetData?.first_name}</p>
                  <p className="cursor-pointer font-bold" onClick={(e) => setAddVehicleModal(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>
                </span>

                <form onSubmit={handleVehicleSubmit}>
                  <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                    <div className="relative flex flex-col min-w-0 break-words w-full  ">
                      <div className="flex-auto   py-10 pt-0">
                        <div className="flex flex-wrap ">

                          <div className="lg:w-12/12 w-full lg:pr-4">
                            <div className="flex justify-between mb-3">
                              <label className="text-[#333333] text-[12px]">Vehicle Name</label>
                              <SvgElement type={icontypesEnum.QUESTIONMARK} />
                            </div>
                            <div className="relative mb-4">
                              <input type="text" name="first_name" value={vehicleName} onChange={(e: any) => setVehicleName(e.target.value)} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="" />
                            </div>
                          </div>


                          <div className="lg:w-12/12 w-full lg:pr-4">
                            <div className="flex justify-between mb-3">
                              <label className="text-[#333333] text-[12px] font-[400]">Description</label>
                              <SvgElement type={icontypesEnum.QUESTIONMARK} />
                            </div>
                            <div className="relative mb-4">

                              <input type="text" name="" value={vehicleDescription} onChange={(e: any) => setVehicleDescription(e.target.value)} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="" />
                            </div>
                          </div>





                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end pb-8">

                    {/* <div className="flex items-center mb-2 mt-4">
                    <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-[#D1D1D1] rounded " />
                    <label htmlFor="default-checkbox" className="ml-3 text-sm font-medium text-[#333333]">I agree with <span className="text-[#E04343]">terms and conditins</span></label>
                  </div> */}

                    <div className="mt-1 ">
                      <button type="submit" disabled={loader} className="text-white bg-[#E04343] rounded-[30px] transition ease-out delay-300 py-[10px] px-[42px] font-[700] text-[14px]">
                      <div className="flex justify-center gap-3 ">
                          <span> Add</span>
                          <Oval
                            visible={loader}
                            height="20"
                            width="20"
                            color="#E04343"
                            secondaryColor="#FCBF94"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                        </div>
                      </button>
                    </div>

                  </div>





                </form>
              </div>

            </div>

          </div>
        </Modal>
      </section>


      {/* Edit Vehicle */}
      <section>
        <Modal
          visible={editVehicleModal}
          width="550"
          height="400"
          effect="fadeInUp"
          onClickAway={() => setEditVehicleModal(false)}
        >
          <div className=" " style={{ height: '100%', overflow: 'auto' }}>

            <div className="container  bg-[#fff] rounded-lg p-2">

              <div className="px-3">

                {/* <span className="flex justify-around">
                    <h1 className=" text-xs text-red-600" style={{ fontSize: '10px' }}>Link can’t be edited in free plan. <span style={{ color: '#61A24F' }} className="font-bold text-xs">Upgrade to Pro</span></h1>
                  </span> */}
                <span className="flex justify-between px-2 pt-3">
                  <p className='mt-2'>Update Vehicle</p>
                  <p className="cursor-pointer font-bold" onClick={(e) => setEditVehicleModal(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>
                </span>

                <form onSubmit={updateVehicle}>
                  <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                    <div className="relative flex flex-col min-w-0 break-words w-full  ">
                      <div className="flex-auto   py-10 pt-0">
                        <div className="flex flex-wrap ">

                          <div className="lg:w-12/12 w-full">
                            <div className="flex justify-between mb-3">
                              <label className="text-[#333333] text-[12px]">Vehicle Name</label>
                              <SvgElement type={icontypesEnum.QUESTIONMARK} />
                            </div>
                            <div className="relative mb-4">
                              <input type="text" name="vehicle_name" defaultValue={vehicleData?.vehicle_name} onChange={handleVehicleChange} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="John" />
                            </div>
                          </div>

                          <div className="lg:w-12/12 w-full ">
                            <div className="flex justify-between mb-3">
                              <label className="text-[#333333] text-[12px]">Description</label>
                              <SvgElement type={icontypesEnum.QUESTIONMARK} />
                            </div>
                            <div className="relative mb-4">
                              <input type="text" name="description" defaultValue={vehicleData?.description} onChange={handleVehicleChange} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="Smith" />
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end pb-8">
                    <div className="mt-1 ">
                      <button type="submit" className="text-white bg-[#E04343] rounded-[30px] transition ease-out delay-300 py-[10px] px-[42px] font-[700] text-[14px]">
                      <div className="flex justify-center gap-3 ">
                          <span> Update</span>
                          <Oval
                            visible={loader}
                            height="20"
                            width="20"
                            color="#E04343"
                            secondaryColor="#FCBF94"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                        </div>
                      </button>
                    </div>
                  </div>
                </form>
              </div>

            </div>

          </div>
        </Modal>
      </section>


      <section>
        <Modal
          visible={deleteFleetModal}
          width="400"
          height="200"
          effect="fadeInUp"
          onClickAway={() => setDeleteFleetModal(false)}
        >
          <div className="p-2" style={{ overflow: 'auto' }}>
            <span className="flex justify-between px-1 mb-3">
              <p className='font-bold mt-1'>Delete Fleet</p>
              <p className="cursor-pointer font-bold" onClick={()=>setDeleteFleetModal(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>

            </span>

            <form  onSubmit={deleteFleet}>
              <div className='my-10 flex justify-center'>
                <span>Are you sure you want to delete this Fleet ?</span>
              </div>
              <div className="w-full flex justify-end">
                <button type='submit' className='bg-[#E04343] text-white py-[6px] rounded w-full'>Delete Fleet</button>
              </div>
            </form>
          </div>
        </Modal>
      </section>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  )
}

export default ManageRiders